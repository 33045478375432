import React, { createContext, useContext, useState } from 'react';
import { AboutCard } from '../components/ui/about-card';

interface AboutModalContextType {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

const AboutModalContext = createContext<AboutModalContextType | undefined>(undefined);

export function AboutModalProvider({ children }: { children: React.ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <AboutModalContext.Provider value={{ isOpen, openModal, closeModal }}>
      {children}
      <AboutCard isOpen={isOpen} onClose={closeModal} />
    </AboutModalContext.Provider>
  );
}

export function useAboutModal() {
  const context = useContext(AboutModalContext);
  if (context === undefined) {
    throw new Error('useAboutModal must be used within an AboutModalProvider');
  }
  return context;
} 