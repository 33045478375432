import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useCart } from '../context/CartContext';
import { X, ShoppingCart, Minus, Plus, Trash2, Image } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { CartMoodboard } from './CartMoodboard';

export function Cart() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [showMoodboard, setShowMoodboard] = React.useState(false);

  const { items, removeItem, updateQuantity, totalItems, totalPrice } = useCart();
  const navigate = useNavigate();

  const handleCheckoutClick = () => {
    setIsOpen(false);
    navigate('/checkout');
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="fixed bottom-4 right-4 bg-neutral-800 text-white p-3 rounded-full shadow-lg 
                 hover:bg-neutral-700 transition-colors border border-white/10 z-[9000]"
        aria-label={`Shopping cart${totalItems > 0 ? ` with ${totalItems} item${totalItems === 1 ? '' : 's'}` : ''}`}
      >
        <ShoppingCart className="w-6 h-6" />
        {totalItems > 0 && (
          <span className="absolute -top-2 -right-2 bg-white text-black text-xs w-6 h-6 
                        rounded-full flex items-center justify-center font-medium">
            {totalItems}
          </span>
        )}
      </button>

      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setIsOpen(false)}
              className="fixed inset-0 bg-black/80 backdrop-blur-sm z-[9998]"
            />
            <motion.div
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              className="fixed right-0 top-0 h-full w-full max-w-md bg-neutral-900/95 shadow-xl z-[9999] 
                       border-l border-white/10"
            >
              <div className="flex flex-col h-full">
                <div className="flex justify-between items-center p-6 border-b border-white/10">
                  <h2 className="text-2xl font-semibold text-white">Cart</h2>
                  <button 
                    onClick={() => setIsOpen(false)}
                    className="p-2 hover:bg-white/10 rounded-full transition-colors text-white"
                    aria-label="Close cart"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>

                <div className="flex-1 overflow-y-auto p-6">
                  {items.length === 0 ? (
                    <div className="text-center text-neutral-400 mt-8">
                      Your cart is empty
                    </div>
                  ) : (
                    <div className="space-y-4">
                      {items.map((item) => (
                        <div
                          key={item.id}
                          className="flex gap-4 bg-white/5 rounded-xl p-4 border border-white/10"
                        >
                          {item.photo_url && (
                            <img
                              src={item.photo_url}
                              alt={item.name}
                              className="w-20 h-20 object-cover rounded-lg"
                            />
                          )}
                          <div className="flex-1">
                            <div className="flex items-center gap-2">
                              <h3 className="font-medium text-white">{item.name}</h3>
                              {item.isBundle && (
                                <span className="text-xs bg-white/10 text-white/80 px-2 py-1 rounded-full">
                                  Bundle
                                </span>
                              )}
                            </div>
                            
                            {item.bundleVariations && item.bundleVariations.length > 0 && (
                              <div className="mt-2 space-y-2">
                                {item.bundleVariations.map((variation) => (
                                  <div key={variation.bundleItemId} className="flex items-center gap-2">
                                    {variation.displayPhotoUrl && (
                                      <img 
                                        src={variation.displayPhotoUrl} 
                                        alt={variation.displayName}
                                        className="w-8 h-8 object-cover rounded-lg"
                                      />
                                    )}
                                    <div className="text-sm text-neutral-400">
                                      {variation.displayName}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                            
                            <div className="text-sm text-neutral-400 mt-1">
                              ${item.price.toFixed(2)}
                            </div>
                            
                            <div className="flex items-center mt-2 gap-2">
                              <div className="flex items-center bg-white/5 rounded-lg border border-white/10">
                                <button
                                  onClick={() => updateQuantity(item.id, Math.max(0, item.quantity - 1), item._productGroupId)}
                                  className="p-1 hover:bg-white/10 rounded-l-lg transition-colors text-white"
                                  aria-label={`Decrease quantity of ${item.name}`}
                                >
                                  <Minus className="w-4 h-4" />
                                </button>
                                <span className="px-3 py-1 font-medium text-white">{item.quantity}</span>
                                <button
                                  onClick={() => updateQuantity(item.id, item.quantity + 1, item._productGroupId)}
                                  className={`p-1 hover:bg-white/10 rounded-r-lg transition-colors text-white
                                            ${item.quantity >= item.stock_count ? 'opacity-50 cursor-not-allowed' : ''}`}
                                  disabled={item.quantity >= item.stock_count}
                                  aria-label={`Increase quantity of ${item.name}`}
                                >
                                  <Plus className="w-4 h-4" />
                                </button>
                              </div>
                              {item.quantity >= item.stock_count && (
                                <span className="text-xs text-yellow-500">Max quantity</span>
                              )}
                              <button
                                onClick={() => removeItem(item.id, item._productGroupId)}
                                className="ml-auto p-2 text-neutral-400 hover:text-red-400 
                                         hover:bg-white/5 rounded-lg transition-colors"
                                aria-label={`Remove ${item.name} from cart`}
                              >
                                <Trash2 className="w-4 h-4" />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                {items.length > 0 && (
                  <div className="border-t border-white/10 p-6 bg-neutral-900/95">
                    <div className="flex justify-between text-lg font-semibold text-white">
                      <span>Total</span>
                      <span>${totalPrice.toFixed(2)}</span>
                    </div>
                    <button
                      onClick={() => {
                        setShowMoodboard(true);
                        setIsOpen(false);
                      }}
                      className="w-full mt-4 bg-white/10 text-white py-3 rounded-xl font-medium
                               hover:bg-white/20 transition-colors flex items-center justify-center gap-2"
                    >
                      <Image className="w-5 h-5" />
                      Create Moodboard
                    </button>
                    <button 
                      onClick={handleCheckoutClick}
                      className="w-full mt-4 bg-white text-black py-3 rounded-xl font-medium
                               hover:bg-neutral-100 transition-colors"
                    >
                      Checkout
                    </button>
                  </div>
                )}
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showMoodboard && (
          <CartMoodboard
            cartImages={items.flatMap(item => {
              if (item.isBundle && item.bundleVariations && item.bundleVariations.length > 0) {
                return item.bundleVariations
                  .filter(variation => variation.displayPhotoUrl)
                  .map(variation => ({
                    id: `${item.id}-${variation.bundleItemId}`,
                    photo_url: variation.displayPhotoUrl,
                    name: variation.displayName
                  }));
              }
              return [{
                id: item.id,
                photo_url: item.photo_url,
                name: item.name
              }];
            }).filter(item => item.photo_url !== null)}
            onClose={() => setShowMoodboard(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
} 