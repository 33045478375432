import React, { useState, useEffect, useCallback, useRef } from 'react';
import { DraggableImage } from './DraggableImage';
import { motion, AnimatePresence } from 'framer-motion';
import { useZIndex } from '../hooks/useZIndex';
import { ImageData } from '../types/image';
import { X, Download } from 'lucide-react';
import html2canvas from 'html2canvas';
import { toast } from 'react-hot-toast';

interface CartMoodboardProps {
  cartImages: Array<{
    id: string;
    photo_url: string | null;
    name: string;
  }>;
  onClose: () => void;
}

// Animation variants for the container
const containerVariants = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.15
    }
  }
};

// Animation variants for each image
const imageVariants = {
  hidden: {
    opacity: 0,
    y: 20
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      ease: "easeOut"
    }
  }
};

const API_BASE_URL = import.meta.env.PROD 
  ? `${import.meta.env.VITE_API_URL}`
  : 'http://localhost:5001';

const getProxiedImageUrl = (originalUrl: string) => {
  return `${API_BASE_URL}/api/proxy-image?url=${encodeURIComponent(originalUrl)}`;
};

export const CartMoodboard: React.FC<CartMoodboardProps> = ({ cartImages, onClose }) => {
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [loading, setLoading] = useState(true);
  const [viewportSize, setViewportSize] = useState({ width: 0, height: 0 });
  const totalImages = cartImages.length;
  const { getZIndex, bringToFront } = useZIndex(cartImages.length);
  const containerRef = useRef<HTMLDivElement>(null);
  const [moodboardImages, setMoodboardImages] = useState<ImageData[]>([]);
  const hasCalculatedPositions = useRef(false);
  const moodboardRef = useRef<HTMLDivElement>(null);
  const [isDownloading, setIsDownloading] = useState(false);

  // Calculate centered stack layout positions
  const calculateStackPositions = (containerWidth: number, containerHeight: number, numImages: number) => {
    // Determine base width based on screen size
    let baseWidth = 400; // Default for desktop
    
    if (containerWidth <= 768) { // Mobile
      baseWidth = Math.min(300, containerWidth - 40); // 40px for padding
    } else if (containerWidth <= 1024) { // Tablet
      baseWidth = Math.min(350, containerWidth - 60); // 60px for padding
    }

    const offsetX = containerWidth <= 768 ? 10 : 20; // Smaller offset for mobile
    const offsetY = containerWidth <= 768 ? 10 : 20;

    // Calculate center point
    const centerX = containerWidth / 2;
    const centerY = containerHeight / 2;

    // Calculate total grid width and height
    const totalWidth = baseWidth + ((numImages - 1) * offsetX);
    const estimatedHeight = baseWidth * 0.75; // Estimate height based on common aspect ratio
    const totalHeight = estimatedHeight + ((numImages - 1) * offsetY);

    // Adjust starting position to center the stack
    const startX = centerX - (totalWidth / 2);
    const startY = centerY - (totalHeight / 2);

    // Generate positions for each image
    return Array.from({ length: numImages }).map((_, index) => {
      return {
        x: startX + (index * offsetX),
        y: startY + (index * offsetY),
        width: baseWidth,
        height: 0 // Let DraggableImage calculate the height based on aspect ratio
      };
    });
  };

  // Convert cart images to moodboard format
  useEffect(() => {
    if (!containerRef.current || viewportSize.width === 0) return;

    const filteredImages = cartImages.filter(img => img.photo_url);
    const positions = calculateStackPositions(viewportSize.width, viewportSize.height, filteredImages.length);

    const convertedImages = filteredImages.map((img, index) => {
      // Convert photo_url to xlarge_url for highest quality
      const xlargeUrl = img.photo_url?.replace('/large_', '/xlarge_');
      
      const position = {
        x: positions[index].x,
        y: positions[index].y
      };

      // Only specify width, let height be calculated
      const size = {
        width: positions[index].width,
        height: 0
      };

      return {
        id: parseInt(img.id),
        url: xlargeUrl ? getProxiedImageUrl(xlargeUrl) : '',
        originalUrl: xlargeUrl || '',
        description: img.name,
        title: img.name,
        type: 'image' as const,
        zIndex: index + 1,
        defaultPosition: position,
        defaultSize: size,
        mobilePosition: position,
        mobileSize: size,
        tabletPosition: position,
        tabletSize: size,
        smallTabletPosition: position,
        smallTabletSize: size
      };
    });

    setMoodboardImages(convertedImages);
  }, [cartImages, viewportSize]);

  // Add resize observer for more responsive updates
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        setViewportSize({
          width: rect.width,
          height: rect.height
        });
      }
    };

    // Create ResizeObserver
    const resizeObserver = new ResizeObserver(handleResize);
    
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Also listen to window resize for good measure
    window.addEventListener('resize', handleResize);
    
    // Initial size calculation
    handleResize();

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const preloadImages = () => {
      let loadedCount = 0;
      cartImages.forEach(image => {
        if (image.photo_url) {
          const img = new Image();
          img.src = image.photo_url;
          img.onload = () => {
            loadedCount++;
            setImagesLoaded(loadedCount);
            if (loadedCount === totalImages) {
              setTimeout(() => setLoading(false), 500);
            }
          };
          img.onerror = () => {
            loadedCount++;
            setImagesLoaded(loadedCount);
          };
        }
      });
    };

    preloadImages();
  }, [cartImages, totalImages]);

  const handleDrag = (id: number) => {
    bringToFront(id);
  };

  const loadingProgress = Math.min(Math.round((imagesLoaded / totalImages) * 100), 100);

  const handleDownload = async () => {
    if (!moodboardRef.current || isDownloading) return;

    
    setIsDownloading(true);
    try {
      // Wait for a small delay to ensure all animations are complete

      await new Promise(resolve => setTimeout(resolve, 500));

      // Force all images to load before capture
      const images = Array.from(moodboardRef.current.getElementsByTagName('img'));


      await Promise.all(images.map(img => {
        if (img.complete) {

          return Promise.resolve();
        }
        return new Promise((resolve, reject) => {

          img.onload = () => {

            resolve();
          };
          img.onerror = (error) => {
            console.error('Image failed to load:', img.src, error);
            reject(error);
          };
        });
      }));


      const canvas = await html2canvas(moodboardRef.current, {
        backgroundColor: 'rgb(227 201 168)',
        scale: 2,
        logging: true,
        allowTaint: true,
        useCORS: true,
        onclone: (clonedDoc) => {
          const clonedElement = clonedDoc.getElementById(moodboardRef.current?.id || '');

          
          if (clonedElement) {
            const originalStyles = window.getComputedStyle(moodboardRef.current!);


            clonedElement.style.width = `${viewportSize.width}px`;
            clonedElement.style.height = `${viewportSize.height}px`;
            clonedElement.style.position = 'relative';
            clonedElement.style.transform = 'none';
            
            const clonedStyles = window.getComputedStyle(clonedElement);


            // Log all images in the cloned element
            const clonedImages = clonedElement.getElementsByTagName('img');

          }
        }
      });


      // Create a download link
      const link = document.createElement('a');
      link.download = 'treasure-room-moodboard.png';
      const dataUrl = canvas.toDataURL('image/png');

      
      link.href = dataUrl;
      link.click();

    } catch (error) {
      console.error('Detailed error in moodboard capture:', {
        error,
        errorMessage: error instanceof Error ? error.message : String(error),
        errorStack: error instanceof Error ? error.stack : undefined,
        moodboardState: {
          ref: moodboardRef.current,
          images: moodboardImages,
          viewportSize
        }
      });
      toast.error('Failed to generate moodboard image. Please try again.');
    } finally {
      setIsDownloading(false);

    }
  };

  return (
    <div className="fixed inset-0 z-[10000] bg-[rgb(227,201,168)]/80 backdrop-blur-md">
      <div className="absolute top-4 right-4 z-10 flex gap-2">
        <button
          onClick={handleDownload}
          disabled={loading || isDownloading}
          className="p-2 rounded-full bg-white/10 hover:bg-white/20 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          aria-label="Download moodboard"
        >
          <Download className={`w-6 h-6 text-white ${isDownloading ? 'animate-pulse' : ''}`} />
        </button>
        <button
          onClick={onClose}
          className="p-2 rounded-full bg-white/10 hover:bg-white/20 transition-colors"
          aria-label="Close moodboard"
        >
          <X className="w-6 h-6 text-white" />
        </button>
      </div>

      <AnimatePresence>
        {loading && (
          <motion.div
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute inset-0 z-50 flex flex-col items-center justify-center"
          >
            <motion.div
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="text-center w-full max-w-md px-4"
            >
              <h2 className="text-2xl font-bold text-white mb-8">Creating Your Moodboard</h2>
              <div className="w-full h-2 bg-white/10 rounded-full overflow-hidden">
                <motion.div
                  initial={{ width: '0%' }}
                  animate={{ width: `${loadingProgress}%` }}
                  transition={{ duration: 0.5 }}
                  className="h-full bg-white rounded-full"
                />
              </div>
              <p className="text-white/70 mt-4">{loadingProgress}%</p>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: loading ? 0 : 1 }}
        className="relative w-full h-screen overflow-hidden"
        ref={containerRef}
      >
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate={loading ? "hidden" : "show"}
          className="relative"
          ref={moodboardRef}
          id="moodboard-container"
          style={{
            width: `${viewportSize.width}px`,
            height: `${viewportSize.height}px`,
            backgroundColor: 'rgb(227 201 168)',
            position: 'relative',
            transform: 'none'
          }}
        >
          {/* Logo */}
          <div className="absolute top-4 left-1/2 -translate-x-1/2 z-50 pointer-events-none">
            <img 
              src="/assets/logo.png" 
              alt="The Treasure Room Logo" 
              className="h-16 w-auto object-contain"
            />
          </div>

          {moodboardImages.map((image) => (
            <DraggableImage
              key={`${image.id}-${hasCalculatedPositions.current ? 'positioned' : 'initial'}`}
              image={image}
              zIndex={getZIndex(image.id, image.zIndex)}
              onDrag={() => handleDrag(image.id)}
              variants={imageVariants}
              isDraggable={true}
            />
          ))}
        </motion.div>
      </motion.div>
    </div>
  );
}; 