import React from 'react';
import { motion } from 'framer-motion';

interface CursorTooltipProps {
  text: string;
  isVisible: boolean;
  x: number;
  y: number;
}

export const CursorTooltip: React.FC<CursorTooltipProps> = ({ text, isVisible, x, y }) => {
  return (
    <motion.div
      style={{
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 9999,
        left: 0,
        top: 0,
        transform: `translate(${x}px, ${y}px)`,
      }}
      animate={{
        opacity: isVisible ? 1 : 0,
      }}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className="bg-black/80 text-white px-3 py-1.5 rounded-lg text-sm whitespace-nowrap"
    >
      {text}
    </motion.div>
  );
}; 