import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Check } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import SEOMetadata from '../components/SEOMetadata';

export const Success = () => {
  const navigate = useNavigate();
  const { clearCart } = useCart();

  // Generate SEO metadata for the success page
  const generateSEOMetadata = () => {
    let baseUrl = window.location.origin;
    if (baseUrl.includes('localhost') || baseUrl.includes('127.0.0.1')) {
      baseUrl = 'https://www.thetreasureroom.com.au';
    }
    
    return {
      title: 'Order Confirmation - Thank You',
      description: 'Thank you for your order with The Treasure Room. Your order request has been submitted successfully.',
      canonicalUrl: `${baseUrl}/success`,
      noIndex: true, // This is a transactional page, shouldn't be indexed
      ogType: 'website'
    };
  };
  
  const seoMetadata = generateSEOMetadata();

  useEffect(() => {
    // Clear the cart on successful order
    clearCart();
  }, [clearCart]);

  return (
    <div className="min-h-screen text-white pt-20 relative overflow-hidden">
      <SEOMetadata {...seoMetadata} />
      <div className="absolute inset-0 w-full h-full bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-[#5e0a0a] via-[#3d0707] to-black -z-10" />

      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="flex flex-col items-center justify-center text-center space-y-6">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ 
              type: "spring",
              stiffness: 260,
              damping: 20 
            }}
            className="w-20 h-20 rounded-full bg-green-500/20 backdrop-blur-sm 
                     flex items-center justify-center border border-green-500/30"
          >
            <Check className="w-10 h-10 text-green-500" />
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="space-y-4"
          >
            <h1 className="text-3xl font-bold">Order Submitted Successfully!</h1>
            <p className="text-lg text-white/70">
              Our team will be in contact shortly.
            </p>
          </motion.div>

          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
            onClick={() => navigate('/hire')}
            className="mt-8 px-6 py-3 bg-white/10 backdrop-blur-md text-white rounded-lg 
                     font-medium hover:bg-white/20 transition-colors border border-white/20"
          >
            Continue Shopping
          </motion.button>
        </div>
      </div>
    </div>
  );
}; 