import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';
import { Moodboard } from './components/Moodboard';
import { NavbarMenu } from './components/ui/navbar-menu';
import { Hire } from './pages/Hire';
import { CartProvider } from './context/CartContext';
import { Cart } from './components/Cart';
import { CheckoutPage } from './pages/Checkout';
import { Search } from './pages/Search';
import { Success } from './pages/Success';
import { ServiceModalProvider } from './context/ServiceModalContext';
import { ContactModalProvider } from './context/ContactModalContext';
import { AboutModalProvider } from './context/AboutModalContext';
import { MobileMenuProvider } from './context/MobileMenuContext';
import { ServiceRedirect } from './components/ServiceRedirect';
import SEOMetadata from './components/SEOMetadata';

function App() {
  return (
    <HelmetProvider>
      <div>
        {/* Default SEO metadata for pages that don't set their own */}
        <SEOMetadata 
          title="The Treasure Room - Premium Event & Prop Hire"
          description="Transform your event with premium styling items and props from The Treasure Room. We offer a curated collection of beautiful event decor for hire in Australia."
          canonicalUrl="https://www.thetreasureroom.com.au"
          ogType="website"
          ogImage="https://www.thetreasureroom.com.au/assets/og-image.png"
        />
        
        {/* Preload critical LCP images */}
        <Helmet>
          {/* Preload hero image for desktop */}
          <link 
            rel="preload" 
            href="/assets/collage_4.webp" 
            as="image" 
            media="(min-width: 1025px)" 
          />
          {/* Preload hero image for tablet */}
          <link 
            rel="preload" 
            href="/assets/collage_4.webp" 
            as="image" 
            media="(min-width: 769px) and (max-width: 1024px)" 
          />
          {/* Preload hero image for mobile */}
          <link 
            rel="preload" 
            href="/assets/collage_4.webp" 
            as="image" 
            media="(max-width: 768px)" 
          />
        </Helmet>
        
        <ContactModalProvider>
          <CartProvider>
            <Router>
              <ServiceModalProvider>
                <AboutModalProvider>
                  <MobileMenuProvider>
                    <ServiceRedirect />
                    <div className="min-h-screen">
                      <NavbarMenu />
                      <Routes>
                        <Route path="/" element={<Moodboard />} />
                        <Route path="/hire" element={<Hire />} />
                        <Route path="/hire/:tag" element={<Hire />} />
                        <Route path="/hire/:tag/page/:page" element={<Hire />} />
                        <Route path="/checkout" element={<CheckoutPage />} />
                        <Route path="/search" element={<Search />} />
                        <Route path="/success" element={<Success />} />
                        <Route path="/services/:service" element={<Moodboard />} />
                      </Routes>
                    </div>
                    <Cart />
                  </MobileMenuProvider>
                </AboutModalProvider>
              </ServiceModalProvider>
            </Router>
          </CartProvider>
        </ContactModalProvider>
      </div>
    </HelmetProvider>
  );
}

export default App;