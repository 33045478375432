import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Search as SearchIcon, Palette, Calendar, Users, Box, LayoutTemplate, Flower, Menu as MenuIcon, X, Mail,
  BrickWall, Cylinder, RectangleHorizontal, Sparkles, GalleryHorizontal, Theater, BookHeart, Armchair, Cuboid, CircleDashed, Joystick, Badge, Rabbit
} from 'lucide-react';
import { ServiceCard } from './service-card';
import { useServiceModal } from '../../context/ServiceModalContext';
import { useContactModal } from '../../context/ContactModalContext';
import { useAboutModal } from '../../context/AboutModalContext';
import { useMobileMenu } from '../../context/MobileMenuContext';
import { SearchPreview } from './search-preview';

const transition = {
  type: "spring",
  mass: 0.5,
  damping: 11.5,
  stiffness: 100,
  restDelta: 0.001,
  restSpeed: 0.001,
};

export const MenuItem = ({
  setActive,
  active,
  item,
  children,
}: {
  setActive: (item: string) => void;
  active: string | null;
  item: string;
  children?: React.ReactNode;
}) => {
  return (
    <div onMouseEnter={() => setActive(item)} className="relative">
      <motion.p
        transition={{ duration: 0.3 }}
        className="cursor-pointer text-black hover:opacity-[0.9] dark:text-white"
      >
        {item}
      </motion.p>
      {active !== null && (
        <motion.div
          initial={{ opacity: 0, scale: 0.85, y: 10 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          transition={transition}
        >
          {active === item && (
            <div className="absolute top-full left-1/2 transform -translate-x-1/2">
              <div className="h-[1rem]" />
              <motion.div
                transition={transition}
                layoutId="active"
                className="bg-white dark:bg-black backdrop-blur-sm rounded-2xl overflow-hidden border border-black/[0.2] dark:border-white/[0.2] shadow-xl"
              >
                <motion.div
                  layout
                  className="w-max max-h-[90vh] overflow-y-auto p-4 scrollbar-thin scrollbar-thumb-black/10 dark:scrollbar-thumb-white/10 scrollbar-track-transparent"
                >
                  {children}
                </motion.div>
              </motion.div>
            </div>
          )}
        </motion.div>
      )}
    </div>
  );
};

export const SearchBar = ({ isMobile = false }: { isMobile?: boolean }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const navigate = useNavigate();
  const { setIsMobileMenuOpen } = useMobileMenu();
  const [searchParams] = useSearchParams();

  // Update local search query when URL changes
  useEffect(() => {
    const queryParam = searchParams.get('q');
    if (queryParam !== searchQuery) {
      setSearchQuery(queryParam || '');
    }
  }, [searchParams]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchQuery.trim())}`);
      if (isMobile) {
        setIsMobileMenuOpen(false);
      }
      setShowPreview(false);
    }
  };

  const handleSelect = (query: string) => {
    setSearchQuery(query);
    navigate(`/search?q=${encodeURIComponent(query)}`);
    if (isMobile) {
      setIsMobileMenuOpen(false);
    }
    setShowPreview(false);
  };

  return (
    <div className="relative">
      <form
        onSubmit={handleSubmit}
        className={`relative ${isMobile ? 'flex w-full' : 'hidden md:flex'} items-center`}
      >
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setShowPreview(true);
          }}
          onFocus={() => setShowPreview(true)}
          placeholder="Search products..."
          className="w-full px-4 py-1.5 rounded-full bg-transparent border border-black/[0.2] 
                   dark:border-white/[0.2] text-neutral-700 dark:text-neutral-200 
                   placeholder-neutral-500 dark:placeholder-neutral-400 text-sm 
                   focus:outline-none focus:ring-2 focus:ring-black/20 
                   dark:focus:ring-white/20 pr-10"
        />
        <button
          onClick={handleSubmit}
          type="submit"
          className="absolute right-3 text-neutral-700 dark:text-neutral-200 
                   hover:text-black dark:hover:text-white transition-colors"
          aria-label="Search"
        >
          <SearchIcon className="w-4 h-4" />
        </button>
      </form>
      {showPreview && (
        <SearchPreview
          query={searchQuery}
          onSelect={handleSelect}
          onClose={() => setShowPreview(false)}
        />
      )}
    </div>
  );
};

export const Menu = ({
  setActive,
  children,
}: {
  setActive: (item: string | null) => void;
  children: React.ReactNode;
}) => {
  return (
    <nav
      onMouseLeave={() => setActive(null)}
      className="relative rounded-full border border-transparent dark:bg-black/80 dark:border-white/[0.2] bg-white/80 shadow-input flex items-center space-x-4 px-4 py-2 backdrop-blur-md"
    >
      <Link to="/" className="flex items-center">
        <img
          src="/assets/logo.png"
          alt="The Treasure Room"
          className="h-8 w-32 object-contain"
          width="128"
          height="32"
          loading="eager"
        />
      </Link>
      <div className="border-l border-black/[0.2] dark:border-white/[0.2] h-8" />
      {children}
      <div className="ml-4 pl-4 border-l border-black/[0.2] dark:border-white/[0.2]">
        <SearchBar />
      </div>
    </nav>
  );
};

export const HoveredLink = ({ children, href, className, ...rest }: any) => {
  return (
    <Link
      to={href}
      {...rest}
      className={`text-neutral-700 dark:text-neutral-200 hover:text-black dark:hover:text-white ${className || ''}`}
    >
      {children}
    </Link>
  );
};

export const NavbarMenu = () => {
  const [active, setActive] = useState<string | null>(null);
  const { isMobileMenuOpen, setIsMobileMenuOpen } = useMobileMenu();
  const { activeService, setActiveService } = useServiceModal();
  const { openModal: openContactModal } = useContactModal();
  const { openModal: openAboutModal } = useAboutModal();

  const handleServiceClick = (e: React.MouseEvent<HTMLAnchorElement>, service: string) => {
    e.preventDefault();
    setActiveService(service);
    setIsMobileMenuOpen(false);
  };

  const handleAboutClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    openAboutModal();
    setIsMobileMenuOpen(false);
  };

  const handleContactClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    openContactModal();
    setIsMobileMenuOpen(false);
  };

  const servicesStructuredData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "The Treasure Room",
    "description": "Sydney's premier event styling and hire service, specialising in luxury event decor, custom builds, and professional styling services for weddings, corporate events, and special occasions.",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "10/111 Bonds Road",
      "addressLocality": "Riverwood",
      "addressRegion": "NSW",
      "postalCode": "2210",
      "addressCountry": "AU"
    },
    "offers": [
      {
        "@type": "Service",
        "name": "Event Hire",
        "description": "We offer a wide range of event hire products, including backdrops, tables, chairs, and more. Our hire service is designed to provide you with everything you need to create a beautiful and functional event space.",
        "areaServed": "Sydney",
        "category": "Event Services"

      },
      {
        "@type": "Service",
        "name": "Event Styling",
        "description": "Our event styling service is a comprehensive solution for creating unforgettable experiences. We work closely with you to understand your vision and bring it to life through careful attention to detail, innovative design concepts, and flawless execution. From intimate gatherings to large-scale events, we handle everything from initial concept development to on-site styling and setup.",
        "areaServed": "Sydney",
        "category": "Event Services"
      },
      {
        "@type": "Service",
        "name": "Event Coordination",
        "description": "Our event coordination service provides end-to-end management of your special day. From initial planning and vendor coordination to on-the-day execution, our experienced coordinators ensure every detail is perfectly managed. We handle timeline creation, supplier liaison, setup supervision, and seamless event flow management.",
        "areaServed": "Sydney",
        "category": "Event Planning"
      },
      {
        "@type": "Service",
        "name": "Custom Builds",
        "description": "Our custom build service specialises in creating unique, made-to-order pieces that make your event truly one-of-a-kind. Whether you're looking for a custom backdrop, themed installation, or specialised event furniture, our team of skilled craftsmen can bring your ideas to life. We handle the entire process from initial design concepts through to manufacturing and installation.",
        "areaServed": "Sydney",
        "category": "Custom Event Furniture"
      },
      {
        "@type": "Service",
        "name": "Custom Signage",
        "description": "Create a lasting impression with our custom signage solutions. Whether you need welcome signs, seating charts, menu displays, or directional signage, we craft beautiful pieces that perfectly complement your event aesthetic. Each piece is carefully designed and produced using premium materials, ensuring both visual impact and durability.",
        "areaServed": "Sydney",
        "category": "Event Signage"
      },
      {
        "@type": "Service",
        "name": "Faux Florals",
        "description": "Our premium faux floral service offers the beauty of botanical designs without the limitations of fresh flowers. Perfect for installations, archways, ceiling features, and table centerpieces that need to maintain their beauty throughout your event. We use only the highest quality artificial flowers and foliage through our faux floral partners, creating stunning arrangements that look remarkably natural.",
        "areaServed": "Sydney",
        "category": "Event Decor"
      }
    ],
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": ["Monday", "Wednesday", "Thursday", "Friday"],
        "opens": "10:00",
        "closes": "16:00"
      }
    ],
    "url": "https://thetreasureroom.com.au",
    "telephone": "0420324722",
    "email": "info@thetreasureroom.com.au",
    "priceRange": "$$",
    "image": "https://thetreasureroom.com.au/assets/logo.png"
  };

  return (
    <div className="fixed top-4 left-1/2 -translate-x-1/2 z-[9999] w-full px-4 lg:px-0 lg:w-auto">
      {/* Desktop Menu */}
      <div className="hidden lg:block">
        <Menu setActive={setActive}>
          <button
            onClick={handleAboutClick}
            className="text-black hover:opacity-[0.9] dark:text-white"
          >
            About
          </button>
          <MenuItem setActive={setActive} active={active} item="Services">
            <div className="flex flex-col space-y-4 text-sm">
              <HoveredLink href="/styling" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => handleServiceClick(e, 'styling')}>
                <div className="flex items-center gap-2">
                  <Palette className="w-4 h-4" />
                  <span>Event Styling</span>
                </div>
              </HoveredLink>
              <HoveredLink href="/event-coordination" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => handleServiceClick(e, 'event-coordination')}>
                <div className="flex items-center gap-2">
                  <Calendar className="w-4 h-4" />
                  <span>Event Coordination</span>
                </div>
              </HoveredLink>
              <HoveredLink href="/custom-builds" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => handleServiceClick(e, 'custom-builds')}>
                <div className="flex items-center gap-2">
                  <Box className="w-4 h-4" />
                  <span>Custom Builds</span>
                </div>
              </HoveredLink>
              <HoveredLink href="/custom-signage" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => handleServiceClick(e, 'custom-signage')}>
                <div className="flex items-center gap-2">
                  <LayoutTemplate className="w-4 h-4" />
                  <span>Custom Signage</span>
                </div>
              </HoveredLink>
              <HoveredLink href="/faux-florals" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => handleServiceClick(e, 'faux-florals')}>
                <div className="flex items-center gap-2">
                  <Flower className="w-4 h-4" />
                  <span>Faux Florals</span>
                </div>
              </HoveredLink>
            </div>
          </MenuItem>
          <MenuItem setActive={setActive} active={active} item="Hire">
            <div className="w-96 p-4">
              <div className="grid gap-4">
                <div>
                  <HoveredLink href="/hire">
                    <div className="flex items-center gap-2 text-sm font-medium">
                      <Box className="w-4 h-4" />
                      <span>View All Products</span>
                    </div>
                  </HoveredLink>
                </div>

                <div>
                  <HoveredLink href="/hire/backdrops">
                    <div className="flex items-center gap-2 text-sm font-medium">
                      <BrickWall className="w-4 h-4" />
                      <span>Backdrops</span>
                    </div>
                  </HoveredLink>
                  <div className="grid grid-cols-2 gap-2 mt-2 pl-6 text-xs">
                    <HoveredLink href="/hire/solid_walls">Solid Walls</HoveredLink>
                    <HoveredLink href="/hire/metal_backdrops">Metal Backdrops</HoveredLink>
                    <HoveredLink href="/hire/ceremony_arches">Ceremony Arches</HoveredLink>
                  </div>
                </div>
                <div>
                  <HoveredLink href="/hire/plinths">
                    <div className="flex items-center gap-2 text-sm font-medium">
                      <Cylinder className="w-4 h-4" />
                      <span>Plinths</span>
                    </div>
                  </HoveredLink>
                  <div className="grid grid-cols-2 gap-2 mt-2 pl-6 text-xs">
                    <HoveredLink href="/hire/cake_plinths">Cake Plinths</HoveredLink>
                    <HoveredLink href="/hire/ghost_plinths">Ghost Plinths</HoveredLink>
                    <HoveredLink href="/hire/acrylic_plinths">Acrylic Plinths</HoveredLink>
                    <HoveredLink href="/hire/wooden_plinths">Wooden Plinths</HoveredLink>
                    <HoveredLink href="/hire/coloured_plinths">Coloured Plinths</HoveredLink>
                  </div>
                </div>
                <div>
                  <HoveredLink href="/hire/tables">
                    <div className="flex items-center gap-2 text-sm font-medium">
                      <RectangleHorizontal className="w-4 h-4" />
                      <span>Tables</span>
                    </div>
                  </HoveredLink>
                  <div className="grid grid-cols-2 gap-2 mt-2 pl-6 text-xs">
                    <HoveredLink href="/hire/dining_tables">Dining Tables</HoveredLink>
                    <HoveredLink href="/hire/side_tables">Side Tables</HoveredLink>
                    <HoveredLink href="/hire/coffee_tables">Coffee Tables</HoveredLink>
                    <HoveredLink href="/hire/cake_tables">Cake Tables</HoveredLink>
                    <HoveredLink href="/hire/bars_djtables">Bars & DJ Tables</HoveredLink>
                    <HoveredLink href="/hire/tableware">Tableware</HoveredLink>
                    <HoveredLink href="/hire/tableclothes">Tableclothes</HoveredLink>

                  </div>
                </div>
                <div>
                  <HoveredLink href="/hire/decor">
                    <div className="flex items-center gap-2 text-sm font-medium">
                      <Sparkles className="w-4 h-4" />
                      <span>Decor</span>
                    </div>
                  </HoveredLink>
                  <div className="grid grid-cols-2 gap-2 mt-2 pl-6 text-xs">
                    <HoveredLink href="/hire/candle_holders">Candle Holders</HoveredLink>
                    <HoveredLink href="/hire/trays">Trays</HoveredLink>
                    <HoveredLink href="/hire/wishing_wells">Wishing Wells</HoveredLink>
                    <HoveredLink href="/hire/servingware">Servingware</HoveredLink>
                    <HoveredLink href="/hire/ice_buckets">Ice Buckets</HoveredLink>
                    <HoveredLink href="/hire/bows">Bows</HoveredLink>
                    <HoveredLink href="/hire/cake_stands">Cake Stands</HoveredLink>
                    <HoveredLink href="/hire/pots_vases">Pots & Vases</HoveredLink>
                    <HoveredLink href="/hire/cushions_rugs">Cushions & Rugs</HoveredLink>
                    <HoveredLink href="/hire/umbrellas">Umbrellas</HoveredLink>
                    <HoveredLink href="/hire/table_numbers">Table Numbers</HoveredLink>
                  </div>
                </div>
                <div>
                  <HoveredLink href="/hire/signage_stands">
                    <div className="flex items-center gap-2 text-sm font-medium">
                      <GalleryHorizontal className="w-4 h-4" />
                      <span>Signage Stands</span>
                    </div>
                  </HoveredLink>
                  <div className="grid grid-cols-2 gap-2 mt-2 pl-6 text-xs">
                    <HoveredLink href="/hire/easels">Easels</HoveredLink>
                    <HoveredLink href="/hire/boxes">Boxes</HoveredLink>
                    <HoveredLink href="/hire/hanging_stands">Hanging Stands</HoveredLink>
                  </div>
                </div>
                <div>
                  <HoveredLink href="/hire/stages">
                    <div className="flex items-center gap-2 text-sm font-medium">
                      <Theater className="w-4 h-4" />
                      <span>Stages</span>
                    </div>
                  </HoveredLink>
                </div>
                <div>
                  <HoveredLink href="/hire/wedding">
                    <div className="flex items-center gap-2 text-sm font-medium">
                      <BookHeart className="w-4 h-4" />
                      <span>Weddings</span>
                    </div>
                  </HoveredLink>
                </div>
                <div>
                  <HoveredLink href="/hire/seating">
                    <div className="flex items-center gap-2 text-sm font-medium">
                      <Armchair className="w-4 h-4" />
                      <span>Seating</span>
                    </div>
                  </HoveredLink>
                </div>
                <div>
                  <HoveredLink href="/hire/carts_trolleys">
                    <div className="flex items-center gap-2 text-sm font-medium">
                      <Cuboid className="w-4 h-4" />
                      <span>Carts & Trolleys</span>
                    </div>
                  </HoveredLink>
                </div>
                <div>
                  <HoveredLink href="/hire/ghost_range">
                    <div className="flex items-center gap-2 text-sm font-medium">
                      <CircleDashed className="w-4 h-4" />
                      <span>Ghost Range</span>
                    </div>
                  </HoveredLink>
                </div>
                <div>
                  <HoveredLink href="/hire/kids_themes">
                    <div className="flex items-center gap-2 text-sm font-medium">
                      <Joystick className="w-4 h-4" />
                      <span>Kids Themes</span>
                    </div>
                  </HoveredLink>
                </div>
                <div>
                  <HoveredLink href="/hire/animals">
                    <div className="flex items-center gap-2 text-sm font-medium">
                      <Rabbit className="w-4 h-4" />
                      <span>Animals</span>
                    </div>
                  </HoveredLink>
                </div>
                <div>
                  <HoveredLink href="/hire/neon_signs">
                    <div className="flex items-center gap-2 text-sm font-medium">
                      <Badge className="w-4 h-4" />
                      <span>Neon Signs</span>
                    </div>
                  </HoveredLink>
                </div>


              </div>
            </div>
          </MenuItem>
          <button
            onClick={handleContactClick}
            className="text-black hover:opacity-[0.9] dark:text-white"
          >
            Contact
          </button>
        </Menu>
      </div>

      {/* Mobile Menu Button */}
      <div className="lg:hidden flex items-center justify-between bg-white/80 dark:bg-black/80 rounded-full border border-black/[0.2] dark:border-white/[0.2] px-4 py-2 max-w-3xl mx-auto">
        <Link to="/" className="flex items-center">
          <img src="/assets/logo.png" alt="The Treasure Room" className="h-8 object-contain" />
        </Link>
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="p-2 hover:bg-white/10 rounded-lg transition-colors"
          aria-label={isMobileMenuOpen ? "Close menu" : "Open menu"}
          aria-expanded={isMobileMenuOpen}
        >
          {isMobileMenuOpen ? (
            <X className="w-6 h-6 text-black dark:text-white" />
          ) : (
            <MenuIcon className="w-6 h-6 text-black dark:text-white" />
          )}
        </button>
      </div>

      {/* Mobile Menu Overlay */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 z-[9998] lg:hidden pointer-events-auto"
              onClick={() => setIsMobileMenuOpen(false)}
            />
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="fixed top-20 left-4 right-4 max-w-3xl mx-auto bg-white dark:bg-black rounded-2xl 
                       border border-black/[0.2] dark:border-white/[0.2] shadow-xl pointer-events-auto
                       max-h-[70vh] overflow-hidden flex flex-col z-[9999]"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="p-4 border-b border-black/[0.1] dark:border-white/[0.1] shrink-0">
                <SearchBar isMobile />
              </div>
              <div className="overflow-y-auto flex-1 overscroll-contain">
                <div className="p-4">
                  <div className="flex flex-col space-y-4">
                    <button
                      onClick={handleAboutClick}
                      className="text-left px-4 py-2 text-black dark:text-white hover:bg-black/5 dark:hover:bg-white/5 rounded-lg flex items-center gap-2"
                    >
                      <Users className="w-4 h-4 shrink-0" />
                      <span>About</span>
                    </button>

                    <button
                      onClick={handleContactClick}
                      className="text-left px-4 py-2 text-black dark:text-white hover:bg-black/5 dark:hover:bg-white/5 rounded-lg flex items-center gap-2"
                    >
                      <Mail className="w-4 h-4 shrink-0" />
                      <span>Contact</span>
                    </button>

                    <div className="px-4 py-2">
                      <div className="font-medium text-black dark:text-white mb-2">Services</div>
                      <div className="ml-4 flex flex-col space-y-3">
                        <HoveredLink
                          href="/styling"
                          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                            handleServiceClick(e, 'styling');
                            setIsMobileMenuOpen(false);
                          }}
                          className="flex items-center gap-2"
                        >
                          <Palette className="w-4 h-4 shrink-0" />
                          <span>Event Styling</span>
                        </HoveredLink>
                        <HoveredLink
                          href="/event-coordination"
                          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                            handleServiceClick(e, 'event-coordination');
                            setIsMobileMenuOpen(false);
                          }}
                          className="flex items-center gap-2"
                        >
                          <Calendar className="w-4 h-4 shrink-0" />
                          <span>Event Coordination</span>
                        </HoveredLink>
                        <HoveredLink
                          href="/custom-builds"
                          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                            handleServiceClick(e, 'custom-builds');
                            setIsMobileMenuOpen(false);
                          }}
                          className="flex items-center gap-2"
                        >
                          <Box className="w-4 h-4 shrink-0" />
                          <span>Custom Builds</span>
                        </HoveredLink>
                        <HoveredLink
                          href="/custom-signage"
                          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                            handleServiceClick(e, 'custom-signage');
                            setIsMobileMenuOpen(false);
                          }}
                          className="flex items-center gap-2"
                        >
                          <LayoutTemplate className="w-4 h-4 shrink-0" />
                          <span>Custom Signage</span>
                        </HoveredLink>
                        <HoveredLink
                          href="/faux-florals"
                          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                            handleServiceClick(e, 'faux-florals');
                            setIsMobileMenuOpen(false);
                          }}
                          className="flex items-center gap-2"
                        >
                          <Flower className="w-4 h-4 shrink-0" />
                          <span>Faux Florals</span>
                        </HoveredLink>
                      </div>
                    </div>

                    <div className="px-4 py-2">
                      <div className="font-medium text-black dark:text-white mb-2">Hire</div>
                      <div className="ml-4 flex flex-col space-y-3">
                        <HoveredLink
                          href="/hire"
                          onClick={() => setIsMobileMenuOpen(false)}
                          className="flex items-center gap-2"
                        >
                          <Box className="w-4 h-4 shrink-0" />
                          <span>View All Products</span>
                        </HoveredLink>

                        <div>
                          <HoveredLink
                            href="/hire/backdrops"
                            onClick={() => setIsMobileMenuOpen(false)}
                            className="flex items-center gap-2"
                          >
                            <BrickWall className="w-4 h-4 shrink-0" />
                            <span>Backdrops</span>
                          </HoveredLink>
                          <div className="grid grid-cols-2 gap-2 mt-2 pl-6 text-xs">
                            <HoveredLink href="/hire/solid_walls" onClick={() => setIsMobileMenuOpen(false)}>Solid Walls</HoveredLink>
                            <HoveredLink href="/hire/metal_backdrops" onClick={() => setIsMobileMenuOpen(false)}>Metal Backdrops</HoveredLink>
                            <HoveredLink href="/hire/ceremony_arches" onClick={() => setIsMobileMenuOpen(false)}>Ceremony Arches</HoveredLink>
                          </div>
                        </div>

                        <div>
                          <HoveredLink
                            href="/hire/plinths"
                            onClick={() => setIsMobileMenuOpen(false)}
                            className="flex items-center gap-2"
                          >
                            <Cylinder className="w-4 h-4 shrink-0" />
                            <span>Plinths</span>
                          </HoveredLink>
                          <div className="grid grid-cols-2 gap-2 mt-2 pl-6 text-xs">
                            <HoveredLink href="/hire/cake_plinths" onClick={() => setIsMobileMenuOpen(false)}>Cake Plinths</HoveredLink>
                            <HoveredLink href="/hire/ghost_plinths" onClick={() => setIsMobileMenuOpen(false)}>Ghost Plinths</HoveredLink>
                            <HoveredLink href="/hire/acrylic_plinths" onClick={() => setIsMobileMenuOpen(false)}>Acrylic Plinths</HoveredLink>
                            <HoveredLink href="/hire/wooden_plinths" onClick={() => setIsMobileMenuOpen(false)}>Wooden Plinths</HoveredLink>
                            <HoveredLink href="/hire/coloured_plinths" onClick={() => setIsMobileMenuOpen(false)}>Coloured Plinths</HoveredLink>
                          </div>
                        </div>

                        <div>
                          <HoveredLink
                            href="/hire/tables"
                            onClick={() => setIsMobileMenuOpen(false)}
                            className="flex items-center gap-2"
                          >
                            <RectangleHorizontal className="w-4 h-4 shrink-0" />
                            <span>Tables</span>
                          </HoveredLink>
                          <div className="grid grid-cols-2 gap-2 mt-2 pl-6 text-xs">
                            <HoveredLink href="/hire/dining_tables" onClick={() => setIsMobileMenuOpen(false)}>Dining Tables</HoveredLink>
                            <HoveredLink href="/hire/side_tables" onClick={() => setIsMobileMenuOpen(false)}>Side Tables</HoveredLink>
                            <HoveredLink href="/hire/coffee_tables" onClick={() => setIsMobileMenuOpen(false)}>Coffee Tables</HoveredLink>
                            <HoveredLink href="/hire/cake_tables" onClick={() => setIsMobileMenuOpen(false)}>Cake Tables</HoveredLink>
                            <HoveredLink href="/hire/tableware" onClick={() => setIsMobileMenuOpen(false)}>Tableware</HoveredLink>
                            <HoveredLink href="/hire/tableclothes" onClick={() => setIsMobileMenuOpen(false)}>Tableclothes</HoveredLink>
                            <HoveredLink href="/hire/bars_djtables" onClick={() => setIsMobileMenuOpen(false)}>Bars & DJ Tables</HoveredLink>
                          </div>
                        </div>

                        <div>
                          <HoveredLink
                            href="/hire/decor"
                            onClick={() => setIsMobileMenuOpen(false)}
                            className="flex items-center gap-2"
                          >
                            <Sparkles className="w-4 h-4 shrink-0" />
                            <span>Decor</span>
                          </HoveredLink>
                          <div className="grid grid-cols-2 gap-2 mt-2 pl-6 text-xs">
                            <HoveredLink href="/hire/candle_holders" onClick={() => setIsMobileMenuOpen(false)}>Candle Holders</HoveredLink>
                            <HoveredLink href="/hire/trays" onClick={() => setIsMobileMenuOpen(false)}>Trays</HoveredLink>
                            <HoveredLink href="/hire/wishing_wells" onClick={() => setIsMobileMenuOpen(false)}>Wishing Wells</HoveredLink>
                            <HoveredLink href="/hire/servingware" onClick={() => setIsMobileMenuOpen(false)}>Servingware</HoveredLink>
                            <HoveredLink href="/hire/ice_buckets" onClick={() => setIsMobileMenuOpen(false)}>Ice Buckets</HoveredLink>
                            <HoveredLink href="/hire/bows" onClick={() => setIsMobileMenuOpen(false)}>Bows</HoveredLink>
                            <HoveredLink href="/hire/cake_stands" onClick={() => setIsMobileMenuOpen(false)}>Cake Stands</HoveredLink>
                            <HoveredLink href="/hire/pots_vases" onClick={() => setIsMobileMenuOpen(false)}>Pots & Vases</HoveredLink>
                            <HoveredLink href="/hire/cushions_rugs" onClick={() => setIsMobileMenuOpen(false)}>Cushions & Rugs</HoveredLink>
                            <HoveredLink href="/hire/umbrellas" onClick={() => setIsMobileMenuOpen(false)}>Umbrellas</HoveredLink>
                            <HoveredLink href="/hire/table_numbers" onClick={() => setIsMobileMenuOpen(false)}>Table Numbers</HoveredLink>
                          </div>
                        </div>

                        <div>
                          <HoveredLink
                            href="/hire/signage_stands"
                            onClick={() => setIsMobileMenuOpen(false)}
                            className="flex items-center gap-2"
                          >
                            <GalleryHorizontal className="w-4 h-4 shrink-0" />
                            <span>Signage Stands</span>
                          </HoveredLink>
                          <div className="grid grid-cols-2 gap-2 mt-2 pl-6 text-xs">
                            <HoveredLink href="/hire/easels" onClick={() => setIsMobileMenuOpen(false)}>Easels</HoveredLink>
                            <HoveredLink href="/hire/boxes" onClick={() => setIsMobileMenuOpen(false)}>Boxes</HoveredLink>
                            <HoveredLink href="/hire/hanging_stands" onClick={() => setIsMobileMenuOpen(false)}>Hanging Stands</HoveredLink>
                          </div>
                        </div>

                        <HoveredLink
                          href="/hire/stages"
                          onClick={() => setIsMobileMenuOpen(false)}
                          className="flex items-center gap-2"
                        >
                          <Theater className="w-4 h-4 shrink-0" />
                          <span>Stages</span>
                        </HoveredLink>

                        <HoveredLink
                          href="/hire/wedding"
                          onClick={() => setIsMobileMenuOpen(false)}
                          className="flex items-center gap-2"
                        >
                          <BookHeart className="w-4 h-4 shrink-0" />
                          <span>Weddings</span>
                        </HoveredLink>

                        <HoveredLink
                          href="/hire/seating"
                          onClick={() => setIsMobileMenuOpen(false)}
                          className="flex items-center gap-2"
                        >
                          <Armchair className="w-4 h-4 shrink-0" />
                          <span>Seating</span>
                        </HoveredLink>

                        <HoveredLink
                          href="/hire/carts_trolleys"
                          onClick={() => setIsMobileMenuOpen(false)}
                          className="flex items-center gap-2"
                        >
                          <Cuboid className="w-4 h-4 shrink-0" />
                          <span>Carts & Trolleys</span>
                        </HoveredLink>

                        <HoveredLink
                          href="/hire/ghost_range"
                          onClick={() => setIsMobileMenuOpen(false)}
                          className="flex items-center gap-2"
                        >
                          <CircleDashed className="w-4 h-4 shrink-0" />
                          <span>Ghost Range</span>
                        </HoveredLink>

                        <HoveredLink
                          href="/hire/kids_themes"
                          onClick={() => setIsMobileMenuOpen(false)}
                          className="flex items-center gap-2"
                        >
                          <Joystick className="w-4 h-4 shrink-0" />
                          <span>Kids Themes</span>
                        </HoveredLink>

                        <HoveredLink
                          href="/hire/animals"
                          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                            setIsMobileMenuOpen(false);
                          }}
                          className="flex items-center gap-2"
                        >
                          <Rabbit className="w-4 h-4 shrink-0" />
                          <span>Animals</span>
                        </HoveredLink>

                        <HoveredLink
                          href="/hire/neon_signs"
                          onClick={() => setIsMobileMenuOpen(false)}
                          className="flex items-center gap-2"
                        >
                          <Badge className="w-4 h-4 shrink-0" />
                          <span>Neon Signs</span>
                        </HoveredLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
}; 