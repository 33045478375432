import { useState } from 'react';
import { fetchBundleDetails, fetchProductGroup, fetchProductById } from '../services/booqableApi';

export const useBundleHandling = () => {
  const [bundleDetails, setBundleDetails] = useState<{ [key: string]: any }>({});
  const [loadingBundleDetails, setLoadingBundleDetails] = useState<string[]>([]);
  const [bundleSelections, setBundleSelections] = useState<{ [bundleId: string]: BundleSelections }>({});
  const [productGroups, setProductGroups] = useState<{ [groupId: string]: ProductGroupResponse }>({});
  const [productDetails, setProductDetails] = useState<{ [productId: string]: ProductDetails }>({});

  const handleBundleExpand = async (bundleId: string, isExpanding: boolean) => {
    if (!isExpanding || bundleDetails[bundleId]) return;
    
    try {
      setLoadingBundleDetails(prev => [...prev, bundleId]);
      const response = await fetchBundleDetails(bundleId);
      


      setBundleDetails(prev => ({
        ...prev,
        [bundleId]: {
          ...response,
          data: {
            ...response.data,
            attributes: {
              ...response.data.attributes,
              inventory: response.data.attributes.inventory
            }
          }
        }
      }));

      const selections: BundleSelections = {};
      const bundleItems = response.included?.filter(item => item.type === 'bundle_items') || [];
      const includedProducts = response.included?.filter(item => item.type === 'products') || [];
      
      const productsMap = includedProducts.reduce((map, product) => {
        const productWithInventory = {
          ...product,
          attributes: {
            ...product.attributes,
            inventory: {
              available: 0,
              stock_count: 0,
              plannable: 0
            }
          }
        };
        map[product.id] = productWithInventory;
        return map;
      }, {} as { [key: string]: any });
      
      for (const item of bundleItems) {
        selections[item.id] = {
          bundleItemId: item.id,
          productGroupId: item.attributes.product_group_id,
          selectedProductId: item.attributes.product_id,
          initialProductId: item.attributes.product_id,
          quantity: item.attributes.quantity,
          required: true,
          name: item.attributes.name || 'Bundle Item',
          discount_percentage: item.attributes.discount_percentage || 0
        };

        if (item.attributes.product_id && productsMap[item.attributes.product_id]) {
          setProductDetails(prev => ({
            ...prev,
            [item.attributes.product_id]: {
              data: productsMap[item.attributes.product_id]
            }
          }));
        }

        if (item.attributes.product_group_id && !item.attributes.product_id) {
          const groupResponse = await fetchProductGroup(item.attributes.product_group_id);
          setProductGroups(prev => ({
            ...prev,
            [item.attributes.product_group_id!]: groupResponse
          }));
        }
      }

      setBundleSelections(prev => ({
        ...prev,
        [bundleId]: selections
      }));
    } catch (error) {
      console.error(`Error fetching bundle ${bundleId} details:`, error);
    } finally {
      setLoadingBundleDetails(prev => prev.filter(id => id !== bundleId));
    }
  };

  const handleBundleItemProductSelect = async (
    bundleId: string,
    bundleItemId: string,
    productId: string | null
  ) => {
    setBundleSelections(prev => ({
      ...prev,
      [bundleId]: {
        ...prev[bundleId],
        [bundleItemId]: {
          ...prev[bundleId][bundleItemId],
          selectedProductId: productId
        }
      }
    }));

    if (productId && !productDetails[productId]) {
      const productResponse = await fetchProductById(productId);
      setProductDetails(prev => ({
        ...prev,
        [productId]: productResponse
      }));
    }
  };

  const isBundleComplete = (bundleId: string) => {
    const selections = bundleSelections[bundleId];
    if (!selections) return false;
    return Object.values(selections).every(selection => 
      !selection.required || selection.selectedProductId !== null
    );
  };

  return {
    bundleDetails,
    loadingBundleDetails,
    bundleSelections,
    productGroups,
    productDetails,
    handleBundleExpand,
    handleBundleItemProductSelect,
    isBundleComplete
  };
}; 