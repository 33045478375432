import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight } from 'lucide-react';

export interface CarouselProps {
  images: string[];
  initialSlide?: number;
  currentSlide?: number;
  onSlideChange?: (index: number) => void;
}

export const Carousel: React.FC<CarouselProps> = ({ 
  images, 
  initialSlide = 0,
  currentSlide,
  onSlideChange 
}) => {
  const [internalSlide, setInternalSlide] = useState(initialSlide);
  
  // Update internal slide when currentSlide prop changes
  useEffect(() => {
    if (currentSlide !== undefined) {
      setInternalSlide(currentSlide);
    }
  }, [currentSlide]);

  const handleNext = () => {
    const newSlide = (internalSlide + 1) % images.length;
    setInternalSlide(newSlide);
    onSlideChange?.(newSlide);
  };

  const handlePrevious = () => {
    const newSlide = (internalSlide - 1 + images.length) % images.length;
    setInternalSlide(newSlide);
    onSlideChange?.(newSlide);
  };

  const handleDotClick = (index: number) => {
    setInternalSlide(index);
    onSlideChange?.(index);
  };

  return (
    <div className="relative w-full h-full">
      <AnimatePresence mode="wait">
        <motion.img
          key={internalSlide}
          src={images[internalSlide]}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="w-full h-full object-cover cursor-pointer"
          alt="Service showcase"
          onClick={() => window.open(images[internalSlide], '_blank')}
          title="Click to view full size"
        />
      </AnimatePresence>

      {images.length > 1 && (
        <>
          <button
            onClick={handlePrevious}
            className="absolute left-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/20 
                     backdrop-blur-md text-white hover:bg-black/40 transition-colors"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
          <button
            onClick={handleNext}
            className="absolute right-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/20 
                     backdrop-blur-md text-white hover:bg-black/40 transition-colors"
          >
            <ChevronRight className="w-6 h-6" />
          </button>

          <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex gap-2">
            {images.map((_, index) => (
              <button
                key={index}
                onClick={() => handleDotClick(index)}
                className={`w-2 h-2 rounded-full transition-colors ${
                  index === internalSlide ? 'bg-white' : 'bg-white/50'
                }`}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}; 