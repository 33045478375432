import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useOutsideClick } from '../hooks/useOutsideClick';
import { useId, useRef } from 'react';
import { X } from 'lucide-react';
import { calculateBundlePrice } from '../lib/utils';
import { useMobileMenu } from '../context/MobileMenuContext';
import { Carousel } from './ui/carousel';
import { useProductGroupHandling } from '../hooks/useProductGroupHandling';
import { 
  HireItem, 
  Photo, 
  BundleSelections, 
  ProductGroupResponse, 
  ProductDetails,
  Product
} from '../services/booqableApi';
import { toast } from 'react-hot-toast';
import { CartItem } from '../context/CartContext';

interface BundleSelection {
  bundleItemId: string;
  productGroupId?: string;
  productId?: string;
  selectedProductId?: string;
  name: string;
  quantity: number;
  discount_percentage: number;
  initialProductId?: string;
}

interface Item {
  id: string;
  name: string;
  price: number;
  photo_url: string | null;
  stock_count: number;
  quantity?: number;
  isBundle?: boolean;
  bundleVariations?: BundleVariation[];
}

export interface GroupedProduct {
  id: string;
  groupName: string;
  photo_url: string | null;
  description: string | null;
  base_price_in_cents: number;
  variants: Product[];
  totalAvailable: number;
  isBundle?: boolean;
  isProductGroup?: boolean;
  variation_fields?: string[];
}

interface ExpandableCardProps {
  group: GroupedProduct;
  onAddToCart?: (item: CartItem, quantity: number) => void;
  onExpand?: (isExpanding: boolean) => void;
  isLoading?: boolean;
  productGroupStates?: {
    [groupId: string]: {
      photos: Photo[];
      selectedProductId: string | null;
      isLoading: boolean;
      products: Product[];
    }
  };
  onProductSelect?: (groupId: string, productId: string | null) => void;
  isProductGroup?: boolean;
  bundleSelections?: { [key: string]: BundleSelection };
  productGroups?: { [groupId: string]: ProductGroupResponse };
  products?: { [productId: string]: ProductDetails };
  isBundle?: boolean;
  isBundleComplete?: (bundleId: string) => boolean;
  bundleDetails?: { [key: string]: any };
  isExpanded?: boolean;
  isFromDirectLink?: boolean;
}

interface BundleVariation {
  bundleItemId: string;
  productId: string;
}

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
);

const ImageWithFade = ({ src, alt, className }: { src: string | null, alt: string, className?: string }) => (
  <motion.img
    key={src}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.2 }}
    src={src || ''}
    alt={alt}
    className={className}
  />
);

// Add a utility function to safely render HTML content
const createMarkup = (html: string | null) => {
  if (!html) return { __html: '' };
  return { __html: html };
};

export const ExpandableCard: React.FC<ExpandableCardProps> = ({ 
  group, 
  onAddToCart,
  onExpand,
  isLoading,
  productGroupStates,
  onProductSelect,
  isProductGroup,
  bundleSelections,
  productGroups,
  products,
  isBundle,
  isBundleComplete,
  bundleDetails,
  isExpanded,
  isFromDirectLink
}) => {

  const [internalActive, setInternalActive] = useState(false);
  const active = isExpanded !== undefined ? isExpanded : internalActive;
  const [selectedVariant, setSelectedVariant] = useState<Product | null>(null);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [showSuccess, setShowSuccess] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const { setIsMobileMenuOpen } = useMobileMenu();
  
  const id = useId();
  const ref = useRef<HTMLDivElement>(null);
  
  // Get the current product group state if it exists
  const productGroupState = isProductGroup ? productGroupStates?.[group.id] : null;
  
  // Get the selected product from the product group state
  const selectedProduct = productGroupState?.selectedProductId
    ? productGroupState.products.find(p => p.id === productGroupState.selectedProductId)
    : null;

  // Update internal state when external state changes
  useEffect(() => {
     if (isExpanded !== undefined) {
      setInternalActive(isExpanded);
    }
  }, [isExpanded]);

  // Update selectedVariant when selectedProduct changes or when group.variants changes
  useEffect(() => {
    if (isProductGroup && selectedProduct) {
      setSelectedVariant(selectedProduct);
    } else if (!isProductGroup && group.variants.length > 0) {
      setSelectedVariant(group.variants[0]);
    }
  }, [isProductGroup, selectedProduct, group.variants]);

  // Update current slide when selected product changes
  useEffect(() => {
    if (isProductGroup && productGroupStates && productGroupStates[group.id]?.photos) {
      const photos = productGroupStates[group.id].photos;
      const selectedPhotoUrl = selectedProduct?.attributes.photo_url;
      
      if (selectedPhotoUrl) {
        const getPhotoId = (url: string) => {
          const match = url.match(/([a-f0-9-]+)\/(?:large|original)_/);
          return match ? match[1] : url;
        };

        const selectedPhotoId = getPhotoId(selectedPhotoUrl);
        const matchingIndex = photos.findIndex(photo => {
          const photoId = getPhotoId(photo.attributes.large_url);
          return selectedPhotoId === photoId;
        });

        if (matchingIndex !== -1) {
          setCurrentSlide(matchingIndex);
        }
      }
    }
  }, [isProductGroup, productGroupStates, group.id, selectedProduct]);

  // Apply outside click for all cards, handling both internal and external control
  useOutsideClick(ref, () => {
    if (isExpanded === undefined) {
      // Component is internally controlled
      setInternalActive(false);
      if (onExpand) onExpand(false);
    } else {
      // Component is externally controlled (including direct links)
      // Call onExpand with false to close the card
      if (onExpand) onExpand(false);
    }
  });

  // Toggle function now respects external control
  const toggleActive = () => {

    if (isExpanded === undefined) {
      // Component is internally controlled
      setInternalActive(!internalActive);
      onExpand && onExpand(!internalActive);
    } else {
      // Component is externally controlled
      // Always call onExpand for direct links when closing (for closing the modal)
      onExpand && onExpand(!isExpanded);
    }
  };

  // Use a ref to track if we've already applied body styles to avoid repeated effect calls
  const bodyStylesAppliedRef = useRef(false);

  // Update the useEffect that handles active state
  useEffect(() => {
    // For direct links, we'll track if we've already applied body styles
    // to prevent repeated effect calls
    if (isFromDirectLink && isExpanded) {
      if (!bodyStylesAppliedRef.current) {
       
        document.body.style.overflow = 'hidden';
        setIsMobileMenuOpen(false);
        bodyStylesAppliedRef.current = true;
      }
      
      return () => {
       
        document.body.style.overflow = '';
        bodyStylesAppliedRef.current = false;
      };
    }

    if (active) {
    
      document.body.style.overflow = 'hidden';
      
      // Only call onExpand if we're managing our own state internally
      // AND this is not a state change caused by external prop
      // AND this is not from a direct link
      if (onExpand && isExpanded === undefined && !isFromDirectLink) {
       
        onExpand(true);
      }
      setIsMobileMenuOpen(false);
    } else {
     
      document.body.style.overflow = '';
      
      // Only call onExpand if we're managing our own state internally
      // AND this is not a state change caused by external prop
      // AND this is not from a direct link
      if (onExpand && isExpanded === undefined && !isFromDirectLink) {
        onExpand(false);
      }
    }

    return () => {
     
      document.body.style.overflow = '';
    };
  }, [active, isExpanded, isFromDirectLink, group.id]); // Add group.id to the dependencies

  const renderPrice = (price: number = 0) => {
    return `$${(price / 100).toFixed(2)}`;
  };

  const renderBundleItems = () => {
    if (!isBundle || !bundleSelections) return null;

    return (
      <div className="mt-6 space-y-4 border-t border-white/10 pt-4">
        <h4 className="text-lg font-medium text-white">Bundle Items</h4>
        {Object.values(bundleSelections).map((selection) => (
          <div key={selection.bundleItemId} className="space-y-2">
            <div className="flex justify-between items-center">
              <span className="text-white/80">{selection.name}</span>
              {!selection.productGroupId && (
                <span className="text-xs bg-white/10 text-white/60 px-2 py-1 rounded-full">
                  Fixed Item
                </span>
              )}
            </div>

            {/* Selectable Product (with productGroupId) */}
            {selection.productGroupId && !selection.selectedProductId ? (
              <div>
                <select
                  value={selection.selectedProductId || ''}
                  onChange={(e) => {
                    onProductSelect?.(selection.bundleItemId, e.target.value || null);
                  }}
                  className="w-full bg-neutral-700 border border-neutral-600 rounded-lg 
                           px-4 py-2 text-white text-sm"
                >
                  <option value="">Select {selection.name}</option>
                  {productGroups?.[selection.productGroupId]?.included
                    ?.filter(product => {
                      if (product.attributes.archived) return false;
                      const availableInventory = product.attributes.inventory?.available || 0;
                      if (availableInventory === 0) return false;
                      const selectedCount = Object.values(bundleSelections || {}).reduce((count, otherSelection) => 
                        otherSelection.selectedProductId === product.id ? count + 1 : count
                      , 0);
                      return selectedCount < availableInventory;
                    })
                    ?.map((product) => {
                      const selectedCount = Object.values(bundleSelections || {}).reduce((count, otherSelection) => 
                        otherSelection.selectedProductId === product.id ? count + 1 : count
                      , 0);
                      const remainingAvailable = (product.attributes.inventory?.available || 0) - selectedCount;

                      return (
                        <option 
                          key={product.id} 
                          value={product.id}
                          disabled={remainingAvailable === 0}
                        >
                          {product.attributes.name} 
                          {remainingAvailable > 0
                            ? ` (${remainingAvailable} available)`
                            : ' (Out of stock)'}
                        </option>
                      );
                    })}
                </select>
              </div>
            ) : null}

            {/* Display Selected or Static Product */}
            {((selection.selectedProductId && products?.[selection.selectedProductId]) || 
              (!selection.productGroupId && selection.productId && products?.[selection.productId])) && (
              <div className="bg-white/5 rounded-lg p-4">
                <div className="flex items-center justify-between gap-4">
                  <div className="flex items-center gap-4">
                    {(() => {
                      const product = products[selection.selectedProductId || selection.productId!].data;
                      return (
                        <>
                          {product.attributes.photo_url && (
                            <img 
                              src={product.attributes.photo_url}
                              alt={product.attributes.name}
                              className="w-16 h-16 object-cover rounded-lg"
                            />
                          )}
                          <div>
                            <div className="text-white font-medium">
                              {product.attributes.name}
                            </div>
                            <div className="flex items-center gap-2 mt-1">
                              {selection.discount_percentage > 0 ? (
                                <>
                                  <span className="text-sm text-white/60 line-through">
                                    ${(product.attributes.base_price_in_cents / 100).toFixed(2)}
                                  </span>
                                  <span className="text-sm text-white">
                                    ${((product.attributes.base_price_in_cents * 
                                      (1 - (selection.discount_percentage || 0) / 100)) / 100).toFixed(2)}
                                  </span>
                                  <span className="text-xs text-green-400">
                                    {selection.discount_percentage}% off
                                  </span>
                                </>
                              ) : (
                                <span className="text-sm text-white">
                                  ${(product.attributes.base_price_in_cents / 100).toFixed(2)}
                                </span>
                              )}
                            </div>
                            <div className="text-sm text-white/60 mt-1">
                              Quantity: {selection.quantity}
                            </div>
                          </div>
                        </>
                      );
                    })()}
                  </div>
                  {/* Only show remove button for selectable products that have been selected */}
                  {(() => {
                    const shouldShowRemove = selection.productGroupId && 
                      selection.selectedProductId && 
                      !selection.productId &&
                      selection.selectedProductId !== selection.initialProductId;
                    
                    return shouldShowRemove ? (
                      <button
                        onClick={() => onProductSelect?.(selection.bundleItemId, null)}
                        className="p-2 hover:bg-white/10 rounded-lg transition-colors text-white/60 hover:text-white"
                      >
                        <X className="w-4 h-4" />
                      </button>
                    ) : null;
                  })()}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderBundlePricing = () => {
    if (!isBundle || !bundleSelections || !products) return null;

    const { originalPrice, discountedPrice, totalSavings } = calculateBundlePrice(bundleSelections, products);

    return (
      <div className="mt-4 border-t border-white/10 pt-4">
        <div className="flex flex-col gap-1">
          {totalSavings > 0 ? (
            <>
              <div className="flex justify-between items-center">
                <span className="text-white/60">Original Price:</span>
                <span className="text-white/60 line-through">${originalPrice.toFixed(2)}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-white font-medium">Bundle Price:</span>
                <span className="text-white font-medium">${discountedPrice.toFixed(2)}</span>
              </div>
              <div className="flex justify-between items-center text-green-400 text-sm">
                <span>Total Savings:</span>
                <span>${totalSavings.toFixed(2)}</span>
              </div>
            </>
          ) : (
            <div className="flex justify-between items-center">
              <span className="text-white font-medium">Bundle Price:</span>
              <span className="text-white font-medium">${discountedPrice.toFixed(2)}</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderProductGroupContent = () => {
    if (!isProductGroup || !productGroupStates) return null;

    const state = productGroupStates[group.id];
    if (!state) return null;

    return (
      <div className="mt-6 space-y-4">
        {state.products.length > 1 && (
          <div className="mt-4">
            <label className="block text-sm font-medium text-neutral-300 mb-2">
              Select Product
            </label>
            <select
              value={state.selectedProductId || ''}
              onChange={(e) => {
                const newProductId = e.target.value;
                const selectedProduct = state.products.find(p => p.id === newProductId);
                if (selectedProduct) {
                  setSelectedVariant(selectedProduct);
                  onProductSelect?.(group.id, newProductId);
                } else {
                  onProductSelect?.(group.id, null);
                }
              }}
              className="w-full bg-neutral-700 border border-neutral-600 rounded-lg px-4 py-2 text-white"
            >
              {state.products.map(product => (
                <option 
                  key={product.id} 
                  value={product.id}
                  disabled={!(product.attributes.inventory?.available || 0)}
                >
                  {product.attributes.name} - ${(product.attributes.base_price_in_cents / 100).toFixed(2)}
                  {product.attributes.inventory?.available 
                    ? ` (${product.attributes.inventory.available} available)`
                    : ' (Out of stock)'}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
    );
  };

  // Get the current image URL based on selected variant or group default
  const currentImageUrl = !active 
    ? group.photo_url 
    : (selectedProduct?.attributes.photo_url || group.photo_url);

  // Get the current availability based on whether it's a bundle or not
  const currentAvailability = isBundle 
    ? bundleDetails?.[group.id]?.data?.attributes?.inventory?.stock_count || group.totalAvailable || 0
    : selectedProduct?.attributes.inventory?.available || 0;

  const handleAddToCart = (product: Product) => {
    const cartItem: CartItem = {
      id: product.id,
      name: product.attributes.name || '',
      price: (product.attributes.base_price_in_cents || 0) / 100,
      photo_url: product.attributes.photo_url,
      stock_count: product.attributes.inventory?.available || 0,
      quantity: selectedQuantity,
      isBundle: false,
      _productGroupId: group.id
    };
    onAddToCart?.(cartItem, selectedQuantity);
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 2000);
    toast.success(`${product.attributes.name} has been added to your cart.`);
  };

  const handleAddBundleToCart = () => {
    if (!products || !bundleSelections) return;


    // Get the bundle's inventory from bundleDetails
    const bundleInventory = bundleDetails?.[group.id]?.data?.attributes?.inventory;
    
    // Use the same price calculation as the UI
    const { discountedPrice } = calculateBundlePrice(bundleSelections, products);

    // Create bundle variations only from user-selected products (exclude fixed items)
    const variations = Object.values(bundleSelections)
      .filter(selection => {
        // Only include items that:
        // 1. Have a productGroupId (meaning they're selectable)
        // 2. Have a selectedProductId that's different from initialProductId (meaning user made a selection)
        // 3. Don't have a fixed productId
        return selection.productGroupId && 
               selection.selectedProductId && 
               !selection.productId &&
               selection.selectedProductId !== selection.initialProductId;
      })
      .map(selection => {
        const productId = selection.selectedProductId!;
        const product = products[productId]?.data;
        const variation = {
          bundleItemId: selection.bundleItemId,
          productId: productId,
          displayName: product?.attributes.name || '',
          displayPhotoUrl: product?.attributes.photo_url || null
        };
        return variation;
      });


    const cartItem: CartItem = {
      id: group.id,
      name: group.groupName,
      price: discountedPrice,
      photo_url: group.photo_url,
      stock_count: bundleInventory?.stock_count || 0,
      quantity: selectedQuantity,
      isBundle: true,
      bundleVariations: variations,
      _productGroupId: group.id
    };



    onAddToCart?.(cartItem, selectedQuantity);
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 2000);
    toast.success(`${group.groupName} bundle has been added to your cart.`);
  };

  // For direct links, render only the expanded modal without the card structure
  if (isFromDirectLink && isExpanded) {
    return (
      <div className="fixed inset-0 bg-black/60 backdrop-blur-md z-[10000] md:grid md:place-items-center md:overflow-hidden overflow-y-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ type: "tween", duration: 0.2 }}
          ref={ref}
          className="w-full max-w-[900px] flex flex-col md:flex-row 
                   bg-black/40 border border-white/20 rounded-none md:rounded-3xl overflow-hidden
                   shadow-[0_8px_32px_0_rgba(0,0,0,0.37)] min-h-[100dvh] md:h-auto md:min-h-[600px] md:max-h-[calc(100dvh-200px)] 
                   m-0 md:mx-4 md:my-auto"
        >
          <div className="w-full md:w-1/2 relative">
            <div className="w-full aspect-square relative md:h-full md:w-full md:absolute md:inset-0 overflow-hidden">
              {isProductGroup && productGroupStates && productGroupStates[group.id]?.photos.length > 0 ? (
                <Carousel 
                  images={productGroupStates[group.id].photos.map(photo => photo.attributes.xlarge_url)}
                  initialSlide={currentSlide}
                  currentSlide={currentSlide}
                  onSlideChange={setCurrentSlide}
                />
              ) : (
                <ImageWithFade
                  src={currentImageUrl}
                  alt={group.groupName}
                  className="w-full h-full object-cover"
                />
              )}
            </div>
          </div>

          <div className="flex-1 md:w-1/2 flex flex-col md:overflow-y-auto">
            <div className="px-4 md:px-8 py-6 md:py-8 flex-1 md:overflow-y-auto">
              <div className="flex items-center justify-between mb-6">
                <h3 className="text-2xl font-semibold text-white">
                  {group.groupName}
                </h3>
                <button
                  onClick={() => toggleActive()}
                  className="flex items-center justify-center bg-black/40 hover:bg-black/60 
                           rounded-full h-8 w-8 transition-colors duration-200"
                >
                  <X className="w-5 h-5 text-white" />
                </button>
              </div>
              {group.description && (
                <div
                  className="text-neutral-400 mt-4 space-y-4"
                  dangerouslySetInnerHTML={createMarkup(group.description)}
                />
              )}
              
              {isProductGroup ? renderProductGroupContent() : renderBundleItems()}
              {renderBundlePricing()}
              
              {group.variants.length > 1 && (
                <div className="mt-6">
                  <label className="block text-sm font-medium text-neutral-300 mb-2">
                    Select Variant
                  </label>
                  <select
                    value={selectedVariant?.id}
                    onChange={(e) => {
                      const variant = group.variants.find(v => v.id === e.target.value);
                      setSelectedVariant(variant || null);
                    }}
                    className="w-full bg-neutral-700 border border-neutral-600 rounded-lg px-4 py-2 text-white"
                  >
                    {group.variants.map(variant => (
                      <option 
                        key={variant.id} 
                        value={variant.id}
                        disabled={!(variant.attributes.inventory?.available || 0)}
                      >
                        {variant.attributes.name} - {renderPrice(variant.attributes.base_price_in_cents)} 
                        ({variant.attributes.inventory?.available || 0} available)
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>

            <div className="mt-auto border-t border-white/10 px-4 md:px-8 py-6 md:py-8 bg-black/20">
              <div className="flex justify-between items-center text-sm">
                <div>
                  {!isBundle && (
                    <span className="font-medium text-white text-lg">
                      {renderPrice(selectedVariant?.attributes.base_price_in_cents)}
                    </span>
                  )}
                </div>
                <div className="text-neutral-400">
                  {isBundle 
                    ? `${bundleDetails?.[group.id]?.data?.attributes?.inventory?.stock_count || 0} available`
                    : `${selectedVariant?.attributes.inventory?.available || 0} available`}
                </div>
              </div>

              {selectedVariant && (selectedVariant.attributes.inventory?.available || 0) > 1 && (
                <div className="mt-4 flex items-center gap-4">
                  <label className="text-sm text-white/80">Quantity:</label>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedQuantity(prev => Math.max(1, prev - 1));
                      }}
                      className="w-8 h-8 rounded-full bg-white/10 hover:bg-white/20 
                               transition-colors flex items-center justify-center"
                    >
                      -
                    </button>
                    <input
                      type="number"
                      min="1"
                      max={selectedVariant.attributes.inventory?.available || 1}
                      value={selectedQuantity}
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        if (!isNaN(value)) {
                          setSelectedQuantity(
                            Math.min(
                              Math.max(1, value),
                              selectedVariant.attributes.inventory?.available || 1
                            )
                          );
                        }
                      }}
                      className="w-16 bg-white/10 border border-white/20 rounded-lg 
                               text-center text-white py-1"
                    />
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedQuantity(prev => 
                          Math.min(prev + 1, selectedVariant.attributes.inventory?.available || 1)
                        );
                      }}
                      className="w-8 h-8 rounded-full bg-white/10 hover:bg-white/20 
                               transition-colors flex items-center justify-center"
                    >
                      +
                    </button>
                  </div>
                </div>
              )}

              <div className="relative">
                <AnimatePresence>
                  {showSuccess && (
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className="absolute -top-12 left-0 right-0 text-center text-white-400 
                               bg-green-400/80 rounded-lg py-2"
                    >
                      Added to cart successfully!
                    </motion.div>
                  )}
                </AnimatePresence>

                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isBundle) {
                      handleAddBundleToCart();
                    } else if (selectedVariant) {
                      handleAddToCart(selectedVariant);
                    }
                  }}
                  disabled={isBundle 
                    ? !isBundleComplete?.(group.id) || currentAvailability === 0 
                    : !selectedVariant || !(selectedVariant.attributes.inventory?.available || 0)}
                  className="w-full mt-4 bg-white text-black py-3 rounded-lg font-medium 
                           hover:bg-neutral-100 transition-colors disabled:bg-neutral-400 
                           disabled:cursor-not-allowed"
                >
                  {isBundle ? (
                    !isBundleComplete?.(group.id)
                      ? 'Please select all required items'
                      : currentAvailability === 0
                        ? 'Out of Stock'
                        : 'Add Bundle to Cart'
                  ) : !selectedVariant || !(selectedVariant.attributes.inventory?.available || 0)
                    ? 'Out of Stock' 
                    : 'Add to Cart'}
                </button>
              </div>
            </div>
          </div>
          {isLoading && (
            <div className="absolute inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-white"></div>
            </div>
          )}
        </motion.div>
      </div>
    );
  }

  // Normal rendering for standard cards (non-direct links)
  return (
    <>
      <AnimatePresence>
        {active && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/60 backdrop-blur-md h-full w-full z-[9999]"
          />
        )}
      </AnimatePresence>

      <motion.div
        onClick={() => !active && toggleActive()}
        className="bg-white/10 rounded-lg overflow-hidden
                 hover:bg-white/20 transition-colors duration-200 shadow-[0_8px_32px_0_rgba(0,0,0,0.37)]
                 group cursor-pointer h-full flex flex-col"
      >
        <div className="aspect-square w-full relative overflow-hidden">
          <AnimatePresence mode="wait">
            {active && isProductGroup && productGroupStates && productGroupStates[group.id]?.photos.length > 0 ? (
              <Carousel 
                images={productGroupStates[group.id].photos.map(photo => photo.attributes.xlarge_url)}
                initialSlide={currentSlide}
                currentSlide={currentSlide}
                onSlideChange={setCurrentSlide}
              />
            ) : (
              <ImageWithFade
                src={currentImageUrl}
                alt={group.groupName}
                className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-200"
              />
            )}
          </AnimatePresence>
        </div>
        <div className="p-4 flex flex-col flex-grow">
          <h3 className="font-semibold text-lg leading-tight mb-2 text-neutral-800 line-clamp-2">
            {group.groupName}
          </h3>
          <div className="mt-auto">
            {!isBundle ? (
              <div className="text-sm text-neutral-700">
                {(isProductGroup && group.variation_fields && group.variation_fields.length > 0) ? (
                  <>Click to view options</>
                ) : (
                  renderPrice(selectedProduct?.attributes.base_price_in_cents || group.base_price_in_cents)
                )}
              </div>
            ) : (
              <div className="text-sm text-neutral-700">
                Click to view price
              </div>
            )}
          </div>
        </div>
      </motion.div>

      <AnimatePresence>
        {active && (
          <div className="fixed inset-0 md:grid md:place-items-center z-[10000] md:overflow-hidden overflow-y-auto">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ type: "tween", duration: 0.2 }}
              ref={ref}
              className="w-full max-w-[900px] flex flex-col md:flex-row 
                       bg-black/40 border border-white/20 rounded-none md:rounded-3xl overflow-hidden
                       shadow-[0_8px_32px_0_rgba(0,0,0,0.37)] min-h-[100dvh] md:h-auto md:min-h-[600px] md:max-h-[calc(100dvh-200px)] 
                       m-0 md:mx-4 md:my-auto"
            >
              <div className="w-full md:w-1/2 relative">
                <div className="w-full aspect-square relative md:h-full md:w-full md:absolute md:inset-0 overflow-hidden">
                  {isProductGroup && productGroupStates && productGroupStates[group.id]?.photos.length > 0 ? (
                    <Carousel 
                      images={productGroupStates[group.id].photos.map(photo => photo.attributes.xlarge_url)}
                      initialSlide={currentSlide}
                      currentSlide={currentSlide}
                      onSlideChange={setCurrentSlide}
                    />
                  ) : (
                    <AnimatePresence mode="wait">
                      <ImageWithFade
                        src={currentImageUrl}
                        alt={group.groupName}
                        className="w-full h-full object-cover"
                      />
                    </AnimatePresence>
                  )}
                </div>
              </div>

              <div className="flex-1 md:w-1/2 flex flex-col md:overflow-y-auto">
                <div className="px-4 md:px-8 py-6 md:py-8 flex-1 md:overflow-y-auto">
                  <div className="flex items-center justify-between mb-6">
                    <motion.h3
                      layoutId={`title-${group.id}-${id}`}
                      className="text-2xl font-semibold text-white"
                    >
                      {group.groupName}
                    </motion.h3>
                    <button
                      onClick={() => toggleActive()}
                      className="flex items-center justify-center bg-black/40 hover:bg-black/60 
                               rounded-full h-8 w-8 transition-colors duration-200"
                    >
                      <X className="w-5 h-5 text-white" />
                    </button>
                  </div>
                  {group.description && (
                    <motion.div
                      layoutId={`description-${group.id}-${id}`}
                      className="text-neutral-400 mt-4 space-y-4"
                      dangerouslySetInnerHTML={createMarkup(group.description)}
                    />
                  )}
                  
                  {isProductGroup ? renderProductGroupContent() : renderBundleItems()}
                  {renderBundlePricing()}
                  
                  {group.variants.length > 1 && (
                    <div className="mt-6">
                      <label className="block text-sm font-medium text-neutral-300 mb-2">
                        Select Variant
                      </label>
                      <select
                        value={selectedVariant?.id}
                        onChange={(e) => {
                          const variant = group.variants.find(v => v.id === e.target.value);
                          setSelectedVariant(variant || null);
                        }}
                        className="w-full bg-neutral-700 border border-neutral-600 rounded-lg px-4 py-2 text-white"
                      >
                        {group.variants.map(variant => (
                          <option 
                            key={variant.id} 
                            value={variant.id}
                            disabled={!(variant.attributes.inventory?.available || 0)}
                          >
                            {variant.attributes.name} - {renderPrice(variant.attributes.base_price_in_cents)} 
                            ({variant.attributes.inventory?.available || 0} available)
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>

                <div className="mt-auto border-t border-white/10 px-4 md:px-8 py-6 md:py-8 bg-black/20">
                  <div className="flex justify-between items-center text-sm">
                    <div>
                      {!isBundle && (
                        <span className="font-medium text-white text-lg">
                          {renderPrice(selectedVariant?.attributes.base_price_in_cents)}
                        </span>
                      )}
                    </div>
                    <div className="text-neutral-400">
                      {isBundle 
                        ? `${bundleDetails?.[group.id]?.data?.attributes?.inventory?.stock_count || 0} available`
                        : `${selectedVariant?.attributes.inventory?.available || 0} available`}
                    </div>
                  </div>

                  {selectedVariant && (selectedVariant.attributes.inventory?.available || 0) > 1 && (
                    <div className="mt-4 flex items-center gap-4">
                      <label className="text-sm text-white/80">Quantity:</label>
                      <div className="flex items-center gap-2">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedQuantity(prev => Math.max(1, prev - 1));
                          }}
                          className="w-8 h-8 rounded-full bg-white/10 hover:bg-white/20 
                                   transition-colors flex items-center justify-center"
                        >
                          -
                        </button>
                        <input
                          type="number"
                          min="1"
                          max={selectedVariant.attributes.inventory?.available || 1}
                          value={selectedQuantity}
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            if (!isNaN(value)) {
                              setSelectedQuantity(
                                Math.min(
                                  Math.max(1, value),
                                  selectedVariant.attributes.inventory?.available || 1
                                )
                              );
                            }
                          }}
                          className="w-16 bg-white/10 border border-white/20 rounded-lg 
                                   text-center text-white py-1"
                        />
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedQuantity(prev => 
                              Math.min(prev + 1, selectedVariant.attributes.inventory?.available || 1)
                            );
                          }}
                          className="w-8 h-8 rounded-full bg-white/10 hover:bg-white/20 
                                   transition-colors flex items-center justify-center"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  )}

                  <div className="relative">
                    <AnimatePresence>
                      {showSuccess && (
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          className="absolute -top-12 left-0 right-0 text-center text-white-400 
                                   bg-green-400/80 rounded-lg py-2"
                        >
                          Added to cart successfully!
                        </motion.div>
                      )}
                    </AnimatePresence>

                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (isBundle) {
                          handleAddBundleToCart();
                        } else if (selectedVariant) {
                          handleAddToCart(selectedVariant);
                        }
                      }}
                      disabled={isBundle 
                        ? !isBundleComplete?.(group.id) || currentAvailability === 0 
                        : !selectedVariant || !(selectedVariant.attributes.inventory?.available || 0)}
                      className="w-full mt-4 bg-white text-black py-3 rounded-lg font-medium 
                               hover:bg-neutral-100 transition-colors disabled:bg-neutral-400 
                               disabled:cursor-not-allowed"
                    >
                      {isBundle ? (
                        !isBundleComplete?.(group.id)
                          ? 'Please select all required items'
                          : currentAvailability === 0
                            ? 'Out of Stock'
                            : 'Add Bundle to Cart'
                      ) : !selectedVariant || !(selectedVariant.attributes.inventory?.available || 0)
                        ? 'Out of Stock' 
                        : 'Add to Cart'}
                    </button>
                  </div>
                </div>
              </div>
              {isLoading && (
                <div className="absolute inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center">
                  <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-white"></div>
                </div>
              )}
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  );
}; 