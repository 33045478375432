import React from 'react';

interface ResizeHandleProps {
  onMouseDown: (e: React.MouseEvent | React.TouchEvent) => void;
}

export const ResizeHandle: React.FC<ResizeHandleProps> = ({ onMouseDown }) => {
  return (
    <div
      className="absolute bottom-0 right-0 w-8 h-8 cursor-se-resize
                 md:opacity-0 md:group-hover:opacity-100 opacity-100
                 transition-opacity duration-200
                 flex items-center justify-center z-30"
      onMouseDown={onMouseDown}
      onTouchStart={onMouseDown}
      style={{ touchAction: 'none' }}
    >
      <div 
        className="w-6 h-6 rounded-full bg-white/30 hover:bg-white/50 
                   transition-colors duration-200 backdrop-blur-sm 
                   ring-1 ring-white/40"
      />
    </div>
  );
};