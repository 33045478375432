import React, { useEffect, useState, useRef } from 'react';
import { useCart } from '../context/CartContext';
import { CartItem } from '../context/CartContext';
import { fetchHireItems, fetchCategoryItems, HireItem, fetchBundles, Bundle, fetchBundleDetails, fetchProductGroup, fetchProductById, searchHireItems, ProductGroup } from '../services/booqableApi';
import { ExpandableCard, GroupedProduct } from '../components/ExpandableCard';
import { motion } from 'framer-motion';
import { useBundleHandling } from '../hooks/useBundleHandling';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useProductGroupHandling } from '../hooks/useProductGroupHandling';
import SEOMetadata from '../components/SEOMetadata';

const ITEMS_PER_PAGE = 30;

// Add a mapping of URL slugs to display names
const categoryDisplayNames: { [key: string]: string } = {
  'backdrops': 'Backdrops',
  'solid_walls': 'Solid Walls',
  'metal_backdrops': 'Metal Backdrops',
  'ceremony_arches': 'Ceremony Arches',
  'plinths': 'Plinths',
  'cake_plinths': 'Cake Plinths',
  'ghost_plinths': 'Ghost Plinths',
  'acrylic_plinths': 'Acrylic Plinths',
  'wooden_plinths': 'Wooden Plinths',
  'coloured_plinths': 'Coloured Plinths',
  'tables': 'Tables',
  'dining_tables': 'Dining Tables',
  'side_tables': 'Side Tables',
  'coffee_tables': 'Coffee Tables',
  'cake_tables': 'Cake Tables',
  'bars_djtables': 'Bars & DJ Tables',
  'tableware': 'Tableware',
  'tableclothes': 'Tableclothes',
  'decor': 'Decor',
  'candle_holders': 'Candle Holders',
  'trays': 'Trays',
  'wishing_wells': 'Wishing Wells',
  'servingware': 'Servingware',
  'ice_buckets': 'Ice Buckets',
  'bows': 'Bows',
  'cake_stands': 'Cake Stands',
  'pots_vases': 'Pots & Vases',
  'cushions_rugs': 'Cushions & Rugs',
  'umbrellas': 'Umbrellas',
  'table_numbers': 'Table Numbers',
  'signage_stands': 'Signage Stands',
  'easels': 'Easels',
  'boxes': 'Boxes',
  'hanging_stands': 'Hanging Stands',
  'stages': 'Stages',
  'wedding': 'Wedding',
  'seating': 'Seating',
  'carts_trolleys': 'Carts & Trolleys',
  'ghost_range': 'Ghost Range',
  'kids_themes': 'Kids Themes',
  'animals': 'Animals',
  'neon_signs': 'Neon Signs'
};

// Function to generate product schema JSON-LD for a product group or bundle
const generateProductSchema = (item: ProductGroup | Bundle, baseUrl: string): string => {
  // Determine if this is a bundle or product group
  const isBundle = item.type === 'bundles';
  
  // Format price from cents to dollars with 2 decimal places
  const price = (item.attributes.base_price_in_cents / 100).toFixed(2);
  
  // Create the basic product schema
  const schema: any = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": item.attributes.name,
    "description": item.attributes.description || `${item.attributes.name} available for hire from The Treasure Room.`,
    "sku": `${item.type}_${item.id}`,
    "brand": {
      "@type": "Brand",
      "name": "The Treasure Room"
    },
    "offers": {
      "@type": "Offer",
      "url": `${baseUrl}/search?q=${encodeURIComponent(item.attributes.name)}`,
      "priceCurrency": "AUD",
      "price": price,
      "priceValidUntil": new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split('T')[0],
      "businessFunction": "LeaseOut"
    }
  };
  
  // Add image if available
  if (item.attributes.photo_url) {
    schema.image = item.attributes.photo_url;
  }
  
  // Add additional bundle-specific data if applicable
  if (isBundle && item.relationships?.bundle_items?.data?.length > 0) {
    schema.isRelatedTo = item.relationships.bundle_items.data.map(bundleItem => ({
      "@type": "Product",
      "name": `Part of ${item.attributes.name}`,
      "sku": `bundle_item_${bundleItem.id}`
    }));
  }
  
  return JSON.stringify(schema);
};

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const item = {
  hidden: { opacity: 0, y: 20 },
  show: { 
    opacity: 1, 
    y: 0,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 20
    }
  }
};

const mapBundleToGroupedProduct = (bundle: Bundle): GroupedProduct => {



  const groupedProduct: GroupedProduct = {
    id: bundle.id,
    groupName: bundle.attributes.name,
    photo_url: bundle.attributes.photo_url,
    description: bundle.attributes.description,
    base_price_in_cents: bundle.attributes.base_price_in_cents || 0,
    variants: [{
      id: bundle.id,
      type: 'products',
      attributes: {
        name: bundle.attributes.name,
        description: bundle.attributes.description,
        base_price_in_cents: bundle.attributes.base_price_in_cents,
        photo_url: bundle.attributes.photo_url,
        inventory: bundle.attributes.inventory,
        archived: false,
        show_in_store: true
      }
    }],
    totalAvailable: bundle.attributes.inventory?.stock_count || 0
  };

  return groupedProduct;
};

const groupItems = (items: Array<ProductGroup | Bundle>) => {
  const groups = new Map<string, GroupedProduct>();
  
  items.forEach(item => {
    if (item.type === 'bundles') {
      // Handle bundles
      groups.set(item.id, {
        id: item.id,
        groupName: item.attributes.name,
        photo_url: item.attributes.photo_url,
        description: item.attributes.description,
        base_price_in_cents: item.attributes.base_price_in_cents || 0,
        variants: [{
          id: item.id,
          type: 'products',
          attributes: {
            name: item.attributes.name,
            description: item.attributes.description,
            photo_url: item.attributes.photo_url,
            base_price_in_cents: item.attributes.base_price_in_cents,
            archived: item.attributes.archived,
            show_in_store: item.attributes.show_in_store,
            inventory: item.attributes.inventory
          }
        }],
        totalAvailable: item.attributes.inventory?.stock_count || 0,
        isBundle: true
      });
    } else if (item.type === 'product_groups') {
      // Handle product groups
      groups.set(item.id, {
        id: item.id,
        groupName: item.attributes.name,
        photo_url: item.attributes.photo_url,
        description: item.attributes.description,
        base_price_in_cents: item.attributes.base_price_in_cents || 0,
        variants: [], // Will be populated when expanded
        totalAvailable: item.attributes.inventory?.stock_count || 0,
        isProductGroup: true,
        variation_fields: item.attributes.variation_fields || []
      });
    }
  });

  return Array.from(groups.values());
};

// Add this outside of any component, at module level
const initializedDirectLinks = new Set<string>();

export const Hire = () => {
  const { tag, page } = useParams<{ tag?: string, page?: string }>();
  // Parse the page parameter or default to 1
  const pageFromUrl = page ? parseInt(page, 10) : 1;
  
  const [items, setItems] = useState<Array<ProductGroup | Bundle>>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [viewMode, setViewMode] = useState<'products' | 'bundles'>('products');
  const bundleHandling = useBundleHandling();
  const [bundles, setBundles] = useState<Bundle[]>([]);
  const [loadingBundles, setLoadingBundles] = useState(false);
  const [bundlesCurrentPage, setBundlesCurrentPage] = useState(1);
  const [bundlesHasMore, setBundlesHasMore] = useState(true);
  const [bundlesTotalItems, setBundlesTotalItems] = useState(0);
  const [isLoadingMoreBundles, setIsLoadingMoreBundles] = useState(false);
  
  // Add states for shareable links
  const [expandedItemFromURL, setExpandedItemFromURL] = useState<{
    type: 'product-group' | 'bundle' | null;
    id: string | null;
  }>({ type: null, id: null });
  const [directlyFetchedItem, setDirectlyFetchedItem] = useState<{
    type: 'product-group' | 'bundle';
    data: any;
    id: string;
  } | null>(null);
  const [isLoadingURLItem, setIsLoadingURLItem] = useState(false);
  const [directFetchError, setDirectFetchError] = useState<string | null>(null);
  
  // Create a reference to track if we've attempted to fetch an item
  const attemptedItemRef = useRef<{type: string, id: string} | null>(null);
  
  // Add a ref to track whether URL parameters were set by user clicking on a card
  const userExpandedItemRef = useRef<{type: string, id: string} | null>(null);
  
  const { addItem } = useCart();
  const productGroupHandling = useProductGroupHandling();
  const location = useLocation();
  const navigate = useNavigate();

  // Helper function to check if this is likely a bot request
  const isBotRequest = () => {
    // Return true if page parameter is present in URL (likely a crawler)
    return !!page;
  };

  // Function to update URL with item info when expanded
  const handleItemExpand = (type: 'product-group' | 'bundle', id: string, isExpanding: boolean) => {
    if (isExpanding) {
      // Track that this expansion was initiated by user clicking a card
      userExpandedItemRef.current = { type, id };
      
      // Update URL with item info
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('type', type);
      searchParams.set('id', id);
      navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
      
      // Set expanded item state
      setExpandedItemFromURL({ type, id });
      
      // Trigger the appropriate expand handler
      if (type === 'bundle') {
        bundleHandling.handleBundleExpand(id, true);
      } else {
        productGroupHandling.handleProductGroupExpand(id, true);
      }
    } else {
      // Clear the user expanded item ref when closing
      userExpandedItemRef.current = null;
      
      // Remove item info from URL
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete('type');
      searchParams.delete('id');
      navigate(`${location.pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`, { replace: true });
      
      // Clear expanded item state
      setExpandedItemFromURL({ type: null, id: null });
    }
  };

  // Check for item ID in URL parameters on load
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const type = params.get('type');
    const id = params.get('id');
    
    // IMPORTANT: Don't proceed if we are already loading or have already fetched this exact item
    if (isLoadingURLItem || (directlyFetchedItem && directlyFetchedItem.id === id && directlyFetchedItem.type === type)) {
      return;
    }
    
    // IMPORTANT: Don't proceed if we've already attempted to fetch this exact item
    const alreadyAttemptedFetch = 
      attemptedItemRef.current && 
      attemptedItemRef.current.type === type && 
      attemptedItemRef.current.id === id;
    
    if (alreadyAttemptedFetch) {
      return;
    }
    
    // Check if this URL update came from a user clicking on a card
    const isUserExpandedItem = 
      userExpandedItemRef.current && 
      userExpandedItemRef.current.type === type && 
      userExpandedItemRef.current.id === id;
    
    if (type && id && (type === 'product-group' || type === 'bundle')) {
      setExpandedItemFromURL({ type: type as 'product-group' | 'bundle', id });
      
      // If this was triggered by user clicking a card, don't use DirectLinkCard
      if (isUserExpandedItem) {
        setDirectlyFetchedItem(null);
        return;
      }
      
      // For direct links (not user clicks), fetch and use DirectLinkCard
      // Check if the item exists in the current loaded items (for logging purposes only)
      const itemExists = items.some(item => 
        item.id === id && 
        ((type === 'bundle' && item.type === 'bundles') ||
         (type === 'product-group' && item.type === 'product_groups'))
      );
      
      // For bundles specifically, also check the bundles array (for logging purposes only)
      const bundleExists = type === 'bundle' && bundles.some(bundle => bundle.id === id);
      
      // Only fetch if we haven't already tried fetching it
      if (!directlyFetchedItem) {
        // Set flag to indicate we're attempting to fetch this item
        attemptedItemRef.current = { type, id };
        
        setIsLoadingURLItem(true);
        setDirectFetchError(null);
        
        if (type === 'product-group') {
          fetchProductGroup(id)
            .then(response => {
              if (response && response.data) {
                // Only update if we're still looking for this item (checking URL hasn't changed)
                const currentParams = new URLSearchParams(window.location.search);
                if (currentParams.get('id') === id && currentParams.get('type') === type) {
                  setDirectlyFetchedItem({
                    type: 'product-group',
                    data: response,
                    id
                  });
                  
                  // Initialize the product group
                  productGroupHandling.handleProductGroupExpand(id, true);
                }
              } else {
                setDirectFetchError('Could not load the requested item - invalid response');
              }
            })
            .catch(error => {
              console.error('Error fetching product group:', error);
              setDirectFetchError('Could not load the requested item');
            })
            .finally(() => {
              setIsLoadingURLItem(false);
            });
        } else if (type === 'bundle') {
          fetchBundleDetails(id)
            .then(response => {
              if (response && response.data) {
                // Only update if we're still looking for this item (checking URL hasn't changed)
                const currentParams = new URLSearchParams(window.location.search);
                if (currentParams.get('id') === id && currentParams.get('type') === type) {
                  setDirectlyFetchedItem({
                    type: 'bundle',
                    data: response,
                    id
                  });
                  
                  // Initialize the bundle
                  bundleHandling.handleBundleExpand(id, true);
                }
              } else {
                setDirectFetchError('Could not load the requested item - invalid response');
              }
            })
            .catch(error => {
              console.error('Error fetching bundle:', error);
              setDirectFetchError('Could not load the requested item');
            })
            .finally(() => {
              setIsLoadingURLItem(false);
            });
        }
      }
    } else {
      // No valid item in URL, clear states
      setExpandedItemFromURL({ type: null, id: null });
      setDirectlyFetchedItem(null);
      setDirectFetchError(null);
      userExpandedItemRef.current = null;
    }
    
  }, [location.search]); // Only depend on location.search

  useEffect(() => {
    const fetchItems = async () => {
      try {
        setLoading(true);
        
        // If accessed with a page parameter (likely a crawler), fetch just that page
        if (tag && isBotRequest()) {
          const response = await fetchCategoryItems(tag, pageFromUrl, ITEMS_PER_PAGE);

          if (!response || !response.data) {
            throw new Error('Invalid response format from API');
          }

          setItems(response.data as (Bundle | ProductGroup)[]);
          setTotalItems(response.meta.total_items);
          setHasMore(pageFromUrl * ITEMS_PER_PAGE < response.meta.total_items);
          setCurrentPage(pageFromUrl);
        } 
        // Normal user behavior - load first page
        else if (tag) {
          const response = await fetchCategoryItems(tag, 1, ITEMS_PER_PAGE);

          if (!response || !response.data) {
            throw new Error('Invalid response format from API');
          }

          setItems(response.data as (Bundle | ProductGroup)[]);
          setTotalItems(response.meta.total_items);
          setHasMore(response.data.length < response.meta.total_items);
          setCurrentPage(1);
        } 
        // No tag specified - show all items
        else {
          const response = await fetchHireItems(1, ITEMS_PER_PAGE);
          
          if (!response || !response.data) {
            throw new Error('Invalid response format from API');
          }

          setItems(response.data as (Bundle | ProductGroup)[]);
          setTotalItems(response.meta.total_items);
          setHasMore(response.data.length < response.meta.total_items);
          setCurrentPage(1);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch items');
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [tag, pageFromUrl]);

  const loadMore = async () => {
    if (isLoadingMore) return;

    try {
      setIsLoadingMore(true);
      const nextPage = currentPage + 1;

      if (tag) {
        const response = await fetchCategoryItems(tag, nextPage, ITEMS_PER_PAGE);
        
        if (!response || !response.data) {
          throw new Error('Invalid response format from API');
        }

        setItems(prev => [...prev, ...(response.data as (Bundle | ProductGroup)[])]);
        setCurrentPage(nextPage);
        setHasMore((items.length + response.data.length) < response.meta.total_items);
      } else {
        const response = await fetchHireItems(nextPage, ITEMS_PER_PAGE);
        
        if (!response || !response.data) {
          throw new Error('Invalid response format from API');
        }

        setItems(prev => [...prev, ...(response.data as (Bundle | ProductGroup)[])]);
        setCurrentPage(nextPage);
        setHasMore((items.length + response.data.length) < response.meta.total_items);
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to load more items');
    } finally {
      setIsLoadingMore(false);
    }
  };

  useEffect(() => {
    const loadBundles = async () => {
      if (viewMode === 'bundles') {
        try {
          setLoadingBundles(true);
          const response = await fetchBundles(1, ITEMS_PER_PAGE);
          const bundleData = response.data || [];
          

          
          setBundles(bundleData);
          setBundlesTotalItems(response.meta.total_items);
          setBundlesHasMore(bundleData.length < response.meta.total_items);
        } catch (err) {
          setError(err instanceof Error ? err.message : 'Failed to fetch bundles');
          setBundles([]);
        } finally {
          setLoadingBundles(false);
        }
      }
    };

    loadBundles();
  }, [viewMode]);

  const loadMoreBundles = async () => {
    if (isLoadingMoreBundles) return;

    try {
      setIsLoadingMoreBundles(true);
      const nextPage = bundlesCurrentPage + 1;
      const response = await fetchBundles(nextPage, ITEMS_PER_PAGE);
      const bundleData = response.data || response.bundles || [];

      setBundles(prev => [...prev, ...bundleData]);
      setBundlesCurrentPage(nextPage);
      setBundlesHasMore(bundles.length + bundleData.length < bundlesTotalItems);
    } catch (err) {
      console.error('Error loading more bundles:', err);
    } finally {
      setIsLoadingMoreBundles(false);
    }
  };

  // Add hidden pagination links for crawlers
  const renderPaginationLinks = () => {
    if (!isBotRequest()) return null;
    
    return (
      <div aria-hidden="true" className="hidden">
        {pageFromUrl > 1 && (
          <a href={`/hire/${tag}/page/${pageFromUrl - 1}`} rel="prev">
            Previous Page
          </a>
        )}
        {hasMore && (
          <a href={`/hire/${tag}/page/${pageFromUrl + 1}`} rel="next">
            Next Page
          </a>
        )}
      </div>
    );
  };

  // Add structured data to the page
  useEffect(() => {
    // Only add structured data if items are loaded and we're not loading
    if (items.length > 0 && !loading) {
      // Base URL for the search links - use production domain in production
      let baseUrl = window.location.origin;
      
      // If in development (localhost), use the production domain
      if (baseUrl.includes('localhost') || baseUrl.includes('127.0.0.1')) {
        baseUrl = 'https://www.thetreasureroom.com.au';
      }
      
      // Remove any existing product schemas to avoid duplicates
      document.querySelectorAll('script[data-product-schema]').forEach(el => el.remove());
      
      // Add schema for each product
      items.forEach(item => {
        const schema = generateProductSchema(item, baseUrl);
        const scriptTag = document.createElement('script');
        scriptTag.setAttribute('type', 'application/ld+json');
        scriptTag.setAttribute('data-product-schema', 'true');
        scriptTag.textContent = schema;
        document.head.appendChild(scriptTag);
      });
      

    }
    
    // Cleanup function to remove schemas when component unmounts
    return () => {
      document.querySelectorAll('script[data-product-schema]').forEach(el => el.remove());
    };
  }, [items, loading]);

  // Generate SEO metadata based on current page
  const generateSEOMetadata = () => {
    // Base URL for canonical and pagination links
    let baseUrl = window.location.origin;
    if (baseUrl.includes('localhost') || baseUrl.includes('127.0.0.1')) {
      baseUrl = 'https://www.thetreasureroom.com.au';
    }
    
    // For the main page without a tag
    if (!tag) {
      return {
        title: 'All Hire Props',
        description: 'Discover premium event props, furniture, and styling items available for hire from The Treasure Room. Transform your event with our unique collection.',
        canonicalUrl: `${baseUrl}/hire`,
        ogImage: `${baseUrl}/assets/og-image.png`
      };
    }
    
    // For category pages
    const categoryName = categoryDisplayNames[tag] || tag;
    let categoryDescription = '';
    
    // Create custom descriptions based on category
    switch(tag) {
      case 'backdrops':
        categoryDescription = `Hire premium ${categoryName} for your event from The Treasure Room. Choose from solid walls, metal backdrops, and more.`;
        break;
      case 'plinths':
        categoryDescription = `Rent stylish ${categoryName} for your event displays from The Treasure Room. We offer ghost, acrylic, wooden, and coloured options.`;
        break;
      case 'tables':
        categoryDescription = `Browse our collection of ${categoryName} for hire, including dining tables, side tables, coffee tables, and cake tables.`;
        break;
      default:
        categoryDescription = `Hire premium ${categoryName} for your event from The Treasure Room. Browse our collection of quality event props and styling items.`;
    }
    
    // For paginated pages
    if (pageFromUrl > 1) {
      return {
        title: `${categoryName} Hire - Page ${pageFromUrl}`,
        description: categoryDescription,
        canonicalUrl: `${baseUrl}/hire/${tag}`, // Canonical points to page 1
        prevPage: pageFromUrl > 2 ? `${baseUrl}/hire/${tag}/page/${pageFromUrl - 1}` : `${baseUrl}/hire/${tag}`,
        nextPage: hasMore ? `${baseUrl}/hire/${tag}/page/${pageFromUrl + 1}` : undefined,
        ogImage: `${baseUrl}/assets/og-image.png`
      };
    }
    
    // For main category page (page 1)
    return {
      title: `${categoryName} Hire`,
      description: categoryDescription,
      canonicalUrl: `${baseUrl}/hire/${tag}`,
      nextPage: hasMore ? `${baseUrl}/hire/${tag}/page/2` : undefined,
      ogImage: `${baseUrl}/assets/og-image.png`
    };
  };
  
  // Add SEO metadata based on current page
  const seoMetadata = generateSEOMetadata();
  
  // Modify the DirectLinkCard component to use a stable ref and add lifecycle logs
  const renderDirectLinkCard = () => {
    if (!directlyFetchedItem) {
      return null;
    }
    
    if (!directlyFetchedItem.data || !directlyFetchedItem.data.data) {
      return null;
    }
    
    // Create a GroupedProduct from the directly fetched data
    let item: GroupedProduct;
    
    try {
      if (directlyFetchedItem.type === 'bundle') {
        const bundle = directlyFetchedItem.data.data;
        item = {
          id: bundle.id,
          groupName: bundle.attributes.name,
          photo_url: bundle.attributes.photo_url,
          description: bundle.attributes.description,
          base_price_in_cents: bundle.attributes.base_price_in_cents || 0,
          variants: [{
            id: bundle.id,
            type: 'products',
            attributes: {
              name: bundle.attributes.name,
              description: bundle.attributes.description,
              photo_url: bundle.attributes.photo_url,
              base_price_in_cents: bundle.attributes.base_price_in_cents,
              archived: false,
              show_in_store: true,
              inventory: bundle.attributes.inventory
            }
          }],
          totalAvailable: bundle.attributes.inventory?.stock_count || 0,
          isBundle: true
        };
      } else {
        // Handle product group
        const productGroup = directlyFetchedItem.data.data;
        item = {
          id: productGroup.id,
          groupName: productGroup.attributes.name,
          photo_url: productGroup.attributes.photo_url,
          description: productGroup.attributes.description,
          base_price_in_cents: productGroup.attributes.base_price_in_cents || 0,
          variants: [], // Will be populated by the handlers
          totalAvailable: productGroup.attributes.inventory?.stock_count || 0,
          isProductGroup: true,
          variation_fields: productGroup.attributes.variation_fields || []
        };
      }
    } catch (error) {
      console.error('Error creating GroupedProduct from directlyFetchedItem:', error);
      return null;
    }
    
    // Create the expandable card props
    const directCardProps = {
      key: `direct-link-${directlyFetchedItem.id}`,
      group: item,
      onAddToCart: (item: CartItem, quantity: number) => {
        addItem({
          id: item.id,
          name: item.name,
          price: item.price,
          photo_url: item.photo_url,
          stock_count: item.stock_count,
          quantity: quantity,
          isBundle: directlyFetchedItem.type === 'bundle',
          bundleVariations: item.bundleVariations
        });
      },
      onExpand: (isExpanding: boolean) => {
        if (!isExpanding) {
          // Clear URL parameters and revert to main page view
          const searchParams = new URLSearchParams(location.search);
          searchParams.delete('type');
          searchParams.delete('id');
          
          // Clear all states first before navigation to avoid any race conditions
          setDirectlyFetchedItem(null);
          setExpandedItemFromURL({ type: null, id: null });
          userExpandedItemRef.current = null;
          attemptedItemRef.current = null;
          
          // Then navigate to clear the URL parameters
          navigate(`/hire${location.search ? (searchParams.toString() ? `?${searchParams.toString()}` : '') : ''}`, { replace: true });
        }
      },
      isLoading: directlyFetchedItem.type === 'bundle'
        ? bundleHandling.loadingBundleDetails.includes(directlyFetchedItem.id)
        : productGroupHandling.loadingProductGroups.includes(directlyFetchedItem.id),
      bundleSelections: directlyFetchedItem.type === 'bundle' 
        ? bundleHandling.bundleSelections[directlyFetchedItem.id] 
        : undefined,
      productGroups: bundleHandling.productGroups,
      products: bundleHandling.productDetails,
      onProductSelect: directlyFetchedItem.type === 'bundle'
        ? (bundleItemId: string, productId: string | null) => 
            bundleHandling.handleBundleItemProductSelect(directlyFetchedItem.id, bundleItemId, productId)
        : (groupId: string, productId: string | null) => 
            productGroupHandling.handleProductSelect(groupId, productId),
      isBundle: directlyFetchedItem.type === 'bundle',
      isProductGroup: directlyFetchedItem.type === 'product-group',
      productGroupStates: productGroupHandling.productGroupStates,
      isBundleComplete: directlyFetchedItem.type === 'bundle' 
        ? bundleHandling.isBundleComplete 
        : undefined,
      bundleDetails: directlyFetchedItem.type === 'bundle' 
        ? bundleHandling.bundleDetails 
        : undefined,
      isExpanded: true,
      isFromDirectLink: true
    };
    
    // Return the ExpandableCard with the configured props
    return <ExpandableCard {...directCardProps} />;
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-[#EDDBC2] text-white pt-20">
        <SEOMetadata {...seoMetadata} />
        <div className="max-w-7xl mx-auto px-4 py-12">
          <div className="flex items-center justify-center">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-white"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-[#EDDBC2] text-white pt-20">
        <div className="max-w-7xl mx-auto px-4 py-12">
          <div className="text-center text-red-400">
            <h2 className="text-xl font-semibold">Error loading products</h2>
            <p className="mt-2">{error}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen text-white pt-20 relative overflow-hidden">
      <SEOMetadata {...seoMetadata} />
      <div className="absolute inset-0 w-full h-full bg-[#E3C9A8] -z-10" />
      
      {/* Loading indicator for URL item */}
      {isLoadingURLItem && (
        <div className="fixed inset-0 bg-black bg-opacity-70 z-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-xl text-black">
            <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-[#5E0A0A] mx-auto"></div>
            <p className="text-center mt-4">Loading item...</p>
          </div>
        </div>
      )}
      
      {/* Error message for URL item */}
      {directFetchError && (
        <div className="fixed inset-0 bg-black bg-opacity-70 z-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-xl text-black max-w-md">
            <h3 className="text-xl font-semibold text-[#5E0A0A]">Error</h3>
            <p className="mt-2">{directFetchError}</p>
            <div className="mt-4 flex justify-end">
              <button 
                onClick={() => {
                  setDirectFetchError(null);
                  // Clear URL parameters
                  const searchParams = new URLSearchParams(location.search);
                  searchParams.delete('type');
                  searchParams.delete('id');
                  navigate(`${location.pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`, { replace: true });
                }}
                className="px-4 py-2 bg-[#5E0A0A] text-white rounded hover:bg-opacity-90"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      
      {/* Direct link card modal */}
      {directlyFetchedItem && renderDirectLinkCard()}
      
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="space-y-8">
          <div className="flex flex-col gap-4">
            <div>
              <h1 className="text-4xl font-bold text-[#5E0A0A]">
                {tag ? categoryDisplayNames[tag] || tag.charAt(0).toUpperCase() + tag.slice(1) : 'Our Collection'}
              </h1>
              <p className="text-[#5E0A0A] mt-2">
                {tag ? 
                  `Browse our ${categoryDisplayNames[tag]?.toLowerCase() || tag.toLowerCase()} collection` : 
                  'Browse our extensive range of event hire items'
                }
              </p>
            </div>
            {!tag && (
              <div className="flex items-center space-x-4">
                <button
                  onClick={() => setViewMode('products')}
                  className={`px-4 py-2 rounded-lg transition-colors ${
                    viewMode === 'products'
                      ? 'bg-white text-black'
                      : 'bg-white/20 text-black font-semibold'
                  }`}
                >
                  Products
                </button>
                <button
                  onClick={() => setViewMode('bundles')}
                  className={`px-4 py-2 rounded-lg transition-colors ${
                    viewMode === 'bundles'
                      ? 'bg-white text-black'
                      : 'bg-white/20 text-black font-semibold'
                  }`}
                >
                  Bundles
                </button>
              </div>
            )}
          </div>
          
          {tag ? (
            <motion.div 
              variants={container}
              initial="hidden"
              animate="show"
              className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6 
                         [&>*]:h-full [&>*>*]:h-full"
            >
              {groupItems(items).map((group) => (
                <motion.div key={group.id} variants={item}>
                  <ExpandableCard
                    group={group}
                    onAddToCart={(item, quantity) => {
                      addItem({
                        id: item.id,
                        name: item.name,
                        price: item.price,
                        photo_url: item.photo_url,
                        stock_count: item.stock_count,
                        quantity: quantity,
                        isBundle: group.isBundle,
                        bundleVariations: item.bundleVariations
                      });
                    }}
                    onExpand={(isExpanding) => {
                      const type = group.isBundle ? 'bundle' : 'product-group';
                      handleItemExpand(type, group.id, isExpanding);
                    }}
                    isLoading={
                      group.isBundle 
                        ? bundleHandling.loadingBundleDetails.includes(group.id)
                        : productGroupHandling.loadingProductGroups.includes(group.id)
                    }
                    bundleSelections={group.isBundle ? bundleHandling.bundleSelections[group.id] : undefined}
                    productGroups={bundleHandling.productGroups}
                    products={bundleHandling.productDetails}
                    onProductSelect={
                      group.isBundle
                        ? (bundleItemId, productId) => 
                            bundleHandling.handleBundleItemProductSelect(group.id, bundleItemId, productId)
                        : (groupId, productId) => 
                            productGroupHandling.handleProductSelect(groupId, productId)
                    }
                    isBundle={group.isBundle}
                    isProductGroup={group.isProductGroup}
                    productGroupStates={productGroupHandling.productGroupStates}
                    isBundleComplete={group.isBundle ? bundleHandling.isBundleComplete : undefined}
                    bundleDetails={group.isBundle ? bundleHandling.bundleDetails : undefined}
                    isExpanded={
                      expandedItemFromURL.id === group.id && 
                      ((group.isBundle && expandedItemFromURL.type === 'bundle') || 
                       (group.isProductGroup && expandedItemFromURL.type === 'product-group')) &&
                      !directlyFetchedItem
                    }
                  />
                </motion.div>
              ))}
            </motion.div>
          ) : (
            viewMode === 'products' ? (
              <motion.div 
                variants={container}
                initial="hidden"
                animate="show"
                className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6 
                           [&>*]:h-full [&>*>*]:h-full"
              >
                {groupItems(items).map((group) => (
                  <motion.div key={group.id} variants={item}>
                    <ExpandableCard
                      group={group}
                      onAddToCart={(item, quantity) => {
                        addItem({
                          id: item.id,
                          name: item.name,
                          price: item.price,
                          photo_url: item.photo_url,
                          stock_count: item.stock_count,
                          quantity: quantity,
                          isBundle: false,
                          bundleVariations: item.bundleVariations
                        });
                      }}
                      onExpand={(isExpanding) => {
                        const type = group.isBundle ? 'bundle' : 'product-group';
                        handleItemExpand(type, group.id, isExpanding);
                      }}
                      isLoading={
                        group.isBundle 
                          ? bundleHandling.loadingBundleDetails.includes(group.id)
                          : productGroupHandling.loadingProductGroups.includes(group.id)
                      }
                      bundleSelections={group.isBundle ? bundleHandling.bundleSelections[group.id] : undefined}
                      productGroups={bundleHandling.productGroups}
                      products={bundleHandling.productDetails}
                      onProductSelect={
                        group.isBundle
                          ? (bundleItemId, productId) => 
                              bundleHandling.handleBundleItemProductSelect(group.id, bundleItemId, productId)
                          : (groupId, productId) => 
                              productGroupHandling.handleProductSelect(groupId, productId)
                      }
                      isBundle={false}
                      isProductGroup={group.isProductGroup}
                      productGroupStates={productGroupHandling.productGroupStates}
                      isBundleComplete={group.isBundle ? bundleHandling.isBundleComplete : undefined}
                      isExpanded={
                        expandedItemFromURL.id === group.id && 
                        ((group.isBundle && expandedItemFromURL.type === 'bundle') || 
                         (group.isProductGroup && expandedItemFromURL.type === 'product-group')) &&
                        !directlyFetchedItem
                      }
                    />
                  </motion.div>
                ))}
              </motion.div>
            ) : (
              <motion.div 
                variants={container}
                initial="hidden"
                animate="show"
                className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6 
                           [&>*]:h-full [&>*>*]:h-full"
              >
                {loadingBundles ? (
                  <div className="col-span-full flex items-center justify-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-white"></div>
                  </div>
                ) : bundles.length > 0 ? (
                  bundles.map((bundle) => {
                    return (
                      <motion.div key={bundle.id} variants={item}>
                        <ExpandableCard
                          group={mapBundleToGroupedProduct(bundle)}
                          onAddToCart={(item, quantity) => {

                            addItem({
                              id: item.id,
                              name: item.name,
                              price: item.price,
                              photo_url: item.photo_url,
                              stock_count: item.stock_count,
                              quantity: quantity,
                              isBundle: true,
                              bundleVariations: item.bundleVariations
                            });
                          }}
                          onExpand={(isExpanding) => {
                            handleItemExpand('bundle', bundle.id, isExpanding);
                          }}
                          isLoading={bundleHandling.loadingBundleDetails.includes(bundle.id)}
                          bundleSelections={bundleHandling.bundleSelections[bundle.id]}
                          productGroups={bundleHandling.productGroups}
                          products={bundleHandling.productDetails}
                          onProductSelect={(bundleItemId, productId) => 
                            bundleHandling.handleBundleItemProductSelect(bundle.id, bundleItemId, productId)
                          }
                          isBundle={true}
                          isBundleComplete={(bundleId) => bundleHandling.isBundleComplete(bundleId)}
                          bundleDetails={bundleHandling.bundleDetails}
                          isExpanded={
                            expandedItemFromURL.id === bundle.id && 
                            expandedItemFromURL.type === 'bundle' &&
                            !directlyFetchedItem
                          }
                        />
                      </motion.div>
                    );
                  })
                ) : (
                  <div className="col-span-full text-center text-neutral-400">
                    <p>No bundles available</p>
                  </div>
                )}
              </motion.div>
            )
          )}

          {tag ? (
            // Load more for tagged pages
            hasMore && (items.length + bundles.length) < totalItems && (
              <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
                className="flex justify-center mt-8"
              >
                <button
                  onClick={loadMore}
                  disabled={isLoadingMore}
                  className="px-6 py-3 bg-white/30 backdrop-blur-md text-black rounded-lg font-medium 
                           hover:bg-white/40 transition-colors disabled:bg-white/20 
                           disabled:cursor-not-allowed flex items-center gap-2
                           border border-white/20 shadow-[0_8px_32px_0_rgba(0,0,0,0.37)]"
                >
                  {isLoadingMore ? (
                    <>
                      <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-black"></div>
                      Loading...
                    </>
                  ) : (
                    `Load More (${items.length + bundles.length} of ${totalItems})`
                  )}
                </button>
              </motion.div>
            )
          ) : viewMode === 'products' ? (
            // Load more for products tab
            hasMore && items.length < totalItems && (
              <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
                className="flex justify-center mt-8"
              >
                <button
                  onClick={loadMore}
                  disabled={isLoadingMore}
                  className="px-6 py-3 bg-white/30 backdrop-blur-md text-black rounded-lg font-medium 
                           hover:bg-white/40 transition-colors disabled:bg-white/20 
                           disabled:cursor-not-allowed flex items-center gap-2
                           border border-white/20 shadow-[0_8px_32px_0_rgba(0,0,0,0.37)]"
                >
                  {isLoadingMore ? (
                    <>
                      <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-black"></div>
                      Loading...
                    </>
                  ) : (
                    `Load More (${items.length} of ${totalItems})`
                  )}
                </button>
              </motion.div>
            )
          ) : (
            // Load more for bundles tab
            bundlesHasMore && bundles.length < bundlesTotalItems && (
              <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
                className="flex justify-center mt-8"
              >
                <button
                  onClick={loadMoreBundles}
                  disabled={isLoadingMoreBundles}
                  className="px-6 py-3 bg-white/30 backdrop-blur-md text-black rounded-lg font-medium 
                           hover:bg-white/40 transition-colors disabled:bg-white/20 
                           disabled:cursor-not-allowed flex items-center gap-2
                           border border-white/20 shadow-[0_8px_32px_0_rgba(0,0,0,0.37)]"
                >
                  {isLoadingMoreBundles ? (
                    <>
                      <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-black"></div>
                      Loading...
                    </>
                  ) : (
                    `Load More (${bundles.length} of ${bundlesTotalItems})`
                  )}
                </button>
              </motion.div>
            )
          )}
        </div>
      </div>
      
      {/* Add the pagination links at the end of your component */}
      {renderPaginationLinks()}
    </div>
  );
};