const API_BASE_URL = import.meta.env.PROD 
  ? `${import.meta.env.VITE_API_URL}` // Add /api to the base URL
  : 'http://localhost:5001';

interface BaseItem {
  id: string;
  type: string;
  attributes: {
    name: string;
    description: string | null;
    photo_url: string | null;
    base_price_in_cents?: number;
    price_period?: string;
    product_type: string;
    archived: boolean;
    show_in_store: boolean;
    created_at: string;
    updated_at: string;
    inventory?: {
      available: number;
      stock_count: number;
      plannable: number;
    };
    [key: string]: any; // Allow for additional attributes
  };
  relationships: Record<string, unknown>;
}

export interface EmailData {
  customer_email: any;
  customer_name: any;
  order_id: any;
  order_date: any;
  order_total: any;
  order_fufilment_type: any;
  order_delivery_address: any;
  order_notes: any;
  wantsSignage: boolean;
  items: Array<{
    name: string;
    quantity: number;
    price: number;
    image: any;
  }>;
}

export interface Product {
  id: string;
  type: 'products';
  attributes: {
    name: string;
    description: string | null;
    photo_url: string | null;
    base_price_in_cents: number;
    price_period?: string;
    archived: boolean;
    show_in_store: boolean;
    inventory?: {
      available: number;
      stock_count: number;
      plannable: number;
    };
    [key: string]: any;
  };
}

export type HireItem = Product;

export interface ApiResponse {
  products: HireItem[];
  meta: any;
}

// Update the fetch calls to include CORS headers
const fetchWithCORS = async (url: string, options: RequestInit = {}) => {
  const defaultOptions: RequestInit = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    ...options
  };

  const response = await fetch(url, defaultOptions);
  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`API call failed: ${response.status} ${errorText}`);
  }
  return response.json();
};

export const fetchHireItems = async (page: number = 1, itemsPerPage: number = 20) => {
  try {
    const response = await fetchWithCORS(
      `${API_BASE_URL}/api/product_groups?page=${page}&per_page=${itemsPerPage}`
    );
    
    // The response is already in the correct format with data and meta
    return response as ItemsResponse;
  } catch (error) {
    console.error('Error in fetchHireItems:', error);
    throw error;
  }
};

interface CustomerResponse {
  data: Array<{
    id: string;
    type: 'customers';
    attributes: {
      name: string;
      email: string;
      phone: string;
      // ... other attributes
    };
  }>;
}

interface CreateCustomerData {
  name: string;
  email: string;
  phone: string;
}

interface UpdateCustomerData {
  name?: string;
  phone?: string;
}

interface CreateOrderData {
  customer_id: string;
  starts_at: string;
  stops_at: string;
  fulfillment_type: 'pickup' | 'delivery';
  delivery_address?: string;
  lines?: Array<{
    id: string;
    quantity: number;
  }>;
}

interface OrderResponse {
  order: {
    data: {
      id: string;
      type: 'orders';
      attributes: {
        status: string;
        // ... other order attributes
      };
    };
  };
  fulfillment: {
    data: {
      id: string;
      type: 'order_fulfillments';
      attributes: Record<string, any>;
    };
  } | null;
}

export const searchCustomerByEmail = async (email: string) => {
  try {
    return await fetchWithCORS(`${API_BASE_URL}/api/customers/search?email=${email}`);
  } catch (error) {
    console.error('Error fetching customer:', error);
    throw error;
  }
};

export const createCustomer = async (customerData: CreateCustomerData) => {
  try {
    return await fetchWithCORS(`${API_BASE_URL}/api/customers`, {
      method: 'POST',
      body: JSON.stringify({
        data: {
          type: 'customers',
          attributes: customerData
        }
      })
    });
  } catch (error) {
    console.error('Error creating customer:', error);
    throw error;
  }
};

export const updateCustomer = async (customerId: string, customerData: UpdateCustomerData) => {
  try {
    return await fetchWithCORS(`${API_BASE_URL}/api/customers/${customerId}`, {
      method: 'PUT',
      body: JSON.stringify({
        data: {
          id: customerId,
          type: 'customers',
          attributes: customerData
        }
      })
    });
  } catch (error) {
    console.error('Error updating customer:', error);
    throw error;
  }
};

export const createOrder = async (orderData: CreateOrderData) => {
  try {
    return await fetchWithCORS(`${API_BASE_URL}/api/orders`, {
      method: 'POST',
      body: JSON.stringify(orderData)
    });
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
};

export const sendEmail = async (emailData: EmailData) => {
  try {
    return await fetchWithCORS(`${API_BASE_URL}/api/email`, {
      method: 'POST',
      body: JSON.stringify(emailData)
    });
  } catch (error) {
    console.error('Error sending email:', error);
    throw error;
  }
};

// Function to fetch items for a specific category
export const fetchCategoryItems = async (
  tag: string,
  page: number = 1,
  itemsPerPage: number = 20
) => {
  try {
    const params = new URLSearchParams();
    params.append('tag', tag);
    params.append('page', page.toString());
    params.append('per_page', itemsPerPage.toString());

    const response = await fetchWithCORS(
      `${API_BASE_URL}/api/boomerang/items?${params.toString()}`
    );
    return response as ItemsResponse;
  } catch (error) {
    console.error('Error in fetchCategoryItems:', error);
    throw error;
  }
};

// Function to search items
export const searchHireItems = async (
  query: string,
  page: number = 1,
  itemsPerPage: number = 20
) => {
  try {
    if (!query) {
      return {
        data: [],
        meta: {
          total_items: 0,
          current_page: page,
          per_page: itemsPerPage
        }
      } as ItemsResponse;
    }

    const params = new URLSearchParams();
    params.append('query', query);
    params.append('page', page.toString());
    params.append('per_page', itemsPerPage.toString());

    const response = await fetchWithCORS(
      `${API_BASE_URL}/api/boomerang/items?${params.toString()}`
    );
    return response as ItemsResponse;
  } catch (error) {
    console.error('Error in searchHireItems:', error);
    throw error;
  }
};

// Keep all existing interfaces and functions, but reorganize the bundle-related ones

export interface Bundle {
  id: string;
  type: 'bundles';
  attributes: {
    name: string;
    description: string | null;
    photo_url: string | null;
    base_price_in_cents: number;
    price_period?: string;
    archived: boolean;
    show_in_store: boolean;
    created_at: string;
    updated_at: string;
    inventory?: {
      available: number;
      stock_count: number;
      plannable: number;
    };
    [key: string]: any;
  };
  relationships: {
    bundle_items: {
      data: Array<{
        id: string;
        type: string;
      }>;
    };
  };
}

export interface BundleResponse {
  data: Bundle[];
  included?: any[]; // This will contain the bundle items
  meta: any;
}

// Bundle-related functions
export const fetchBundles = async (page: number = 1, itemsPerPage: number = 20) => {
  try {
    const response = await fetchWithCORS(`${API_BASE_URL}/api/bundles?page=${page}&per_page=${itemsPerPage}`);
    return response;
  } catch (error) {
    console.error('Error in fetchBundles:', error);
    throw error;
  }
};

export const fetchBundleDetails = async (bundleId: string) => {
  try {
    const response = await fetchWithCORS(`${API_BASE_URL}/api/bundles/${bundleId}`);
    return response;
  } catch (error) {
    console.error('Error in fetchBundleDetails:', error);
    throw error;
  }
};

// Product-related interfaces and functions
export interface ProductDetails {
  data: {
    id: string;
    type: 'products';
    attributes: {
      name: string;
      description: string | null;
      photo_url: string | null;
      base_price_in_cents: number;
      price_period?: string;
      archived: boolean;
      show_in_store: boolean;
      inventory?: {
        available: number;
        stock_count: number;
        plannable: number;
      };
      [key: string]: any;
    };
  };
}

export interface ProductGroup {
  id: string;
  type: 'product_groups';
  attributes: {
    name: string;
    description: string | null;
    photo_url: string | null;
    created_at: string;
    updated_at: string;
    inventory?: {
      available: number;
      stock_count: number;
      plannable: number;
    };
    [key: string]: any;
  };
  relationships: {
    products: {
      data: Array<{
        id: string;
        type: string;
      }>;
    };
    photos?: {
      data: Array<{
        id: string;
        type: string;
      }>;
    };
  };
}

export interface ProductGroupResponse {
  data: ProductGroup;
  included?: Product[];  // Only include Product type since we filter out Photos
  meta?: any;
}

export const fetchProductById = async (productId: string) => {
  try {
    const response = await fetchWithCORS(`${API_BASE_URL}/api/products/${productId}`);
    return response;
  } catch (error) {
    console.error('Error in fetchProductById:', error);
    throw error;
  }
};

export const fetchProductGroup = async (groupId: string) => {
  try {
    const response = await fetchWithCORS(`${API_BASE_URL}/api/product-groups/${groupId}`);
    return response as ProductGroupResponse;
  } catch (error) {
    console.error('Error in fetchProductGroup:', error);
    throw error;
  }
};

// Add these interfaces
export interface BundleItem {
  id: string;
  type: 'bundle_items';
  attributes: {
    product_group_id: string | null;
    product_id: string | null;
    quantity: number;
    name: string;
    description?: string;
    required: boolean;
  };
}

export interface BundleDetailsResponse {
  data: Bundle;
  included: BundleItem[];
}

// Add this helper function to manage bundle item selections
export interface BundleItemSelection {
  bundleItemId: string;
  productGroupId: string | null;
  selectedProductId: string | null;
  quantity: number;
  required: boolean;
  name: string;
}

export interface BundleSelections {
  [bundleItemId: string]: BundleItemSelection;
}

// Add Photo interfaces
export interface Photo {
  id: string;
  type: 'photos';
  attributes: {
    owner_id: string;
    owner_type: string;
    photo_url: string;
    original_url: string;
    large_url: string;
    xlarge_url: string;
    position: number;
    created_at: string;
    updated_at: string;
  };
}

export interface PhotoResponse {
  data: Photo[];
  meta: any;
}

// Add a new interface for items response that includes product groups
export interface ItemsResponse {
  data: Array<ProductGroup | Bundle | Product>;
  meta: {
    total_items: number;
    current_page: number;
    per_page: number;
  };
}

// Add function to fetch photos
export const fetchPhotos = async (ownerId: string) => {
  try {
    const response = await fetchWithCORS(`${API_BASE_URL}/api/photos?owner_id=${ownerId}`);
    return response as PhotoResponse;
  } catch (error) {
    console.error('Error in fetchPhotos:', error);
    throw error;
  }
};

export interface GroupedProduct {
  id: string;
  attributes: {
    name: string;
    base_price_in_cents: number;
    photo_url: string | null;
    inventory?: {
      available: number;
    };
  };
} 