import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function calculateBundlePrice(bundleSelections: { [key: string]: BundleSelection }, products: { [key: string]: ProductDetails }) {
  let totalOriginalPrice = 0;
  let totalDiscountedPrice = 0;

  Object.values(bundleSelections).forEach(selection => {
    if (selection.selectedProductId && products[selection.selectedProductId]) {
      const basePrice = products[selection.selectedProductId].data.attributes.base_price_in_cents;
      const discountMultiplier = 1 - (selection.discount_percentage || 0) / 100;
      
      totalOriginalPrice += basePrice * selection.quantity;
      totalDiscountedPrice += (basePrice * discountMultiplier) * selection.quantity;
    }
  });

  return {
    originalPrice: totalOriginalPrice / 100,
    discountedPrice: totalDiscountedPrice / 100,
    totalSavings: (totalOriginalPrice - totalDiscountedPrice) / 100
  };
}
