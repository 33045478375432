import React, { useEffect, useState, useRef } from 'react';
import { useCart } from '../context/CartContext';
import { searchHireItems, HireItem, Bundle, ProductGroup, fetchProductGroup, fetchBundleDetails } from '../services/booqableApi';
import { ExpandableCard, GroupedProduct } from '../components/ExpandableCard';
import { motion } from 'framer-motion';
import { useDebounce } from '../hooks/useDebounce';
import { useSearchParams, useParams, useNavigate, useLocation } from 'react-router-dom';
import { useBundleHandling } from '../hooks/useBundleHandling';
import { useProductGroupHandling } from '../hooks/useProductGroupHandling';
import { Link } from 'react-router-dom';
import SEOMetadata from '../components/SEOMetadata';

const ITEMS_PER_PAGE = 30;

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const item = {
  hidden: { opacity: 0, y: 20 },
  show: { 
    opacity: 1, 
    y: 0,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 20
    }
  }
};

interface SearchState {
  items: (ProductGroup | Bundle)[];
  isLoading: boolean;
  error: string | null;
  currentPage: number;
  hasMore: boolean;
  totalItems: number;
}

// Add this outside of any component, at module level
const initializedDirectLinks = new Set<string>();

export const Search = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState(searchParams.get('q') || '');
  const [state, setState] = useState<SearchState>({
    items: [],
    isLoading: false,
    error: null,
    currentPage: 1,
    hasMore: false,
    totalItems: 0
  });
  
  // Add states for shareable links
  const [expandedItemFromURL, setExpandedItemFromURL] = useState<{
    type: 'product-group' | 'bundle' | null;
    id: string | null;
  }>({ type: null, id: null });
  const [directlyFetchedItem, setDirectlyFetchedItem] = useState<{
    type: 'product-group' | 'bundle';
    data: any;
    id: string;
  } | null>(null);
  const [isLoadingURLItem, setIsLoadingURLItem] = useState(false);
  const [directFetchError, setDirectFetchError] = useState<string | null>(null);
  
  // Create a reference to track if we've attempted to fetch an item
  const attemptedItemRef = useRef<{type: string, id: string} | null>(null);
  
  // Add a ref to track whether URL parameters were set by user clicking on a card
  const userExpandedItemRef = useRef<{type: string, id: string} | null>(null);
  
  const { addItem } = useCart();
  const debouncedSearch = useDebounce(searchQuery, 500);
  const bundleHandling = useBundleHandling();
  const productGroupHandling = useProductGroupHandling();
  const location = useLocation();
  const navigate = useNavigate();

  const { items, isLoading, error, currentPage, hasMore, totalItems } = state;

  // Function to update URL with item info when expanded
  const handleItemExpand = (type: 'product-group' | 'bundle', id: string, isExpanding: boolean) => {
    if (isExpanding) {
      // Track that this expansion was initiated by user clicking a card
      userExpandedItemRef.current = { type, id };
      
      // Update URL with item info
      const newSearchParams = new URLSearchParams(searchParams.toString());
      newSearchParams.set('type', type);
      newSearchParams.set('id', id);
      setSearchParams(newSearchParams);
      
      // Set expanded item state
      setExpandedItemFromURL({ type, id });
      
      // Trigger the appropriate expand handler
      if (type === 'bundle') {
        bundleHandling.handleBundleExpand(id, true);
      } else {
        productGroupHandling.handleProductGroupExpand(id, true);
      }
    } else {
      // Clear the user expanded item ref when closing
      userExpandedItemRef.current = null;
      
      // Remove item info from URL
      const newSearchParams = new URLSearchParams(searchParams.toString());
      newSearchParams.delete('type');
      newSearchParams.delete('id');
      setSearchParams(newSearchParams);
      
      // Clear expanded item state
      setExpandedItemFromURL({ type: null, id: null });
    }
  };

  // Check for item ID in URL parameters on load
  useEffect(() => {
    const type = searchParams.get('type');
    const id = searchParams.get('id');
    
    // IMPORTANT: Don't proceed if we are already loading or have already fetched this exact item
    if (isLoadingURLItem || (directlyFetchedItem && directlyFetchedItem.id === id && directlyFetchedItem.type === type)) {
      return;
    }
    
    // IMPORTANT: Don't proceed if we've already attempted to fetch this exact item
    const alreadyAttemptedFetch = 
      attemptedItemRef.current && 
      attemptedItemRef.current.type === type && 
      attemptedItemRef.current.id === id;
    
    if (alreadyAttemptedFetch) {
      return;
    }
    
    // Check if this URL update came from a user clicking on a card
    const isUserExpandedItem = 
      userExpandedItemRef.current && 
      userExpandedItemRef.current.type === type && 
      userExpandedItemRef.current.id === id;
    
    if (type && id && (type === 'product-group' || type === 'bundle')) {
      setExpandedItemFromURL({ type: type as 'product-group' | 'bundle', id });
      
      // If this was triggered by user clicking a card, don't use DirectLinkCard
      if (isUserExpandedItem) {
        setDirectlyFetchedItem(null);
        return;
      }
      
      // For direct links (not user clicks), fetch and use DirectLinkCard
      // Check if the item exists in the current loaded items (for logging purposes only)
      const itemExists = items.some(item => 
        item.id === id && 
        ((type === 'bundle' && item.type === 'bundles') ||
         (type === 'product-group' && item.type === 'product_groups'))
      );
      
      // Only fetch if we haven't already tried fetching it
      if (!directlyFetchedItem) {
        // Set flag to indicate we're attempting to fetch this item
        attemptedItemRef.current = { type, id };
        
        setIsLoadingURLItem(true);
        setDirectFetchError(null);
        
        if (type === 'product-group') {
          fetchProductGroup(id)
            .then(response => {
              if (response && response.data) {
                // Only update if we're still looking for this item (checking URL hasn't changed)
                if (searchParams.get('id') === id && searchParams.get('type') === type) {
                  setDirectlyFetchedItem({
                    type: 'product-group',
                    data: response,
                    id
                  });
                  
                  // Initialize the product group
                  productGroupHandling.handleProductGroupExpand(id, true);
                }
              } else {
                setDirectFetchError('Could not load the requested item - invalid response');
              }
            })
            .catch(error => {
              console.error('Error fetching product group:', error);
              setDirectFetchError('Could not load the requested item');
            })
            .finally(() => {
              setIsLoadingURLItem(false);
            });
        } else if (type === 'bundle') {
          fetchBundleDetails(id)
            .then(response => {
              if (response && response.data) {
                // Only update if we're still looking for this item (checking URL hasn't changed)
                if (searchParams.get('id') === id && searchParams.get('type') === type) {
                  setDirectlyFetchedItem({
                    type: 'bundle',
                    data: response,
                    id
                  });
                  
                  // Initialize the bundle
                  bundleHandling.handleBundleExpand(id, true);
                }
              } else {
                setDirectFetchError('Could not load the requested item - invalid response');
              }
            })
            .catch(error => {
              console.error('Error fetching bundle:', error);
              setDirectFetchError('Could not load the requested item');
            })
            .finally(() => {
              setIsLoadingURLItem(false);
            });
        }
      }
    } else {
      // No valid item in URL, clear states
      setExpandedItemFromURL({ type: null, id: null });
      setDirectlyFetchedItem(null);
      setDirectFetchError(null);
      userExpandedItemRef.current = null;
    }
    
  }, [searchParams]); // Only depend on searchParams

  // Update URL when search query changes
  const handleSearchChange = (value: string) => {
    setSearchQuery(value);
    if (value.trim()) {
      setSearchParams({ q: value.trim() });
    } else {
      setSearchParams({});
    }
  };

  // Update search query when URL changes
  useEffect(() => {
    const queryParam = searchParams.get('q');
    if (queryParam !== searchQuery) {
      setSearchQuery(queryParam || '');
    }
  }, [searchParams]);

  const fetchItems = async (page = 1, searchTerm = debouncedSearch) => {
    if (!searchTerm) {
      setState(prev => ({
        ...prev,
        items: [],
        isLoading: false,
        error: null,
        currentPage: 1,
        hasMore: false,
        totalItems: 0
      }));
      return;
    }

    setState(prev => ({ ...prev, isLoading: true, error: null }));

    try {
      const { data, meta } = await searchHireItems(searchTerm, page);
      
      // Ensure that data is cast to the correct type
      const typedData = data as (ProductGroup | Bundle)[];
      
      setState(prev => ({
        items: page === 1 ? typedData : [...prev.items, ...typedData],
        totalItems: meta.total_items,
        hasMore: page < Math.ceil(meta.total_items / meta.per_page),
        currentPage: page,
        isLoading: false,
        error: null
      }));
    } catch (error) {
      console.error('Error fetching search results:', error);
      setState(prev => ({
        ...prev,
        isLoading: false,
        error: 'Failed to load search results. Please try again.'
      }));
    }
  };

  useEffect(() => {
    if (debouncedSearch) {
      fetchItems(1);
    } else {
      setState(prev => ({
        ...prev,
        items: [],
        totalItems: 0,
        hasMore: false,
        currentPage: 1
      }));
    }
  }, [debouncedSearch]);

  const loadMore = async () => {
    if (!hasMore || isLoading) return;
    await fetchItems(currentPage + 1);
  };

  // Group products by product_group_id (same as in Hire.tsx)
  const groupItems = (items: Array<ProductGroup | Bundle>) => {
    const groups = new Map<string, GroupedProduct>();
    
    items.forEach(item => {
      if (item.type === 'bundles') {
        // Handle bundles
        groups.set(item.id, {
          id: item.id,
          groupName: item.attributes.name,
          photo_url: item.attributes.photo_url,
          description: item.attributes.description,
          base_price_in_cents: item.attributes.base_price_in_cents || 0,
          variants: [{
            id: item.id,
            type: 'products',
            attributes: {
              name: item.attributes.name,
              description: item.attributes.description,
              photo_url: item.attributes.photo_url,
              base_price_in_cents: item.attributes.base_price_in_cents,
              archived: item.attributes.archived,
              show_in_store: item.attributes.show_in_store,
              inventory: item.attributes.inventory
            }
          }],
          totalAvailable: item.attributes.inventory?.stock_count || 0,
          isBundle: true
        });

        if (item.attributes.inventory) {

        }
      } else if (item.type === 'product_groups') {
        // Handle product groups
        groups.set(item.id, {
          id: item.id,
          groupName: item.attributes.name,
          photo_url: item.attributes.photo_url,
          description: item.attributes.description,
          base_price_in_cents: item.attributes.base_price_in_cents || 0,
          variants: [], // Will be populated when expanded
          totalAvailable: item.attributes.inventory?.stock_count || 0,
          isProductGroup: true,
          variation_fields: item.attributes.variation_fields || []
        });
      }
    });

    return Array.from(groups.values());
  };

  const groupedProducts = React.useMemo(() => {
    return groupItems(items);
  }, [items]);

  // Modify the DirectLinkCard component to use a stable ref and add lifecycle logs
  const renderDirectLinkCard = () => {
    if (!directlyFetchedItem) {
      return null;
    }
    
    if (!directlyFetchedItem.data || !directlyFetchedItem.data.data) {
      return null;
    }
    
    // Create a GroupedProduct from the directly fetched data
    let item: GroupedProduct;
    
    try {
      if (directlyFetchedItem.type === 'bundle') {
        const bundle = directlyFetchedItem.data.data;
        item = {
          id: bundle.id,
          groupName: bundle.attributes.name,
          photo_url: bundle.attributes.photo_url,
          description: bundle.attributes.description,
          base_price_in_cents: bundle.attributes.base_price_in_cents || 0,
          variants: [{
            id: bundle.id,
            type: 'products',
            attributes: {
              name: bundle.attributes.name,
              description: bundle.attributes.description,
              photo_url: bundle.attributes.photo_url,
              base_price_in_cents: bundle.attributes.base_price_in_cents,
              archived: false,
              show_in_store: true,
              inventory: bundle.attributes.inventory
            }
          }],
          totalAvailable: bundle.attributes.inventory?.stock_count || 0,
          isBundle: true
        };
      } else {
        // Handle product group
        const productGroup = directlyFetchedItem.data.data;
        item = {
          id: productGroup.id,
          groupName: productGroup.attributes.name,
          photo_url: productGroup.attributes.photo_url,
          description: productGroup.attributes.description,
          base_price_in_cents: productGroup.attributes.base_price_in_cents || 0,
          variants: [], // Will be populated by the handlers
          totalAvailable: productGroup.attributes.inventory?.stock_count || 0,
          isProductGroup: true,
          variation_fields: productGroup.attributes.variation_fields || []
        };
      }
    } catch (error) {
      console.error('Error creating GroupedProduct from directlyFetchedItem:', error);
      return null;
    }
    
    // Create the expandable card props
    const directCardProps = {
      key: `direct-link-${directlyFetchedItem.id}`,
      group: item,
      onAddToCart: (item: any, quantity: number) => {
        addItem({
          id: item.id,
          name: item.name,
          price: item.price,
          photo_url: item.photo_url,
          stock_count: item.stock_count,
          quantity: quantity,
          isBundle: directlyFetchedItem.type === 'bundle',
          bundleVariations: item.bundleVariations
        });
      },
      onExpand: (isExpanding: boolean) => {
        if (!isExpanding) {
          // Clear URL parameters and revert to main page view
          const newSearchParams = new URLSearchParams(searchParams.toString());
          newSearchParams.delete('type');
          newSearchParams.delete('id');
          
          // Clear all states first before navigation to avoid any race conditions
          setDirectlyFetchedItem(null);
          setExpandedItemFromURL({ type: null, id: null });
          userExpandedItemRef.current = null;
          attemptedItemRef.current = null;
          
          // Then update search params to clear the URL parameters
          setSearchParams(newSearchParams);
        }
      },
      isLoading: directlyFetchedItem.type === 'bundle'
        ? bundleHandling.loadingBundleDetails.includes(directlyFetchedItem.id)
        : productGroupHandling.loadingProductGroups.includes(directlyFetchedItem.id),
      bundleSelections: directlyFetchedItem.type === 'bundle' 
        ? bundleHandling.bundleSelections[directlyFetchedItem.id] 
        : undefined,
      productGroups: bundleHandling.productGroups,
      products: bundleHandling.productDetails,
      onProductSelect: directlyFetchedItem.type === 'bundle'
        ? (bundleItemId: string, productId: string | null) => 
            bundleHandling.handleBundleItemProductSelect(directlyFetchedItem.id, bundleItemId, productId)
        : (groupId: string, productId: string | null) => 
            productGroupHandling.handleProductSelect(groupId, productId),
      isBundle: directlyFetchedItem.type === 'bundle',
      isProductGroup: directlyFetchedItem.type === 'product-group',
      productGroupStates: productGroupHandling.productGroupStates,
      isBundleComplete: directlyFetchedItem.type === 'bundle' 
        ? bundleHandling.isBundleComplete 
        : undefined,
      bundleDetails: directlyFetchedItem.type === 'bundle' 
        ? bundleHandling.bundleDetails 
        : undefined,
      isExpanded: true,
      isFromDirectLink: true
    };
    
    // Return the ExpandableCard with the configured props
    return <ExpandableCard {...directCardProps} />;
  };

  // Generate SEO metadata based on search query
  const generateSEOMetadata = () => {
    let baseUrl = window.location.origin;
    if (baseUrl.includes('localhost') || baseUrl.includes('127.0.0.1')) {
      baseUrl = 'https://www.thetreasureroom.com.au';
    }
    
    const searchTerm = searchParams.get('q') || '';
    let title = 'Search Results';
    let description = 'Find event props and styling items for hire at The Treasure Room.';
    
    if (searchTerm) {
      title = `Search Results for "${searchTerm}"`;
      description = `Browse results for "${searchTerm}" at The Treasure Room. Find premium event props and styling items for your event.`;
    }
    
    // Set noIndex true for empty search results to avoid indexing useless pages
    const noIndex = searchTerm !== '' && groupedProducts.length === 0;
    
    return {
      title,
      description,
      canonicalUrl: `${baseUrl}/search?q=${searchTerm}`,
      noIndex,
      ogType: 'website'
    };
  };
  
  const seoMetadata = generateSEOMetadata();

  return (
    <div className="min-h-screen text-white pt-20 relative overflow-hidden">
      <SEOMetadata 
        title={searchQuery ? `Search Results for "${searchQuery}" - The Treasure Room` : "Search - The Treasure Room"}
        description={
          searchQuery 
            ? `Find ${searchQuery} from The Treasure Room's premium collection of event hire items.` 
            : "Search The Treasure Room's premium collection of event hire items."
        }
        canonicalUrl={`https://www.thetreasureroom.com.au/search`}
      />
      <div className="absolute inset-0 w-full h-full bg-[#E3C9A8] -z-10" />
      
      {/* Loading indicator for URL item */}
      {isLoadingURLItem && (
        <div className="fixed inset-0 bg-black bg-opacity-70 z-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-xl text-black">
            <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-[#5E0A0A] mx-auto"></div>
            <p className="text-center mt-4">Loading item...</p>
          </div>
        </div>
      )}
      
      {/* Error message for URL item */}
      {directFetchError && (
        <div className="fixed inset-0 bg-black bg-opacity-70 z-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-xl text-black max-w-md">
            <h3 className="text-xl font-semibold text-[#5E0A0A]">Error</h3>
            <p className="mt-2">{directFetchError}</p>
            <div className="mt-4 flex justify-end">
              <button 
                onClick={() => {
                  setDirectFetchError(null);
                  // Clear URL parameters
                  const newSearchParams = new URLSearchParams(searchParams.toString());
                  newSearchParams.delete('type');
                  newSearchParams.delete('id');
                  setSearchParams(newSearchParams);
                }}
                className="px-4 py-2 bg-[#5E0A0A] text-white rounded hover:bg-opacity-90"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      
      {/* Direct link card modal */}
      {directlyFetchedItem && renderDirectLinkCard()}
      
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="space-y-8">
          <div>
            <h1 className="text-4xl font-bold text-[#5E0A0A]">
              Search
            </h1>
            <p className="text-[#5E0A0A] mt-2">
              Find the perfect items for your event
            </p>
          </div>
          
          <div className="relative">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => handleSearchChange(e.target.value)}
              placeholder="Search for items..."
              className="w-full p-4 pl-12 bg-white/10 backdrop-blur-md 
                       text-white border border-white/20 rounded-lg 
                       shadow-[0_8px_32px_0_rgba(0,0,0,0.37)] 
                       focus:outline-none focus:ring-2 focus:ring-white/30
                       placeholder:text-white/60"
            />
            <svg
              className="absolute left-4 top-1/2 transform -translate-y-1/2 w-5 h-5 text-white/60"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          
          {isLoading && items.length === 0 ? (
            <div className="flex justify-center py-12">
              <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-white"></div>
            </div>
          ) : error ? (
            <div className="text-center py-12 text-red-400">
              <p>{error}</p>
            </div>
          ) : items.length === 0 ? (
            <div className="text-center py-12 text-white/70">
              {searchQuery ? (
                <>
                  <p className="text-lg">No items found for "{searchQuery}"</p>
                  <p className="mt-2">Try a different search term or browse our <Link to="/hire" className="underline">collection</Link></p>
                </>
              ) : (
                <p className="text-lg">Enter a search term to find items</p>
              )}
            </div>
          ) : (
            <>
              <div className="text-[#5E0A0A] mb-4">
                Found {totalItems} item{totalItems !== 1 ? 's' : ''} for "{searchQuery}"
              </div>
              
              <motion.div 
                variants={container}
                initial="hidden"
                animate="show"
                className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6 
                         [&>*]:h-full [&>*>*]:h-full"
              >
                {groupedProducts.map((group) => (
                  <motion.div key={group.id} variants={item}>
                    <ExpandableCard
                      group={group}
                      onAddToCart={(item, quantity) => {
                        addItem({
                          id: item.id,
                          name: item.name,
                          price: item.price,
                          photo_url: item.photo_url,
                          stock_count: item.stock_count,
                          quantity: quantity,
                          isBundle: group.isBundle,
                          bundleVariations: item.bundleVariations
                        });
                      }}
                      onExpand={(isExpanding) => {
                        const type = group.isBundle ? 'bundle' : 'product-group';
                        handleItemExpand(type, group.id, isExpanding);
                      }}
                      isLoading={
                        group.isBundle 
                          ? bundleHandling.loadingBundleDetails.includes(group.id)
                          : productGroupHandling.loadingProductGroups.includes(group.id)
                      }
                      bundleSelections={group.isBundle ? bundleHandling.bundleSelections[group.id] : undefined}
                      productGroups={bundleHandling.productGroups}
                      products={bundleHandling.productDetails}
                      onProductSelect={
                        group.isBundle
                          ? (bundleItemId, productId) => 
                              bundleHandling.handleBundleItemProductSelect(group.id, bundleItemId, productId)
                          : (groupId, productId) => 
                              productGroupHandling.handleProductSelect(groupId, productId)
                      }
                      isBundle={group.isBundle}
                      isProductGroup={group.isProductGroup}
                      productGroupStates={productGroupHandling.productGroupStates}
                      isBundleComplete={group.isBundle ? bundleHandling.isBundleComplete : undefined}
                      bundleDetails={group.isBundle ? bundleHandling.bundleDetails : undefined}
                      isExpanded={
                        expandedItemFromURL.id === group.id && 
                        ((group.isBundle && expandedItemFromURL.type === 'bundle') || 
                         (group.isProductGroup && expandedItemFromURL.type === 'product-group')) &&
                        !directlyFetchedItem // Don't expand regular cards when viewing direct link
                      }
                    />
                  </motion.div>
                ))}
              </motion.div>
              
              {hasMore && (
                <div className="flex justify-center mt-8">
                  <button
                    onClick={loadMore}
                    disabled={isLoading}
                    className="px-6 py-3 bg-white/30 backdrop-blur-md text-black rounded-lg font-medium 
                             hover:bg-white/40 transition-colors disabled:bg-white/20 
                             disabled:cursor-not-allowed flex items-center gap-2
                             border border-white/20 shadow-[0_8px_32px_0_rgba(0,0,0,0.37)]"
                  >
                    {isLoading ? (
                      <>
                        <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-black"></div>
                        Loading...
                      </>
                    ) : (
                      `Load More (${items.length} of ${totalItems})`
                    )}
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}; 