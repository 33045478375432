import React from 'react';
import { useCart } from '../context/CartContext';
import { CheckoutFormData } from '../types/checkout';
import { useNavigate } from 'react-router-dom';
import { X, Minus, Plus } from 'lucide-react';
import { searchCustomerByEmail, createCustomer, createOrder, sendEmail, updateCustomer } from '../services/booqableApi';
import { toast } from 'react-hot-toast';
import { motion } from 'framer-motion';
import SEOMetadata from '../components/SEOMetadata';

export function CheckoutPage() {
  const { items, totalPrice, updateQuantity, removeItem, clearCart } = useCart();
  const navigate = useNavigate();
  
  const [formData, setFormData] = React.useState<CheckoutFormData>({
    name: '',
    email: '',
    phone: '',
    hireDate: '',
    deliveryOption: 'pickup',
    deliveryAddress: '',
    orderNotes: '',
    wantsSignage: false,
  });

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const gst = totalPrice * 0.1;
  const grandTotal = totalPrice + gst;

  const isFormValid = () => {
    // Check if all required fields are filled
    const requiredFieldsFilled = 
      formData.name.trim() !== '' &&
      formData.email.trim() !== '' &&
      formData.phone.trim() !== '' &&
      formData.hireDate !== '';

    // Check delivery requirements if delivery is selected
    if (formData.deliveryOption === 'delivery') {
      return (
        requiredFieldsFilled &&
        formData.deliveryAddress.trim() !== ''
      );
    }

    // If pickup is selected, just check required fields
    return requiredFieldsFilled;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Check delivery option and subtotal requirements
    const hireDate = new Date(formData.hireDate);
    const dayOfWeek = hireDate.getDay();
    const isWeekend = dayOfWeek === 0 || dayOfWeek === 6; // Sunday = 0, Saturday = 6
    const minimumSubtotal = isWeekend ? 300 : 250;

    // Set warning message if below minimum but don't prevent submission
    if (formData.deliveryOption === 'delivery' && totalPrice < minimumSubtotal) {
      setErrorMessage(
        `Note: Our standard delivery minimum is $${minimumSubtotal} ${isWeekend ? 'for weekend hire' : 'for weekday hire'}. ` +
        'You can still submit your order and we will contact you regarding delivery costs. ' +
        'Alternatively, you can add more products to your cart to meet the minimum.'
      );
    } else {
      setErrorMessage('');
    }

    setIsSubmitting(true);


    try {
      // First, search for existing customer
      const customerResponse = await searchCustomerByEmail(formData.email);
      let customerId;

      if (customerResponse.data.length === 0) {
        // Scenario 1: No customer found - create new customer

        const newCustomer = await createCustomer({
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
        });
        customerId = newCustomer.data.id;
      } else {
        const existingCustomer = customerResponse.data[0];
        customerId = existingCustomer.id;
        
        // Check if phone number exists in customer properties
        const existingPhone = existingCustomer.attributes.properties?.phone;
        
        if (!existingPhone) {
          // Scenario 2: Customer found but no phone number - update customer
         
          await updateCustomer(customerId, {
            phone: formData.phone
          });
        } else {
          // Scenario 3: Customer found with phone number - do nothing
         
        }
      }

      // Create the order with fulfillment
      const orderData = {
        customer_id: customerId,
        starts_at: formData.hireDate,
        stops_at: formData.hireDate,
        fulfillment_type: formData.deliveryOption,
        delivery_address: formData.deliveryOption === 'delivery' ? formData.deliveryAddress : undefined,
        lines: items.map(item => ({
          id: item.id,
          quantity: item.quantity,
          isBundle: item.isBundle,
          bundleVariations: item.bundleVariations
        }))
      };


      const orderResponse = await createOrder(orderData);

      // Check if the order needs manual processing
      if (orderResponse.error && orderResponse.needsManualProcessing) {
        console.log('Order needs manual processing:', orderResponse);
        
        // Create fallback email data for manual processing
        const fallbackEmailData = {
          customer_email: formData.email,
          customer_name: formData.name,
          customer_phone: formData.phone,
          order_id: 'Manual Processing Required',
          order_date: new Date(formData.hireDate).toLocaleDateString(),
          order_total: grandTotal.toFixed(2),
          order_fufilment_type: formData.deliveryOption,
          order_delivery_address: formData.deliveryOption === 'delivery' ? formData.deliveryAddress : undefined,
          order_notes: formData.orderNotes,
          wantsSignage: formData.wantsSignage,
          items: items.map(item => ({
            name: item.name,
            quantity: item.quantity,
            price: item.price,
            image: item.photo_url,
            bundleVariations: item.bundleVariations ? item.bundleVariations.map(variation => ({
              name: variation.displayName,
              image: variation.displayPhotoUrl
            })) : undefined
          })),
          hasError: true,
          errorMessage: orderResponse.errorMessage,
          manualProcessingDetails: orderResponse.orderDetails
        };

        // Send fallback emails
        await sendEmail(fallbackEmailData);

        // Show success message to user but indicate manual processing
        toast.success('Your order has been received and will be processed manually. We will contact you shortly.');
        
        // Clear cart and redirect to success page
        clearCart();
        navigate('/success');
        return;
      }

      // Check if there was a fulfillment error
      if (orderResponse.fulfillmentError) {
        console.log('Order created but fulfillment failed:', orderResponse);
        
        // Create fallback email data for fulfillment error
        const fallbackEmailData = {
          customer_email: formData.email,
          customer_name: formData.name,
          customer_phone: formData.phone,
          order_id: orderResponse.order.data.id,
          order_date: new Date(formData.hireDate).toLocaleDateString(),
          order_total: grandTotal.toFixed(2),
          order_fufilment_type: formData.deliveryOption,
          order_delivery_address: formData.deliveryOption === 'delivery' ? formData.deliveryAddress : undefined,
          order_notes: formData.orderNotes,
          wantsSignage: formData.wantsSignage,
          items: items.map(item => ({
            name: item.name,
            quantity: item.quantity,
            price: item.price,
            image: item.photo_url,
            bundleVariations: item.bundleVariations ? item.bundleVariations.map(variation => ({
              name: variation.displayName,
              image: variation.displayPhotoUrl
            })) : undefined
          })),
          hasError: true,
          errorMessage: orderResponse.errorMessage,
          manualProcessingDetails: orderResponse.orderDetails
        };

        // Send fallback emails
        await sendEmail(fallbackEmailData);

        // Show success message to user but indicate manual processing
        toast.success('Your order has been received but needs additional processing. We will contact you shortly.');
        
        // Clear cart and redirect to success page
        clearCart();
        navigate('/success');
        return;
      }

      const emailData = {
        customer_email: formData.email,
        customer_name: formData.name,
        customer_phone: formData.phone,
        order_id: orderResponse.order.data.id,
        order_date: hireDate.toLocaleDateString(),
        order_total: grandTotal.toFixed(2),
        order_fufilment_type: formData.deliveryOption,
        order_delivery_address: formData.deliveryOption === 'delivery' ? formData.deliveryAddress : undefined,
        order_notes: formData.orderNotes,
        wantsSignage: formData.wantsSignage,
        items: items.map(item => ({
          name: item.name,
          quantity: item.quantity,
          price: item.price,
          image: item.photo_url,
          bundleVariations: item.bundleVariations ? item.bundleVariations.map(variation => ({
            name: variation.displayName,
            image: variation.displayPhotoUrl
          })) : undefined
        }))
      }

      const emailResponse = await sendEmail(emailData);


      // Clear cart and redirect to success page
      clearCart();
      navigate('/success');
    } catch (error: any) {
      console.error('Detailed checkout error:', error);
      
      // Only show error to user if it's not a 500 error
      if (error.response?.status !== 500) {
        alert('There was an error processing your order. Please try again.');
      } else {
        // For 500 errors, still send email and redirect to success
        console.log('500 Error detected - Creating fallback email data:', {
          customerInfo: {
            name: formData.name,
            email: formData.email,
            phone: formData.phone
          },
          orderInfo: {
            date: new Date(formData.hireDate).toLocaleDateString(),
            total: grandTotal.toFixed(2),
            fulfillmentType: formData.deliveryOption,
            deliveryAddress: formData.deliveryOption === 'delivery' ? formData.deliveryAddress : undefined,
            notes: formData.orderNotes,
            wantsSignage: formData.wantsSignage
          },
          items: items.map(item => ({
            name: item.name,
            quantity: item.quantity,
            price: item.price,
            hasImage: !!item.photo_url,
            isBundle: item.isBundle,
            bundleVariations: item.bundleVariations ? item.bundleVariations.map(variation => ({
              name: variation.displayName,
              hasImage: !!variation.displayPhotoUrl
            })) : undefined
          }))
        });

        const fallbackEmailData = {
          customer_email: formData.email,
          customer_name: formData.name,
          customer_phone: formData.phone,
          order_id: 'Unassigned',
          order_date: new Date(formData.hireDate).toLocaleDateString(),
          order_total: grandTotal.toFixed(2),
          order_fufilment_type: formData.deliveryOption,
          order_delivery_address: formData.deliveryOption === 'delivery' ? formData.deliveryAddress : undefined,
          order_notes: formData.orderNotes,
          wantsSignage: formData.wantsSignage,
          items: items.map(item => ({
            name: item.name,
            quantity: item.quantity,
            price: item.price,
            image: item.photo_url,
            bundleVariations: item.bundleVariations ? item.bundleVariations.map(variation => ({
              name: variation.displayName,
              image: variation.displayPhotoUrl
            })) : undefined
          })),
          hasError: true,
          errorMessage: 'Error Found 500: There is an issue with Booqable, and this order has not been added to the system.'
        };

        console.log('Sending fallback email with data:', {
          ...fallbackEmailData,
          items: fallbackEmailData.items.map(item => ({
            ...item,
            image: item.image ? 'Image URL present' : 'No image',
            bundleVariations: item.bundleVariations ? item.bundleVariations.map(variation => ({
              ...variation,
              image: variation.image ? 'Image URL present' : 'No image'
            })) : undefined
          }))
        });

        // Send fallback emails
        await sendEmail(fallbackEmailData);

        // Clear cart and redirect to success page
        clearCart();
        navigate('/success');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));

    // Check subtotal validation when delivery option is selected
    if (name === 'hireDate' || name === 'deliveryOption') {
      const hireDate = new Date(name === 'hireDate' ? value : formData.hireDate);
      const dayOfWeek = hireDate.getDay();
      const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
      const minimumSubtotal = isWeekend ? 300 : 250;
      const isDelivery = name === 'deliveryOption' ? value === 'delivery' : formData.deliveryOption === 'delivery';

      if (isDelivery && totalPrice < minimumSubtotal) {
        setErrorMessage(
          `Note: Our standard delivery minimum is $${minimumSubtotal} ${isWeekend ? 'for weekend hire' : 'for weekday hire'}. ` +
          'You can still submit your order and we will contact you regarding delivery costs. ' +
          'Alternatively, you can add more products to your cart to meet the minimum.'
        );
      } else {
        setErrorMessage('');
      }
    }
  };

  // Generate SEO metadata for the checkout page
  const generateSEOMetadata = () => {
    let baseUrl = window.location.origin;
    if (baseUrl.includes('localhost') || baseUrl.includes('127.0.0.1')) {
      baseUrl = 'https://www.thetreasureroom.com.au';
    }
    
    return {
      title: 'Checkout - Complete Your Order',
      description: 'Complete your event styling hire order with The Treasure Room. Secure checkout for premium event props and styling items.',
      canonicalUrl: `${baseUrl}/checkout`,
      noIndex: true, // This is a transactional page, shouldn't be indexed
      ogType: 'website'
    };
  };
  
  const seoMetadata = generateSEOMetadata();

  if (items.length === 0) {
    return (
      <div className="min-h-screen bg-neutral-900 text-white pt-20">
        <div className="max-w-4xl mx-auto px-4 py-12">
          <div className="text-center">
            <h2 className="text-2xl font-semibold mb-4">Your cart is empty</h2>
            <button
              onClick={() => navigate('/hire')}
              className="bg-white text-black px-6 py-2 rounded-lg font-medium hover:bg-neutral-100"
            >
              Continue Shopping
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen text-white pt-20 relative overflow-hidden">
      <SEOMetadata {...seoMetadata} />
      <div className="absolute inset-0 w-full h-full bg-[#E3C9A8] -z-10" />

      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-neutral-900">Checkout</h1>
          <button
            onClick={() => navigate('/hire')}
            className="p-2 hover:bg-black/5 rounded-lg transition-colors text-neutral-700"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Order Summary */}
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-neutral-900">Order Summary</h2>
            <div className="space-y-4">
              {items.map((item) => (
                <div
                  key={item.id}
                  className="flex gap-4 bg-white/50 backdrop-blur-sm rounded-xl p-4 border border-black/5"
                >
                  {item.photo_url && (
                    <img
                      src={item.photo_url}
                      alt={item.name}
                      className="w-20 h-20 object-cover rounded-lg"
                    />
                  )}
                  <div className="flex-1">
                    <h3 className="font-medium text-neutral-900">{item.name}</h3>
                    {item.bundleVariations && item.bundleVariations.length > 0 && (
                      <div className="mt-2 space-y-2">
                        {item.bundleVariations.map((variation) => (
                          <div key={variation.bundleItemId} className="flex items-center gap-2">
                            {variation.displayPhotoUrl && (
                              <img 
                                src={variation.displayPhotoUrl} 
                                alt={variation.displayName}
                                className="w-8 h-8 object-cover rounded-lg"
                              />
                            )}
                            <div className="text-sm text-neutral-600">
                              {variation.displayName}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="text-sm text-neutral-600 mt-1">
                      ${item.price.toFixed(2)} per item
                    </div>
                    <div className="flex items-center gap-2 mt-3">
                      <div className="flex items-center bg-white/50 rounded-lg border border-black/10">
                        <button
                          onClick={() => updateQuantity(item.id, Math.max(0, item.quantity - 1))}
                          className="p-1 hover:bg-black/5 rounded-l-lg transition-colors text-neutral-700"
                        >
                          <Minus className="w-4 h-4" />
                        </button>
                        <span className="px-3 py-1 font-medium text-neutral-900">{item.quantity}</span>
                        <button
                          onClick={() => updateQuantity(item.id, item.quantity + 1)}
                          className={`p-1 hover:bg-black/5 rounded-r-lg transition-colors text-neutral-700
                                    ${item.quantity >= item.stock_count ? 'opacity-50 cursor-not-allowed' : ''}`}
                          disabled={item.quantity >= item.stock_count}
                        >
                          <Plus className="w-4 h-4" />
                        </button>
                      </div>
                      {item.quantity >= item.stock_count && (
                        <span className="text-xs text-yellow-600">Max quantity</span>
                      )}
                      <div className="ml-auto text-sm font-medium text-neutral-900">
                        ${(item.price * item.quantity).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="border-t border-black/10 pt-4 space-y-2">
              <div className="flex justify-between text-neutral-600">
                <span>Subtotal</span>
                <span>${totalPrice.toFixed(2)}</span>
              </div>
              <div className="flex justify-between text-neutral-600">
                <span>GST (10%)</span>
                <span>${gst.toFixed(2)}</span>
              </div>
              <div className="flex justify-between text-lg font-semibold pt-2 border-t border-black/10 text-neutral-900">
                <span>Total</span>
                <span>${grandTotal.toFixed(2)}</span>
              </div>
            </div>
          </div>

          {/* Checkout Form */}
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-neutral-800 mb-2">Name</label>
                <input
                  type="text"
                  name="name"
                  required
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-4 py-2 bg-white/50 backdrop-blur-sm rounded-lg 
                           border border-black/10 text-neutral-900 placeholder-neutral-500
                           focus:outline-none focus:ring-2 focus:ring-black/20"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-neutral-800 mb-2">Email</label>
                <input
                  type="email"
                  name="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-4 py-2 bg-white/50 backdrop-blur-sm rounded-lg 
                           border border-black/10 text-neutral-900 placeholder-neutral-500
                           focus:outline-none focus:ring-2 focus:ring-black/20"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-neutral-800 mb-2">Phone Number</label>
                <input
                  type="tel"
                  name="phone"
                  required
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full px-4 py-2 bg-white/50 backdrop-blur-sm rounded-lg 
                           border border-black/10 text-neutral-900 placeholder-neutral-500
                           focus:outline-none focus:ring-2 focus:ring-black/20"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-neutral-800 mb-2">Hire Date</label>
                <input
                  type="date"
                  name="hireDate"
                  required
                  value={formData.hireDate}
                  onChange={handleChange}
                  className="w-full px-4 py-2 bg-white/50 backdrop-blur-sm rounded-lg 
                           border border-black/10 text-neutral-900 placeholder-neutral-500
                           focus:outline-none focus:ring-2 focus:ring-black/20"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-neutral-800 mb-2">Delivery Option</label>
                <select
                  name="deliveryOption"
                  value={formData.deliveryOption}
                  onChange={handleChange}
                  className="w-full px-4 py-2 bg-white/50 backdrop-blur-sm rounded-lg 
                           border border-black/10 text-neutral-900
                           focus:outline-none focus:ring-2 focus:ring-black/20"
                >
                  <option value="pickup">Pickup (Dry Hire)</option>
                  <option value="delivery">Delivery</option>
                </select>
                {formData.deliveryOption === 'pickup' && (
                  <p className="mt-2 text-sm text-neutral-600">
                    Pickup for hire items occurs on Friday the week of your order, and return occurs the following Monday. 
                    Please let us know in the order notes if you require times outside of these.
                  </p>
                )}
                {formData.deliveryOption === 'delivery' && (
                  <p className="mt-2 text-sm text-neutral-600">
                    Delivery fees and timings will be communicated to you via email.
                  </p>
                )}
              </div>

              {formData.deliveryOption === 'delivery' && (
                <div>
                  <label className="block text-sm font-medium text-neutral-800 mb-2">Delivery Address</label>
                  <textarea
                    name="deliveryAddress"
                    required
                    value={formData.deliveryAddress}
                    onChange={handleChange}
                    rows={3}
                    className="w-full px-4 py-2 bg-white/50 backdrop-blur-sm rounded-lg 
                             border border-black/10 text-neutral-900 placeholder-neutral-500
                             focus:outline-none focus:ring-2 focus:ring-black/20"
                  />
                </div>
              )}

              <div>
                <label className="flex items-center space-x-2 text-sm font-medium text-neutral-800 mb-2">
                  <input
                    type="checkbox"
                    name="wantsSignage"
                    checked={formData.wantsSignage}
                    onChange={(e) => setFormData(prev => ({ ...prev, wantsSignage: e.target.checked }))}
                    className="rounded border-black/10 bg-white/50 text-neutral-900 focus:ring-black/20"
                  />
                  <span>Would you like signage or decals?</span>
                </label>
                {formData.wantsSignage && (
                  <p className="mt-2 text-sm text-neutral-600">
                    We will contact you regarding your signage/decals requirements.
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-neutral-800 mb-2">Order Notes</label>
                <textarea
                  name="orderNotes"
                  value={formData.orderNotes}
                  onChange={handleChange}
                  rows={3}
                  placeholder="Add any special requirements or notes for your order"
                  className="w-full px-4 py-2 bg-white/50 backdrop-blur-sm rounded-lg 
                           border border-black/10 text-neutral-900 placeholder-neutral-500
                           focus:outline-none focus:ring-2 focus:ring-black/20"
                />
              </div>
            </div>

            {errorMessage && (
              <div className="p-4 bg-red-500/10 border border-red-500/50 rounded-lg text-red-600 text-sm mb-4">
                {errorMessage}
              </div>
            )}

            <button
              type="submit"
              disabled={isSubmitting || !isFormValid()}
              className="w-full bg-neutral-900 text-white py-3 rounded-lg font-medium
                       hover:bg-neutral-800 transition-colors disabled:opacity-50
                       disabled:cursor-not-allowed"
            >
              {isSubmitting ? 'Processing...' : `Submit Order Request - $${grandTotal.toFixed(2)}`}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
} 