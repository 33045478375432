import React, { createContext, useContext, useState, useEffect } from 'react';

interface BundleVariation {
  bundleItemId: string;
  productId: string;
  displayName: string;
  displayPhotoUrl: string | null;
}

export interface CartItem {
  id: string;
  name: string;
  price: number;
  photo_url: string | null;
  stock_count: number;
  quantity: number;
  isBundle?: boolean;
  bundleVariations?: BundleVariation[];
  _productGroupId?: string;
  _uniqueCartId?: string;
}

interface CartContextType {
  items: CartItem[];
  addItem: (item: CartItem) => void;
  removeItem: (id: string, productGroupId?: string) => void;
  updateQuantity: (id: string, quantity: number, productGroupId?: string) => void;
  clearCart: () => void;
  totalItems: number;
  totalPrice: number;
}

const CART_STORAGE_KEY = 'shopping-cart';
const CART_TIMESTAMP_KEY = 'shopping-cart-timestamp';
const CART_EXPIRY_HOURS = 12;

const CartContext = createContext<CartContextType | undefined>(undefined);

export function CartProvider({ children }: { children: React.ReactNode }) {
  const [items, setItems] = useState<CartItem[]>(() => {
    const savedCart = localStorage.getItem(CART_STORAGE_KEY);
    const timestamp = localStorage.getItem(CART_TIMESTAMP_KEY);
    
    if (savedCart && timestamp) {
      const now = new Date().getTime();
      const storedTime = parseInt(timestamp);
      const expiryTime = CART_EXPIRY_HOURS * 60 * 60 * 1000; // 12 hours in milliseconds
      
      if (now - storedTime > expiryTime) {
        // Cart has expired, clear storage
        localStorage.removeItem(CART_STORAGE_KEY);
        localStorage.removeItem(CART_TIMESTAMP_KEY);
        return [];
      }
      
      return JSON.parse(savedCart);
    }
    
    return [];
  });

  useEffect(() => {
    if (items.length > 0) {
      localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(items));
      localStorage.setItem(CART_TIMESTAMP_KEY, new Date().getTime().toString());
    } else {
      localStorage.removeItem(CART_STORAGE_KEY);
      localStorage.removeItem(CART_TIMESTAMP_KEY);
    }
  }, [items]);

  const addItem = (newItem: CartItem) => {

    // Create a clean copy of the item with internal tracking fields
    const uniqueCartId = newItem.isBundle 
      ? `bundle_${newItem.id}` 
      : `product_${newItem.id}_group_${newItem._productGroupId}`;

    const cleanItem = {
      ...JSON.parse(JSON.stringify({
        ...newItem,
        bundleVariations: newItem.bundleVariations || [],
        isBundle: Boolean(newItem.isBundle)
      })),
      _uniqueCartId: uniqueCartId,
      _productGroupId: newItem._productGroupId
    };

    setItems(currentItems => {
      // Check for duplicates using product ID
      const existingItem = currentItems.find(item => {
        if (item.id === cleanItem.id) {
          if (item.isBundle && cleanItem.isBundle) {
            // For bundles, compare variations
            return JSON.stringify(item.bundleVariations) === JSON.stringify(cleanItem.bundleVariations);
          }
          // For regular products, just match by product ID
          return !item.isBundle && !cleanItem.isBundle;
        }
        return false;
      });

      if (existingItem) {
        const newQuantity = Math.min(
          existingItem.quantity + cleanItem.quantity,
          existingItem.stock_count
        );

        return currentItems.map(item =>
          item.id === existingItem.id && 
          JSON.stringify(item.bundleVariations) === JSON.stringify(existingItem.bundleVariations)
            ? { ...item, quantity: newQuantity }
            : item
        );
      }

      return [...currentItems, cleanItem];
    });
  };

  const removeItem = (id: string, productGroupId?: string) => {
    setItems(currentItems => currentItems.filter(item => {
      if (item.isBundle) {
        return item.id !== id;
      }
      // Remove only the specific product instance
      return !(item.id === id && item._productGroupId === productGroupId);
    }));
  };

  const updateQuantity = (id: string, quantity: number, productGroupId?: string) => {
    setItems(currentItems =>
      currentItems.map(item => {
        const isMatch = item.isBundle 
          ? item.id === id
          : item.id === id && item._productGroupId === productGroupId;

        return isMatch
          ? { ...item, quantity: Math.max(0, Math.min(quantity, item.stock_count)) }
          : item;
      }).filter(item => item.quantity > 0)
    );
  };

  const clearCart = () => {
    setItems([]);
  };

  const totalItems = items.reduce((sum, item) => sum + item.quantity, 0);
  const totalPrice = items.reduce((sum, item) => sum + (item.price * item.quantity), 0);

  return (
    <CartContext.Provider
      value={{
        items,
        addItem,
        removeItem,
        updateQuantity,
        clearCart,
        totalItems,
        totalPrice,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export function useCart() {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
} 