import React from 'react';
import { Helmet } from 'react-helmet-async';

interface SEOMetadataProps {
  title: string;
  description: string;
  canonicalUrl: string;
  ogImage?: string;
  ogType?: string;
  prevPage?: string;
  nextPage?: string;
  noIndex?: boolean;
}

export const SEOMetadata = ({
  title,
  description,
  canonicalUrl,
  ogImage,
  ogType = 'website',
  prevPage,
  nextPage,
  noIndex = false
}: SEOMetadataProps) => {
  // Ensure title ends with site name
  const fullTitle = title.includes('| The Treasure Room') 
    ? title 
    : `${title} | The Treasure Room`;

  return (
    <Helmet>
      {/* Basic metadata */}
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />
      
      {/* Pagination links for SEO */}
      {prevPage && <link rel="prev" href={prevPage} />}
      {nextPage && <link rel="next" href={nextPage} />}
      
      {/* Robots directives */}
      {noIndex && <meta name="robots" content="noindex, follow" />}
      
      {/* Open Graph data */}
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content={ogType} />
      {ogImage && <meta property="og:image" content={ogImage} />}
      
      {/* Twitter card data */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description} />
      {ogImage && <meta name="twitter:image" content={ogImage} />}
    </Helmet>
  );
};

export default SEOMetadata; 