import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { useContactModal } from '../../context/ContactModalContext';

interface AboutCardProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AboutCard: React.FC<AboutCardProps> = ({
  isOpen,
  onClose,
}) => {
  const { openModal: openContactModal } = useContactModal();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-[10000]">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/60 backdrop-blur-sm"
            onClick={onClose}
          />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="min-h-full w-full flex items-start md:items-center justify-center p-4">
              <motion.div
                initial={{ opacity: 0, y: 20, scale: 0.95 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{ opacity: 0, y: 20, scale: 0.95 }}
                transition={{ type: "spring", duration: 0.5 }}
                className="w-full max-w-[900px] bg-black/40 border border-white/20 rounded-3xl overflow-hidden
                           shadow-[0_8px_32px_0_rgba(0,0,0,0.37)]
                           my-4 md:my-0"
              >
                <div className="flex flex-col md:flex-row h-full">
                  <div className="w-full md:w-1/2 p-8">
                    <div className="flex justify-between items-start">
                      <h2 className="text-2xl font-semibold text-white">About The Treasure Room</h2>
                      <button
                        onClick={onClose}
                        className="p-2 hover:bg-white/10 rounded-lg transition-colors text-white"
                      >
                        <X className="w-5 h-5" />
                      </button>
                    </div>

                    <div className="mt-6">
                      <p className="text-white/80 leading-relaxed mb-4">
                        Welcome to The Treasure Room, your premier destination for event hire in Sydney. 
                        We specialise in creating unforgettable experiences through our carefully curated collection 
                        of event decor and furniture.
                      </p>
                      
                      <p className="text-white/80 leading-relaxed mb-4">
                        We offer a comprehensive range of services including custom builds, an extensive hire collection,
                        event coordination and event styling.
                      </p>

                      <p className="text-white/80 leading-relaxed mb-4">
                        We pride ourselves on creating and designing one-of-a-kind pieces that transform your event space.
                      </p>

                      <p className="text-white/80 leading-relaxed">
                        Talk with out experiences team today to help cultivate and curate a treeasured event through our services.
                      </p>
                    </div>
                  </div>

                  <div className="w-full md:w-1/2 bg-gradient-to-br from-[#E3C9A8] via-[#D4B08C] to-[#A67B46] p-8 flex flex-col items-start md:overflow-y-auto">
                    <div className="w-full">
                      <div className="w-full h-48 rounded-lg overflow-hidden mt-8 mb-6">
                        <iframe
                          title="The Treasure Room Location"
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3309.5207465795584!2d151.0478663!3d-33.9484397!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ba1f8eef6fdb%3A0x9f3fb7a4e8f3c70!2s10%2F111%20Bonds%20Rd%2C%20Riverwood%20NSW%202210!5e0!3m2!1sen!2sau!4v1710400000000!5m2!1sen!2sau"
                          width="100%"
                          height="100%"
                          style={{ border: 0 }}
                          allowFullScreen={false}
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        />
                      </div>
                      <div className="mb-2">
                        <h3 className="text-xl font-semibold text-neutral-800">Location</h3>
                        <p className="text-neutral-700 mb-2">10/111 Bonds Road, Riverwood, NSW 2210</p>
                      </div>

                      <div className="mb-2">
                        <h3 className="text-xl font-semibold text-neutral-800">Hours</h3>
                        <p className="text-neutral-700">Monday: 10am - 4pm By Appointment</p>
                        <p className="text-neutral-700">Tuesday: CLOSED</p>
                        <p className="text-neutral-700">Wednesday: 10am - 4pm By Appointment</p>
                        <p className="text-neutral-700">Thursday: 10am - 4pm By Appointment</p>
                        <p className="text-neutral-700">Friday: 10am - 4pm By Appointment</p>
                        <p className="text-neutral-700">Weekends: CLOSED</p>
                      </div>

                      <div className="mb-2">
                        <h3 className="text-xl font-semibold text-neutral-800">Contact</h3>
                        <p className="text-neutral-700">Email: info@thetreasureroom.com.au</p>
                        <p className="text-neutral-700">Phone: 0420 324 722</p>
                      </div>

                      <button
                        onClick={() => {
                          onClose();
                          openContactModal();
                        }}
                        className="w-full mt-8 bg-neutral-800 text-white py-3 rounded-lg font-medium 
                                 hover:bg-neutral-700 transition-colors"
                      >
                        Contact Us
                      </button>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      )}
    </AnimatePresence>
  );
}; 