import { useState } from 'react';
import { fetchProductGroup, fetchPhotos, ProductGroup, Photo, Product } from '../services/booqableApi';

interface ProductGroupState {
  photos: Photo[];
  selectedProductId: string | null;
  isLoading: boolean;
  products: Product[];
}

interface ProductGroupStates {
  [groupId: string]: ProductGroupState;
}

export const useProductGroupHandling = () => {
  const [productGroupStates, setProductGroupStates] = useState<ProductGroupStates>({});
  const [loadingProductGroups, setLoadingProductGroups] = useState<string[]>([]);

  const handleProductGroupExpand = async (productGroupId: string, expanded: boolean) => {

    if (!expanded) {
      return;
    }

    // Skip if we're already loading this group
    if (loadingProductGroups.includes(productGroupId)) {
     return;
    }

    try {
      setLoadingProductGroups(prev => [...prev, productGroupId]);

      // Fetch product group details and photos in parallel
      const [groupResponse, photosResponse] = await Promise.all([
        fetchProductGroup(productGroupId),
        fetchPhotos(productGroupId)
      ]);


      // Filter out archived products from included array
      const products = groupResponse.included?.filter(
        item => item.type === 'products' && !item.attributes.archived
      ) as Product[] || [];

      // Find the first available product (with stock > 0)
      const firstAvailableProduct = products.find(product => 
        (product.attributes.inventory?.available || 0) > 0
      );

      // Set the initial product ID to:
      // 1. The only product if there's just one
      // 2. The first available product if there's more than one
      // 3. null if no products are available
      const initialProductId = products.length === 1 
        ? products[0].id 
        : firstAvailableProduct?.id || null;



      setProductGroupStates(prev => ({
        ...prev,
        [productGroupId]: {
          photos: photosResponse.data,
          selectedProductId: initialProductId,
          isLoading: false,
          products
        }
      }));
    } catch (error) {
      console.error('Error fetching product group details:', error);
    } finally {
     
      setLoadingProductGroups(prev => prev.filter(id => id !== productGroupId));
    }
  };

  const handleProductSelect = (groupId: string, productId: string | null) => {
    setProductGroupStates(prev => {
      const currentState = prev[groupId];

      if (!currentState) {

        return prev;
      }

      
      // Verify that the selected product exists in the products array
      const productExists = productId === null || currentState.products.some(p => p.id === productId);
      if (!productExists) {
        console.warn('Selected product not found in products array:', productId);
       
        return prev;
      }

      const newState = {
        ...prev,
        [groupId]: {
          ...currentState,
          selectedProductId: productId
        }
      };

      return newState;
    });
  };

  return {
    productGroupStates,
    loadingProductGroups,
    handleProductGroupExpand,
    handleProductSelect
  };
}; 