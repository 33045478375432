import { useEffect, useState } from 'react';
import { serviceData } from '../context/ServiceModalContext';

export const useImagePreloader = () => {
  const [imagesPreloaded, setImagesPreloaded] = useState(false);

  useEffect(() => {
    // Get all unique image URLs from service data
    const imageUrls = Object.values(serviceData)
      .flatMap((service) => service.images)
      .filter((url, index, self) => self.indexOf(url) === index);

    // Create an array to track loaded images
    const imagePromises = imageUrls.map(url => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    // Wait for all images to load
    Promise.all(imagePromises)
      .then(() => {
        setImagesPreloaded(true);
      })
      .catch(error => {
        console.error('Error preloading images:', error);
        // Still set as preloaded even if some fail, to not block the UI
        setImagesPreloaded(true);
      });
  }, []);

  return imagesPreloaded;
}; 