import { ImageData } from '../types/image';

// Base layout (desktop)
export const moodboardImages: ImageData[] = [
  {
    id: 1,
    url: '/assets/collage_1.webp',
    description: 'Tableware setup with white table clothes, antique chairs, luxury silverware and white orchids',
    title: 'Tableware',
    category: 'Engagement Party',
    linkUrl: '/hire/tableware',
    defaultPosition: { x: 380, y: 578 },
    defaultSize: { width: 800, height: 535 },
    type: 'image',
    zIndex: 3,
    mobilePosition: { x: -18, y: 724 },

    mobileSize: { width: 318, height: 210 },
    smallTabletPosition: { x: 270, y: 575 },
    smallTabletSize: { width: 390, height: 257 },
    tabletPosition: { x: 505, y: 884 },
    tabletSize: { width: 560, height: 373 }
  },
  {
    id: 2,
    url: '/assets/collage_2.webp',
    description: 'Cake and Green florals styling with out Ghost Plinths and Tableclothes',
    title: 'Ghost Plinths',
    category: 'Engagement Party',
    linkUrl: '/hire/ghost_range',
    defaultPosition: { x: 0, y: 50 },
    defaultSize: { width: 390, height: 595 },
    type: 'image',
    zIndex: 1,
    mobilePosition: { x: -1, y: 0 },
    mobileSize: { width: 238, height: 356 },
    smallTabletPosition: { x: -13, y: -4 },
    smallTabletSize: { width: 393, height: 590 },
    tabletPosition: { x: 297, y: -70 },
    tabletSize: { width: 432, height: 648 }
  },
  {
    id: 3,
    url: '/assets/collage_3.webp',
    description: 'Silverware and Tablecloths styled with red candlesticks and fruits',
    title: 'Servingware',
    category: 'Engagement Party',
    linkUrl: '/hire/servingware',
    defaultPosition: { x: 375, y: 52 },
    defaultSize: { width: 411, height: 550 },
    type: 'image',
    zIndex: 3,
    mobilePosition: { x: 225, y: -1 },

    mobileSize: { width: 237, height: 320 },
    smallTabletPosition: { x: 416, y: -12 },
    smallTabletSize: { width: 355, height: 480 },
    tabletPosition: { x: 615, y: -7 },
    tabletSize: { width: 372, height: 500 }
  },
  {
    id: 4,
    url: '/assets/collage_4.webp',
    description: 'White cake on silver cake stand ontop of silver mirror plinth infront of red velvet drape',
    title: 'Silver Mirror Plinth',
    category: 'Engagement Party',
    linkUrl: '/search?q=silver+mirror+plinth',
    defaultPosition: { x: 38, y: 556 },
    defaultSize: { width: 348, height: 522 },
    type: 'image',
    zIndex: 2,
    mobilePosition: { x: -16, y: 342 },

    mobileSize: { width: 259, height: 389 },
    smallTabletPosition: { x: -68, y: 579 },
    smallTabletSize: { width: 420, height: 630 },
    tabletPosition: { x: 199, y: 549 },
    tabletSize: { width: 482, height: 723 }
  },
  {
    id: 5,
    url: '/assets/collage_5.webp',
    description: 'Silverware serving tray with charcuterie and cheese',
    title: 'Silver Goblets',
    category: 'Luxury Servingware',
    linkUrl: '/search?q=silver%20goblet%20cups',
    defaultPosition: { x: 760, y: 45 },
    defaultSize: { width: 840, height: 545 },
    type: 'image',
    zIndex: 3,
    mobilePosition: { x: 188, y: 229 },

    mobileSize: { width: 244, height: 162 },
    smallTabletPosition: { x: 165, y: 257 },
    smallTabletSize: { width: 362, height: 239 },
    tabletPosition: { x: 746, y: -49 },
    tabletSize: { width: 700, height: 450 }
  },
  {
    id: 6,
    url: '/assets/sticker_1.webp',
    description: 'Rattan vases filled with light blue hydrangeas',
    title: 'Natural Large Urn',
    category: 'Floral Installation',
    linkUrl: '/search?q=natural%20urn%20large',
    defaultPosition: { x: 799, y: 211 },
    defaultSize: { width: 370, height: 422 },
    type: 'image',
    zIndex: 5,
    mobilePosition: { x: 237, y: 315 },

    mobileSize: { width: 233, height: 266 },
    smallTabletPosition: { x: 480, y: 342 },
    smallTabletSize: { width: 279, height: 319 },
    tabletPosition: { x: 755, y: 225 },
    tabletSize: { width: 420, height: 480 }
  },
  {
    id: 7,
    url: '/assets/sticker_2.webp',
    description: 'Custom nude signage with wicker basket of strawberries',
    title: 'Natural Scallop Clam Bowl',
    category: 'Custom Signage',
    linkUrl: '/search?q=natural%20scallop%20clam%20bowl',
    defaultPosition: { x: 160, y: 440 },
    defaultSize: { width: 395, height: 217 },
    type: 'image',
    zIndex: 6,
    mobilePosition: { x: 35, y: 238 },

    mobileSize: { width: 280, height: 154 },
    smallTabletPosition: { x: 187, y: 104 },
    smallTabletSize: { width: 410, height: 223 },
    tabletPosition: { x: 550, y: 720 },
    tabletSize: { width: 400, height: 220 }
  },
  {
    id: 8,
    url: '/assets/sticker_3.webp',
    description: 'Wine glass with glass stem and pearl sea shell as the glass',
    title: 'Seashell Glassware',
    category: 'Engagement Party',
    linkUrl: '/search?q=seashell%20glass',
    defaultPosition: { x: 1200, y: 290 },
    defaultSize: { width: 136, height: 300 },
    type: 'image',
    zIndex: 7,
    mobilePosition: { x: -17, y: 513 },

    mobileSize: { width: 110, height: 240 },
    smallTabletPosition: { x: -24, y: 452 },
    smallTabletSize: { width: 140, height: 308 },
    tabletPosition: { x: 938, y: 888 },
    tabletSize: { width: 110, height: 240 }
  },
  {
    id: 9,
    url: '/assets/sticker_4.webp',
    description: 'Two white, roughly terxtured pots',
    title: 'Pots & Vases',
    category: 'White Pots/Vases',
    linkUrl: '/hire/pots_vases',
    defaultPosition: { x: 910, y: 745 },
    defaultSize: { width: 330, height: 335 },
    type: 'image',
    zIndex: 8,
    mobilePosition: { x: 198, y: 657 },

    mobileSize: { width: 203, height: 202 },
    smallTabletPosition: { x: 221, y: 771 },
    smallTabletSize: { width: 280, height: 280 },
    tabletPosition: { x: 315, y: 963 },
    tabletSize: { width: 320, height: 322 }
  },
  {
    id: 10,
    url: '/assets/logo_background.WEBP',
    defaultPosition: { x: 551, y: 398 },
    defaultSize: { width: 615, height: 300 },
    type: 'image',
    zIndex: 10,
    mobilePosition: { x: 20, y: 0 },
    mobileSize: { width: 420, height: 205 },
    smallTabletPosition: { x: 40, y: 0 },
    smallTabletSize: { width: 557, height: 272 },
    tabletPosition: { x: 200, y: 200 },
    tabletSize: { width: 690, height: 333 }
  },
  {
    id: 11,
    url: '/assets/collage_6.webp',
    description: 'Silver tray filled with red cherries',
    title: 'Serving Trays',
    category: 'Engagement Party',
    linkUrl: '/hire/trays',
    defaultPosition: { x: 1070, y: 420 },
    defaultSize: { width: 453, height: 676 },
    type: 'image',
    zIndex: 2,
    mobilePosition: { x: 228, y: 389 },

    mobileSize: { width: 391, height: 587 },
    smallTabletPosition: { x: 427, y: 518 },
    smallTabletSize: { width: 428, height: 642 },
    tabletPosition: { x: 877, y: 453 },
    tabletSize: { width: 515, height: 772 }
  }
];
