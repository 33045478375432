import React, { useState, useEffect, useCallback, useRef } from 'react';
import { DraggableImage } from './DraggableImage';
import { moodboardImages } from '../data/images';
import { motion, AnimatePresence } from 'framer-motion';
import { useZIndex } from '../hooks/useZIndex';
import { ImageData } from '../types/image';
import { CursorTooltip } from '../components/ui/cursor-tooltip';

// Animation variants for the container
const containerVariants = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.15, // 0.15 second delay between each child
    }
  }
};

// Animation variants for each image
const imageVariants = {
  hidden: {
    opacity: 0,
    y: 20
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1, // 1 second duration for each image animation
      ease: "easeOut"
    }
  }
};

export const Moodboard = () => {
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [loading, setLoading] = useState(true);
  const [viewportSize, setViewportSize] = useState({ width: 0, height: 0 });
  const [isMobileView, setIsMobileView] = useState(false);
  const totalImages = moodboardImages.length;
  const { getZIndex, bringToFront } = useZIndex(moodboardImages.length);
  const containerRef = useRef<HTMLDivElement>(null);
  const [responsiveImages, setResponsiveImages] = useState<ImageData[]>(moodboardImages);
  const hasCalculatedPositions = useRef(false);
  const resizeTimeoutRef = useRef<NodeJS.Timeout>();
  const [tooltipState, setTooltipState] = useState({
    text: '',
    isVisible: false,
    x: 0,
    y: 0
  });

  // Calculate responsive positions relative to the center image (ID 10)
  const calculateResponsivePositions = useCallback(() => {
    const centerImage = moodboardImages.find(img => img.id === 10);
    if (!centerImage?.defaultSize) return moodboardImages;

    // Get container dimensions
    const container = containerRef.current;
    if (!container) return moodboardImages;

    const containerRect = container.getBoundingClientRect();
    const isMobile = containerRect.width <= 480; // Mobile breakpoint
    const isSmallTablet = containerRect.width > 480 && containerRect.width <= 768; // Small tablet breakpoint
    const isTablet = containerRect.width > 768 && containerRect.width <= 1024; // Tablet breakpoint

    // Calculate the center position in the viewport
    const viewportCenterX = containerRect.width / 2;
    const viewportCenterY = containerRect.height / 2;

    // Create new images array with updated positions
    const updatedImages = moodboardImages.map(image => {
      if (!image.defaultSize) return image;

      if (isMobile) {
        if (image.id === 10) {
          // Center image 10 on mobile
          return {
            ...image,
            defaultPosition: {
              x: viewportCenterX - (image.mobileSize.width / 2),
              y: viewportCenterY - (image.mobileSize.height / 2)
            },
            defaultSize: image.mobileSize
          };
        }
        // For other images on mobile, use mobile positions
        return {
          ...image,
          defaultPosition: image.mobilePosition,
          defaultSize: image.mobileSize
        };
      } else if (isSmallTablet) {
        if (image.id === 10) {
          // Center image 10 on small tablet
          return {
            ...image,
            defaultPosition: {
              x: viewportCenterX - (image.smallTabletSize.width / 2),
              y: viewportCenterY - (image.smallTabletSize.height / 2)
            },
            defaultSize: image.smallTabletSize
          };
        }
        // For other images on small tablet, use small tablet positions
        return {
          ...image,
          defaultPosition: image.smallTabletPosition,
          defaultSize: image.smallTabletSize
        };
      } else if (isTablet) {
        if (image.id === 10) {
          // Center image 10 on tablet
          return {
            ...image,
            defaultPosition: {
              x: viewportCenterX - (image.tabletSize.width / 2),
              y: viewportCenterY - (image.tabletSize.height / 2)
            },
            defaultSize: image.tabletSize
          };
        }
        // For other images on tablet, use tablet positions
        return {
          ...image,
          defaultPosition: image.tabletPosition,
          defaultSize: image.tabletSize
        };
      } else {
        // For desktop
        if (image.id === 10) {
          // Center image 10 in the viewport
          return {
            ...image,
            defaultPosition: {
              x: viewportCenterX - (image.defaultSize.width / 2),
              y: viewportCenterY - (image.defaultSize.height / 2)
            },
            defaultSize: image.defaultSize
          };
        }
        // For other images, use their original positions relative to the 1600px container
        return {
          ...image,
          defaultPosition: image.defaultPosition,
          defaultSize: image.defaultSize
        };
      }
    });

    hasCalculatedPositions.current = true;
    return updatedImages;
  }, [viewportSize]);

  const handleResize = useCallback(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setViewportSize({
        width: rect.width,
        height: rect.height
      });
    }
  }, []);

  useEffect(() => {
    // Initial size
    handleResize();

    // Create a ResizeObserver to watch container size changes
    const resizeObserver = new ResizeObserver(() => {
      // Clear any existing timeout
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }

      // Set a new timeout to debounce the resize
      resizeTimeoutRef.current = setTimeout(() => {
        handleResize();
      }, 100); // Debounce for 100ms
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Also watch window resize for good measure
    window.addEventListener('resize', handleResize);

    return () => {
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
      resizeObserver.disconnect();
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    if (viewportSize.width > 0 && viewportSize.height > 0) {
      const newPositions = calculateResponsivePositions();
      setResponsiveImages(newPositions);
    }
  }, [viewportSize, calculateResponsivePositions]);

  useEffect(() => {
    const preloadImages = () => {
      moodboardImages.forEach((image: ImageData) => {
        const img = new Image();
        img.src = image.url;
        img.onload = () => {
          setImagesLoaded(prev => {
            const newCount = Math.min(prev + 1, totalImages);
            if (newCount === totalImages) {
              setTimeout(() => setLoading(false), 500);
            }
            return newCount;
          });
        };
        img.onerror = () => {
          console.error(`Failed to load image: ${image.url}`);
          setImagesLoaded(prev => Math.min(prev + 1, totalImages));
        };
      });
    };

    preloadImages();
  }, [totalImages]);

  useEffect(() => {
    if (viewportSize.width > 0) {
      setIsMobileView(viewportSize.width <= 768); // Consider both mobile and small tablet as "mobile view" for container purposes
    }
  }, [viewportSize.width]);

  const handleDrag = (id: number, position: { x: number; y: number }, dimensions: { width: number; height: number }) => {
    bringToFront(id);


 
  };

  const loadingProgress = Math.min(Math.round((imagesLoaded / totalImages) * 100), 100);

  const handleImageHover = (text: string, isVisible: boolean, e: React.MouseEvent) => {
    setTooltipState({
      text,
      isVisible,
      x: e.clientX,
      y: e.clientY
    });
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (tooltipState.isVisible) {
        setTooltipState(prev => ({
          ...prev,
          x: e.clientX,
          y: e.clientY
        }));
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [tooltipState.isVisible]);

  return (
    <>
      <div className="visually-hidden">
        <h1>The Treasure Room - Sydney's Luxury Event & Prop Hire Service</h1>
        
        <section aria-label="Main Introduction">
          <p>Transform your event with Sydney's premier event and prop hire service. Specialising in luxury decor, custom builds, and professional event services for weddings, corporate events, and private celebrations in Sydney and surrounding areas.</p>
        </section>

        <section aria-label="Core Services">
          <h2>Our Services</h2>
          <ul>
            <li>Luxury Event Styling</li>
            <li>Event Hire</li>
            <li>Wedding Decor Hire</li>
            <li>Custom Event Builds</li>
            <li>Corporate Event Styling</li>
            <li>Private Party Decoration</li>
          </ul>
        </section>

        <section aria-label="Service Areas">
          <h2>Areas We Serve</h2>
          <ul>
            <li>Sydney CBD and Inner City</li>
            <li>Eastern Suburbs</li>
            <li>Inner West</li>
            <li>North Shore</li>
            <li>Northern Beaches</li>
            <li>Western Sydney</li>
          </ul>
        </section>

        <section aria-label="Event Types">
          <h2>Event Types We Cater To</h2>
          <ul>
            <li>Luxury Weddings</li>
            <li>Corporate Functions</li>
            <li>Private Celebrations</li>
            <li>Engagement Parties</li>
            <li>Birthday Celebrations</li>
            <li>Product Launches</li>
            <li>Kids Parties</li>
          </ul>
        </section>

        <section aria-label="Hire Categories">
          <h2>Available for Hire</h2>
          <ul>
            <li>Luxury Furniture and Decor</li>
            <li>Custom Backdrops and Installations</li>
            <li>Table Settings and Servingware</li>
            <li>Floral Arrangements and Vases</li>
            <li>Custom Signage and Neon Lights</li>
            <li>Props and Styling Elements</li>
          </ul>
        </section>

        <section aria-label="Business Information">
          <h2>Business Details</h2>
          <p>Located in Sydney, NSW</p>
          <p>Servicing Greater Sydney and surrounding areas</p>
          <p>Available for consultations by appointment</p>
          <p>Professional event styling and setup services available</p>
        </section>

        <section aria-label="Unique Selling Points">
          <h2>Why Choose The Treasure Room</h2>
          <ul>
            <li>Bespoke styling solutions for every event</li>
            <li>Luxury hire items and custom builds</li>
            <li>Professional styling team</li>
            <li>Comprehensive event setup service</li>
            <li>Attention to detail and personalized service</li>
          </ul>
        </section>

        <section aria-label="Contact Information">
          <h2>Get in Touch</h2>
          <p>Contact us to discuss your event styling needs</p>
          <p>Available for appointments and consultations</p>
          <p>Serving the Greater Sydney region</p>
        </section>
      </div>
      <AnimatePresence>
        {loading && (
          <motion.div
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-[#E3C9A8] via-[#E3C9A8] to-[#A67B46]"
          >
            <motion.div
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="text-center w-full max-w-md px-4"
            >
              <h1 className="text-4xl font-bold text-white mb-8">Loading Treasures</h1>
              <div className="w-full h-2 bg-white/10 rounded-full overflow-hidden">
                <motion.div
                  initial={{ width: '0%' }}
                  animate={{ width: `${loadingProgress}%` }}
                  transition={{ duration: 0.5 }}
                  className="h-full bg-white rounded-full"
                />
              </div>
              <p className="text-white/70 mt-4">{loadingProgress}%</p>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: loading ? 0 : 1 }}
        className="min-h-screen relative overflow-hidden bg-[#E3C9A8]"
      >
        <div ref={containerRef} className="relative min-h-screen w-full">
          {/* Logo image (id: 10) rendered separately */}
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate={loading ? "hidden" : "show"}
          >
            {responsiveImages
              .filter(image => image.id === 10)
              .map((image: ImageData) => (
                <DraggableImage
                  key={`${image.id}-${hasCalculatedPositions.current ? 'positioned' : 'initial'}-${viewportSize.width}-${viewportSize.height}`}
                  image={image}
                  zIndex={getZIndex(image.id, image.zIndex)}
                  onDrag={(e, data, dimensions) => handleDrag(image.id, { x: data.x, y: data.y }, dimensions)}
                  variants={imageVariants}
                  onHover={handleImageHover}
                />
            ))}
          </motion.div>

          {/* Container for all other images */}
          <div className={`absolute ${isMobileView ? 'w-full' : 'left-1/2 -translate-x-1/2 w-[1600px]'} h-full flex items-center`}>
            <div className={`relative w-full ${isMobileView ? 'h-full' : 'h-[1200px]'} draggable-container`}>
              <motion.div
                variants={containerVariants}
                initial="hidden"
                animate={loading ? "hidden" : "show"}
              >
                {responsiveImages
                  .filter(image => image.id !== 10)
                  .map((image: ImageData) => (
                    <DraggableImage
                      key={`${image.id}-${hasCalculatedPositions.current ? 'positioned' : 'initial'}-${viewportSize.width}-${viewportSize.height}`}
                      image={image}
                      zIndex={getZIndex(image.id, image.zIndex)}
                      onDrag={(e, data, dimensions) => handleDrag(image.id, { x: data.x, y: data.y }, dimensions)}
                      variants={imageVariants}
                      onHover={handleImageHover}
                    />
                ))}
              </motion.div>
            </div>
          </div>
        </div>
      </motion.div>
      <CursorTooltip
        text={tooltipState.text}
        isVisible={tooltipState.isVisible}
        x={tooltipState.x}
        y={tooltipState.y}
      />
    </>
  );
};