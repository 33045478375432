import React, { useState, useEffect, useCallback, useRef } from 'react';
import { motion, HTMLMotionProps } from 'framer-motion';
import { ImageData } from '../types/image';
import { ResizeHandle } from './ResizeHandle';
import { useNavigate } from 'react-router-dom';

interface DraggableImageProps {
  image: ImageData;
  zIndex: number;
  onDrag: (e: MouseEvent | TouchEvent | PointerEvent, data: { x: number; y: number }, dimensions: { width: number; height: number }) => void;
  variants?: any;
  onHover?: (text: string, isVisible: boolean, e: React.MouseEvent) => void;
  isDraggable?: boolean;
}

export const DraggableImage: React.FC<DraggableImageProps> = ({
  image,
  zIndex,
  variants,
  onHover,
  onDrag,
  isDraggable = false
}) => {
  const navigate = useNavigate();
  const [dimensions, setDimensions] = useState(image.defaultSize || { width: 0, height: 0 });
  const [aspectRatio, setAspectRatio] = useState(1);
  const [isResizing, setIsResizing] = useState(false);
  const [initialDistance, setInitialDistance] = useState(0);
  const [initialDimensions, setInitialDimensions] = useState({ width: 0, height: 0 });
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);
  const imageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkDevice = () => {
      setIsMobileOrTablet(window.innerWidth <= 1024);
    };
    
    checkDevice();
    window.addEventListener('resize', checkDevice);
    
    return () => window.removeEventListener('resize', checkDevice);
  }, []);

  useEffect(() => {
    if (image.defaultSize) {
      if (image.defaultSize.height === 0) {
        const img = new Image();
        img.src = image.url;
        img.onload = () => {
          const ratio = img.height / img.width;
          setAspectRatio(ratio);
          const newHeight = image.defaultSize.width * ratio;
          setDimensions({
            width: image.defaultSize.width,
            height: newHeight
          });
        };
      } else {
        setDimensions(image.defaultSize);
        setAspectRatio(image.defaultSize.height / image.defaultSize.width);
      }
    } else {
      const img = new Image();
      img.src = image.url;
      img.onload = () => {
        const maxDimension = 400;
        const ratio = img.height / img.width;
        setAspectRatio(ratio);
        const scale = Math.min(1, maxDimension / Math.max(img.width, img.height));
        const newDimensions = {
          width: img.width * scale,
          height: img.height * scale
        };
        setDimensions(newDimensions);
      };
    }
  }, [image.url, image.defaultSize]);

  const handleMouseMove = useCallback((e: React.MouseEvent) => {
    if (image.id === 10 || !onHover) return;
    onHover(image.title || 'Custom Title', true, e);
  }, [image.id, image.title, onHover]);

  const handleMouseLeave = useCallback((e: React.MouseEvent) => {
    if (image.id === 10 || !onHover) return;
    onHover(image.title || 'Custom Title', false, e);
  }, [image.id, image.title, onHover]);

  const handleDragStart = useCallback((e: any) => {
    if (isDraggable && !isResizing) {
      onDrag(e, { x: 0, y: 0 }, dimensions);
    }
  }, [isDraggable, onDrag, dimensions, isResizing]);

  const handleResizeStart = useCallback((e: React.MouseEvent | React.TouchEvent) => {
    e.stopPropagation();
    setIsResizing(true);
    setInitialDimensions(dimensions);

    const handleMouseMove = (e: MouseEvent) => {
      if (!imageRef.current) return;
      const rect = imageRef.current.getBoundingClientRect();
      const width = e.clientX - rect.left;
      const height = width * aspectRatio;
      setDimensions({ width, height });
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      setIsResizing(false);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  }, [aspectRatio, dimensions]);

  const handleTouchStart = useCallback((e: TouchEvent) => {
    if (e.touches.length !== 2) return;
    
    const touch1 = e.touches[0];
    const touch2 = e.touches[1];
    const distance = Math.hypot(
      touch2.clientX - touch1.clientX,
      touch2.clientY - touch1.clientY
    );
    
    setInitialDistance(distance);
    setInitialDimensions(dimensions);
    setIsResizing(true);
  }, [dimensions]);

  const handleTouchMove = useCallback((e: TouchEvent) => {
    if (!isResizing || e.touches.length !== 2) return;
    
    const touch1 = e.touches[0];
    const touch2 = e.touches[1];
    const distance = Math.hypot(
      touch2.clientX - touch1.clientX,
      touch2.clientY - touch1.clientY
    );
    
    const scale = distance / initialDistance;
    const newWidth = initialDimensions.width * scale;
    const newHeight = newWidth * aspectRatio;
    
    setDimensions({
      width: newWidth,
      height: newHeight
    });
  }, [isResizing, initialDistance, initialDimensions, aspectRatio]);

  const handleTouchEnd = useCallback(() => {
    setIsResizing(false);
  }, []);

  useEffect(() => {
    if (isDraggable && imageRef.current) {
      imageRef.current.addEventListener('touchstart', handleTouchStart);
      imageRef.current.addEventListener('touchmove', handleTouchMove);
      imageRef.current.addEventListener('touchend', handleTouchEnd);
      
      return () => {
        if (imageRef.current) {
          imageRef.current.removeEventListener('touchstart', handleTouchStart);
          imageRef.current.removeEventListener('touchmove', handleTouchMove);
          imageRef.current.removeEventListener('touchend', handleTouchEnd);
        }
      };
    }
  }, [isDraggable, handleTouchStart, handleTouchMove, handleTouchEnd]);

  const handleClick = useCallback((e: React.MouseEvent) => {
    if (!isDraggable && image.linkUrl && image.id !== 10) {
      e.preventDefault();
      e.stopPropagation();
      navigate(image.linkUrl);
    }
  }, [isDraggable, image.linkUrl, image.id, navigate]);

  // Generate srcset URLs based on image URL
  const generateSrcSet = () => {
    // Skip for images that are already optimized or SVGs
    if (image.url.includes('.svg')) return undefined;
    
    // For now, return undefined to use only the original image
    // This prevents 404 errors for missing responsive variants
    return undefined;
    
    // The original code is commented out until responsive images are generated
    /*
    // Extract base path and extension
    const urlParts = image.url.split('.');
    const extension = urlParts.pop() || 'webp';
    const basePath = urlParts.join('.');
    
    // For local assets, create responsive variants
    if (image.url.startsWith('/assets/')) {
      return `${basePath}-400w.${extension} 400w, ${basePath}-800w.${extension} 800w, ${image.url} 1200w`;
    }
    
    // For external URLs or non-standard paths, return undefined
    return undefined;
    */
  };
  
  // Generate appropriate sizes attribute
  const generateSizes = () => {
    // Return undefined to disable the sizes attribute while responsive images are disabled
    return undefined;
    
    // Original code commented out
    // return '(max-width: 768px) 400px, (max-width: 1200px) 800px, 1200px';
  };

  const ImageContent = (
    <img
      src={image.url}
      srcSet={generateSrcSet()}
      sizes={generateSizes()}
      alt={image.description || 'Moodboard image'}
      className="w-full h-full object-cover"
      draggable={false}
      width={dimensions.width}
      height={dimensions.height}
      loading={image.id <= 5 ? "eager" : "lazy"}
    />
  );

  const motionProps: HTMLMotionProps<"div"> = {
    variants,
    style: {
      position: 'absolute' as const,
      left: image.defaultPosition?.x,
      top: image.defaultPosition?.y,
      width: dimensions.width,
      height: dimensions.height,
      zIndex,
    },
    className: `relative group ${image.id !== 10 ? 'cursor-pointer' : ''}`,
    onMouseMove: handleMouseMove,
    onMouseLeave: handleMouseLeave,
    onClick: handleClick,
    whileHover: image.id !== 10 ? { scale: 1.05 } : undefined,
    transition: { duration: 0.2 },
    drag: isDraggable && !isResizing,
    dragMomentum: false,
    onDragStart: handleDragStart,
  };

  const content = (
    <motion.div ref={imageRef} {...motionProps}>
      {ImageContent}
      {isDraggable && !isMobileOrTablet && <ResizeHandle onMouseDown={handleResizeStart} />}
    </motion.div>
  );

  if (image.id === 10 || isDraggable) {
    return content;
  }

  return content;
};