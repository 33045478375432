import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { Carousel } from './carousel';
import { useContactModal } from '../../context/ContactModalContext';
import { useImagePreloader } from '../../hooks/useImagePreloader';

interface ServiceCardProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  images: string[];
  longDescription: string;
}

export const ServiceCard: React.FC<ServiceCardProps> = ({
  isOpen,
  onClose,
  title,
  description,
  images,
  longDescription
}) => {
  const { openModal: openContactModal } = useContactModal();
  const imagesPreloaded = useImagePreloader();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const handleEnquire = () => {
    onClose();
    openContactModal(`${title} Enquiry`);
  };

  return (
    <>
      {/* Hidden SEO content - always rendered but visually hidden */}
      <div className="sr-only">
        <article>
          <h2>{title}</h2>
          <p>{description}</p>
          <div dangerouslySetInnerHTML={{ __html: longDescription }} />
          {images.map((image, index) => (
            <img 
              key={index}
              src={image} 
              alt={`${title} - Image ${index + 1}`}
              width="800"
              height="600"
            />
          ))}
        </article>
      </div>

      {/* Modal overlay */}
      <AnimatePresence>
        {isOpen && (
          <div className="fixed inset-0 z-[10000]">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/60 backdrop-blur-sm"
              onClick={onClose}
            />
            <div className="fixed inset-0 overflow-y-auto">
              <div className="min-h-full w-full flex items-start md:items-center justify-center p-4">
                <motion.div
                  initial={{ opacity: 0, y: 20, scale: 0.95 }}
                  animate={{ opacity: 1, y: 0, scale: 1 }}
                  exit={{ opacity: 0, y: 20, scale: 0.95 }}
                  transition={{ type: "spring", duration: 0.5 }}
                  className="w-full max-w-[900px] bg-black/40 border border-white/20 rounded-3xl overflow-hidden
                             shadow-[0_8px_32px_0_rgba(0,0,0,0.37)]
                             my-4 md:my-0"
                >
                  <div className="flex flex-col md:flex-row h-full">
                    <div className="w-full md:w-1/2 relative">
                      <div className="w-full aspect-square relative md:h-full md:w-full md:absolute md:inset-0 overflow-hidden">
                        {imagesPreloaded && <Carousel images={images} />}
                      </div>
                    </div>

                    <div className="flex-1 md:w-1/2 flex flex-col">
                      <div className="px-4 md:px-8 py-6 md:py-8">
                        <div className="flex justify-between items-start">
                          <h2 className="text-2xl font-semibold text-white">{title}</h2>
                          <button
                            onClick={onClose}
                            className="p-2 hover:bg-white/10 rounded-lg transition-colors text-white"
                          >
                            <X className="w-5 h-5" />
                          </button>
                        </div>

                        <p className="text-lg text-white/80 mt-4">{description}</p>
                        
                        <div className="mt-6">
                          <p className="text-white/60 leading-relaxed">
                            {longDescription}
                          </p>
                        </div>

                        <button
                          onClick={handleEnquire}
                          className="mt-8 w-full bg-white text-black py-3 rounded-lg font-medium
                                   hover:bg-neutral-100 transition-colors"
                        >
                          Enquire Now
                        </button>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        )}
      </AnimatePresence>
    </>
  );
}; 