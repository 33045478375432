import { useState } from 'react';

export const useZIndex = (totalItems: number) => {
  const [zIndexMap, setZIndexMap] = useState<Record<number, number>>({});
  const [maxZ, setMaxZ] = useState(totalItems);

  const bringToFront = (id: number) => {
    setMaxZ(prev => prev + 1);
    setZIndexMap(prev => ({
      ...prev,
      [id]: maxZ + 1
    }));
  };

  const getZIndex = (id: number, baseZIndex: number) => zIndexMap[id] || baseZIndex;

  return { bringToFront, getZIndex };
};