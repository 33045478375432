import React, { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ProductGroup, Bundle, Product } from '../../services/booqableApi';
import { useDebounce } from '../../hooks/useDebounce';
import { searchHireItems } from '../../services/booqableApi';

interface SearchPreviewProps {
  query: string;
  onSelect: (query: string) => void;
  onClose: () => void;
}

export const SearchPreview: React.FC<SearchPreviewProps> = ({ query, onSelect, onClose }) => {
  const [results, setResults] = useState<Array<ProductGroup | Bundle>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const debouncedQuery = useDebounce(query, 500);
  const previewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchPreviewResults = async () => {
      if (!debouncedQuery.trim()) {
        setResults([]);
        return;
      }

      setIsLoading(true);
      try {
        const response = await searchHireItems(debouncedQuery, 1, 4);
        // Filter out any Product types and only keep ProductGroup and Bundle
        const filteredResults = response.data.filter(
          (item): item is ProductGroup | Bundle => 
            item.type === 'product_groups' || item.type === 'bundles'
        );
        setResults(filteredResults);
        setSelectedIndex(0);
      } catch (error) {
        console.error('Error fetching preview results:', error);
        setResults([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPreviewResults();
  }, [debouncedQuery]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (previewRef.current && !previewRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prev => (prev + 1) % results.length);
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prev => (prev - 1 + results.length) % results.length);
        break;
      case 'Enter':
        e.preventDefault();
        if (results[selectedIndex]) {
          onSelect(results[selectedIndex].attributes.name);
        }
        break;
      case 'Escape':
        e.preventDefault();
        onClose();
        break;
    }
  };

  if (!query.trim()) return null;

  return (
    <AnimatePresence>
      <motion.div
        ref={previewRef}
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -10 }}
        className="absolute top-full left-0 right-0 mt-2 bg-white dark:bg-black rounded-lg shadow-lg 
                 border border-black/[0.2] dark:border-white/[0.2] overflow-hidden z-50"
      >
        {isLoading ? (
          <div className="p-4 flex items-center justify-center">
            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-black dark:border-white"></div>
          </div>
        ) : results.length > 0 ? (
          <div className="py-1">
            {results.map((item, index) => (
              <button
                key={item.id}
                onClick={() => onSelect(item.attributes.name)}
                onKeyDown={handleKeyDown}
                className={`w-full px-4 py-2 text-left hover:bg-black/5 dark:hover:bg-white/5 
                         transition-colors flex items-center gap-3 ${
                           index === selectedIndex ? 'bg-black/5 dark:bg-white/5' : ''
                         }`}
              >
                <img
                  src={item.attributes.photo_url || ''}
                  alt={item.attributes.name}
                  className="w-10 h-10 object-cover rounded aspect-square"
                />
                <div className="flex flex-col">
                  <span className="text-sm font-medium text-black dark:text-white">
                    {item.attributes.name}
                  </span>
                  <span className="text-xs text-neutral-500 dark:text-neutral-400">
                    {item.type === 'bundles' ? 'Bundle' : 'Product'}
                  </span>
                </div>
              </button>
            ))}
          </div>
        ) : (
          <div className="p-4 text-center text-neutral-500 dark:text-neutral-400">
            No results found
          </div>
        )}
      </motion.div>
    </AnimatePresence>
  );
}; 