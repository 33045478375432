import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ServiceCard } from '../components/ui/service-card';

// Define the service data structure
interface Service {
  title: string;
  description: string;
  images: string[];
  longDescription: string;
}

// Service data
export const serviceData: Record<string, Service> = {
  styling: {
    title: "Event Styling",
    description: "Transform your event with our professional styling services",
    images: [
      '/assets/styling_1.webp',
      '/assets/styling_2.webp',
      '/assets/styling_3.webp'
    ],
    longDescription: `Our event styling service is a comprehensive solution for creating unforgettable experiences. 
    We work closely with you to understand your vision and bring it to life through careful attention to detail, 
    innovative design concepts, and flawless execution.

    From intimate gatherings to large-scale events, we handle everything from initial concept development to 
    on-site styling and setup. Our team of experienced stylists combines creativity with practicality to 
    create spaces that not only look stunning but also function perfectly for your event.`
  },
  'event-coordination': {
    title: "Event Coordination",
    description: "Professional event planning and coordination services",
    images: [
      '/assets/coordination_1.webp',
      '/assets/coordination_2.webp',
      '/assets/coordination_3.webp'
    ],
    longDescription: `Our event coordination service provides end-to-end management of your special day. 
    From initial planning and vendor coordination to on-the-day execution, our experienced coordinators 
    ensure every detail is perfectly managed.

    We handle timeline creation, supplier liaison, setup supervision, and seamless event flow management, 
    allowing you to fully immerse yourself in your celebration without worry.`
  },
  'custom-builds': {
    title: "Custom Builds",
    description: "Bespoke installations and backdrops for unique events",
    images: [
      '/assets/custom_1.webp',
      '/assets/custom_2.webp',
      '/assets/custom_3.webp'
    ],
    longDescription: `Our custom build service specialises in creating unique, made-to-order pieces that make 
    your event truly one-of-a-kind. Whether you're looking for a custom backdrop, themed installation, or 
    specialised event furniture, our team of skilled craftsmen can bring your ideas to life.

    We handle the entire process from initial design concepts through to manufacturing and installation, 
    ensuring every piece meets our high standards of quality and your specific requirements. Our builds 
    are engineered to be both visually stunning and practically functional.`
  },
  'custom-signage': {
    title: "Custom Signage",
    description: "Bespoke signage solutions for any occasion",
    images: [
      '/assets/signage_1.webp',
      '/assets/signage_2.webp',
      '/assets/signage_3.webp'
    ],
    longDescription: `Create a lasting impression with our custom signage solutions. Whether you need 
    welcome signs, seating charts, menu displays, or directional signage, we craft beautiful pieces 
    that perfectly complement your event aesthetic.

    Each piece is carefully designed and produced using premium materials, ensuring both visual impact 
    and durability throughout your event.`
  },
  'faux-florals': {
    title: "Faux Florals",
    description: "Stunning artificial floral arrangements and installations",
    images: [
      '/assets/florals_1.webp'
    ],
    longDescription: `Our premium faux floral service offers the beauty of botanical designs without 
    the limitations of fresh flowers. Perfect for installations, archways, ceiling features, and table 
    centerpieces that need to maintain their beauty throughout your event.

    We partner with experienced faux florists that use only the highest quality artificial flowers and foliage, creating stunning arrangements that 
    look remarkably natural while providing excellent value and reliability.`
  }
};

interface ServiceModalContextType {
  activeService: string | null;
  setActiveService: (service: string) => void;
  closeServiceModal: () => void;
}

const ServiceModalContext = createContext<ServiceModalContextType | undefined>(undefined);

export function ServiceModalProvider({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeService, setActiveService] = useState<string | null>(null);

  // Parse the service from URL on mount and location changes
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const serviceParam = searchParams.get('service');
    if (serviceParam && Object.keys(serviceData).includes(serviceParam)) {
      setActiveService(serviceParam);
    }
  }, [location]);

  const openServiceModal = (service: string) => {
    // Update URL without page reload
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('service', service);
    navigate(`${location.pathname}?${searchParams.toString()}`, { 
      replace: true // Use replace to avoid breaking browser back button
    });
    setActiveService(service);
  };

  const closeServiceModal = () => {
    // Remove service param from URL
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('service');
    const newSearch = searchParams.toString();
    navigate(
      `${location.pathname}${newSearch ? `?${newSearch}` : ''}`,
      { replace: true }
    );
    setActiveService(null);
  };

  return (
    <ServiceModalContext.Provider value={{ 
      activeService, 
      setActiveService: openServiceModal,
      closeServiceModal 
    }}>
      {children}
      {Object.entries(serviceData).map(([key, service]) => (
        <ServiceCard
          key={key}
          isOpen={activeService === key}
          onClose={closeServiceModal}
          {...service}
        />
      ))}
    </ServiceModalContext.Provider>
  );
}

export const useServiceModal = () => {
  const context = useContext(ServiceModalContext);
  if (context === undefined) {
    throw new Error('useServiceModal must be used within a ServiceModalProvider');
  }
  return context;
}; 