import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useServiceModal } from '../context/ServiceModalContext';
import { serviceData } from '../context/ServiceModalContext';

export const ServiceRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setActiveService } = useServiceModal();

  useEffect(() => {
    // Extract service from path for direct URLs like /services/event-styling
    const path = location.pathname;
    const service = path.split('/').pop();
    
    if (service && Object.keys(serviceData).includes(service)) {
      // Redirect to home with service modal
      navigate('/?service=' + service, { replace: true });
      setActiveService(service);
    }
  }, [location, navigate, setActiveService]);

  return null;
}; 